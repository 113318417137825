import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import ButtonRounded from 'components/ButtonRounded'
import { accountSources } from 'constants/externalLinks'
import { LocalizationContext } from 'contexts/Localization'

import { Box } from './styles'

const COLORS = {
  black: {
    bgClassName: 'bg--green',
    blackoutClassName: 'bg--black--op-07',
    textClassName: 'text--white',
  },
  white: {
    bgClassName: 'bg--green',
    blackoutClassName: 'bg--black--op-07',
    textClassName: 'text--white',
  },
}

/**
 * ActivateKitButton
 *
 * @param {object} props - properties of component
 * @returns {node} ActivateKitButton
 */

const ActivateKitButton = ({ isSideNavOpen, theme }) => {
  const { dict } = useContext(LocalizationContext)
  const { bgClassName, blackoutClassName, textClassName } = COLORS[theme]

  return (
    <Box isTransparent={isSideNavOpen}>
      <ButtonRounded
        {...{ bgClassName, blackoutClassName, textClassName }}
        size="slim"
        to={accountSources}
      >
        {dict.buttons.activate_kit}
      </ButtonRounded>
    </Box>
  )
}

ActivateKitButton.propTypes = {
  isSideNavOpen: PropTypes.bool.isRequired,
  theme: PropTypes.string,
}

ActivateKitButton.defaultProps = {
  theme: '',
}

export default ActivateKitButton
