import PropTypes from 'prop-types'
import React from 'react'

import { CountrySwitcherIconStyled } from './styles'

const CountrySwitcherIcon = ({ icon }) => {
  return (
    <CountrySwitcherIconStyled>
      <img src={icon} alt="" />
    </CountrySwitcherIconStyled>
  )
}

CountrySwitcherIcon.propTypes = {
  icon: PropTypes.string.isRequired,
}

export default CountrySwitcherIcon
