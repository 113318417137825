import { em } from 'polished'
import styled, { css } from 'styled-components'

import { unstyleButton } from 'styles/mixins'

const hamburgerHeight = 18
const hamburgerHeightEm = em(hamburgerHeight)
const hamburgerHeightHalfEm = em(hamburgerHeight / 2)
const hamburgerWidthEm = em(22)
const linesHeight = '2px'

const Line = styled.div`
  left: 0;
  width: 100%;
  height: ${linesHeight};
  position: absolute;
  transform: rotateZ(0);
  transform-origin: 50%;
  transition: transform ${p => p.theme.animation.timeBtns} linear,
    background-color ${p => p.theme.animation.timeBtns}
      ${p => p.theme.animation.cubicBezier},
    opacity ${p => p.theme.animation.timeStandard}
      ${p => p.theme.animation.timeStandard};
`

export const Top = styled(Line)`
  top: 0;
  will-change: transform, background-color;
`

export const Middle = styled(Line)`
  top: calc(50% - 1px);
`

export const Bottom = styled(Line)`
  bottom: 0;
  will-change: transform, background-color;
`

export const ButtonBox = styled.button`
  ${unstyleButton};
  position: relative;
  display: inline-block;
  width: ${hamburgerWidthEm};
  height: ${hamburgerHeightEm};
  vertical-align: middle;
  pointer-events: all;
  cursor: pointer;
  opacity: 1;
  transform: rotateZ(0);
  transition: opacity ${p => p.theme.animation.timeStandard};

  ${p => p.theme.mq.upMd} {
    top: ${em(-3)};
  }

  ${({ withAlternateViewport }) =>
    withAlternateViewport &&
    css`
      ${p => p.theme.mq.upLgMenu} {
        display: none;
      }
    `}

  ${({ withAlternateViewport }) =>
    !withAlternateViewport &&
    css`
      ${p => p.theme.mq.upLg} {
        display: none;
      }
    `}

  ${p => p.theme.mq.hover} {
    &:hover {
      opacity: ${p => p.theme.blocks.blockMutedOpacity};
    }
  }

  ${Line} {
    background-color: ${({ colorTheme, theme: { navigation } }) =>
      navigation[colorTheme].textColor};
  }

  ${p =>
    p.isActive &&
    css`
      transition: ${({
        theme: {
          animation: { timeStandard },
        },
      }) => `opacity ${timeStandard}, transform ${timeStandard}`};

      ${p => p.theme.mq.hover} {
        &:hover {
          transform: rotateZ(180deg);
        }
      }

      ${Top}, ${Bottom} {
        background-color: ${p => p.theme.colors.white};
      }

      ${Top} {
        // move top line to the center of hamburger
        transform: translateY(calc(${hamburgerHeightHalfEm} - 1px))
          rotate(45deg);
      }

      ${Middle} {
        opacity: 0;
        background-color: ${p => p.theme.colors.white};
        transition: opacity ${p => p.theme.animation.timeStandard} 0s;
      }

      ${Bottom} {
        // move top line to the center of hamburger
        transform: translateY(calc(-${hamburgerHeightHalfEm} + 1px))
          rotate(-45deg);
      }
    `}
`
