import { em } from 'polished'

// arrow height
export const arrowIconHBase = em(9)
export const arrowIconHBaseMd = em(10)
export const arrowIconHH2 = em(16)
export const arrowIconHH2Md = em(16)
export const arrowIconHCart = em(11)
export const arrowIconHCartMd = arrowIconHH2Md
export const arrowIconHH1 = em(16)
export const arrowIconHH1Md = em(27)

export const arrowShortOnHoverTransform = em(10)
export const arrowShortOnHoverTransformMd = em(10)

// small arrow down (for example: for accordions)
export const arrowDownSize = em(35)
export const arrowDownPadding = em(5)

// faq arrow size
export const faqArrowSize = em(16)
export const faqArrowSizeMd = em(20)
