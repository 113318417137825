import { template } from 'lodash'
import React from 'react'
import { Transition } from 'react-transition-group'

import ButtonRounded from 'components/ButtonRounded'
import { LocalizationContext } from 'contexts/Localization'
import { privacy, terms } from 'routes'
import { Col, Row } from 'styles/components/layout'
import { TextCookies } from 'styles/components/typography'
import { gtmEvent } from 'utils/client/analitics'
import LocalStorage, { PRIVACY_BANNER_SHOW } from 'utils/client/localStorage'

import CookieIcon from './components/CookieIcon'
import {
  BoxBaseStyled,
  TextWrapper,
  ButtonTextWrapper,
  IconBox,
  TextLHStyled,
  Wrapper,
} from './styles'

function PrivacyBanner() {
  const lStorage = React.useRef()
  const [isVisible, setVisible] = React.useState(false)

  React.useEffect(() => {
    lStorage.current = new LocalStorage()
    const store = lStorage.current.getItem(PRIVACY_BANNER_SHOW, true)
    const isVisible = store === null || !!store

    setVisible(isVisible)

    if (isVisible) {
      gtmEvent({
        event: 'Cookie window - Showed',
      })
    }
  }, [])

  const onCloseHandler = () => {
    lStorage.current?.setItem(PRIVACY_BANNER_SHOW, false)
    setVisible(false)
    gtmEvent({
      event: 'Cookie window - Closed',
    })
  }

  const {
    locale,
    dict: {
      buttons: { close_cookie_banner },
      links,
      privacy_banner: { rules: rulesValue, privacy: privacyValue, text },
    },
  } = React.useContext(LocalizationContext)

  const content = React.useMemo(() => {
    const contentCompile = template(text)

    return contentCompile({
      rules_link: `<a href="${
        links.terms ? links.terms : terms.as(locale)
      }" class="link--inverted" rel="noopener noreferrer" target="_blank">${rulesValue}</a>`,
      privacy_link: `<a href="${
        links.privacy ? links.privacy : privacy.as(locale)
      }" class="link--inverted" rel="noopener noreferrer" target="_blank">${privacyValue}</a>`,
    })
  }, [text, links.terms, links.privacy, locale, rulesValue, privacyValue])

  return (
    <Transition in={isVisible} timeout={300} unmountOnExit={true}>
      <BoxBaseStyled isVisible={isVisible}>
        <Row>
          <Col
            flex={{
              xs: '100%',
              md: '0 1 100%',
            }}
          >
            <Wrapper>
              <TextWrapper>
                <TextCookies dangerouslySetInnerHTML={{ __html: content }} />
              </TextWrapper>
              <ButtonRounded
                bgClassName="bg--white"
                as="button"
                size="slim"
                wrapperProps={{ onClick: onCloseHandler }}
                textClassName="text--default"
                blackoutClassName="bg--purple"
              >
                <ButtonTextWrapper>
                  <IconBox>
                    <CookieIcon />
                  </IconBox>
                  <TextLHStyled
                    dangerouslySetInnerHTML={{
                      __html: close_cookie_banner,
                    }}
                  />
                </ButtonTextWrapper>
              </ButtonRounded>
            </Wrapper>
          </Col>
        </Row>
      </BoxBaseStyled>
    </Transition>
  )
}

export default PrivacyBanner
