import PropTypes from 'prop-types'
import React from 'react'

import { RU } from 'constants/countries'
import { Icon } from 'shared/Icon'

import { Social } from './styles'

export const SocialMediaIcons = ({ locale, links, mobile }) => {
  return (
    <Social>
      {locale === RU
        && links.yandexZen && (
          <a href={links.yandexZen} target='_blank' rel='noopener noreferrer'>
            <Icon color='black' icon='dzen' size={mobile ? 40 : 50} />
          </a>
        )}
      {locale === RU
        ? links.telegram && (
        <a href={links.telegram} target='_blank' rel='noopener noreferrer'>
          <Icon color='black' icon='telegram' size={mobile ? 40 : 50} />
        </a>
      )
        : links.instagram && (
        <a href={links.instagram} target='_blank' rel='noopener noreferrer'>
          <Icon color='black' icon='instagram' size={mobile ? 40 : 50} />
        </a>
      )}
      {locale === RU
        ? links.vk && (
        <a href={links.vk} target='_blank' rel='noopener noreferrer'>
          <Icon color='black' icon='vk' size={mobile ? 40 : 50} />
        </a>
      )
        : links.facebook && (
        <a href={links.facebook} target='_blank' rel='noopener noreferrer'>
          <Icon color='black' icon='facebook' size={mobile ? 40 : 50} />
        </a>
      )}
      {locale === RU
        && links.youtube && (
          <a href={links.youtube} target='_blank' rel='noopener noreferrer'>
            <Icon color='black' icon='youtube' size={mobile ? 40 : 50} />
          </a>
        )}
    </Social>
  )
}

SocialMediaIcons.propTypes = {
  links: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  mobile: PropTypes.bool.isRequired,
}
