import PropTypes from 'prop-types'
import React from 'react'

import BurgerButton from '../BurgerButton'

import { Box, LinkStyled, LogoRus } from './styles'

const COLORS = {
  black: {
    logoColor: 'white',
  },
  white: {
    logoColor: 'black',
  },
}

const Logo = ({
  onToggleSideNav,
  isSideNavOpen,
  hideSideNav,
  to,
  as,
  withAlternateViewport,
  theme,
  dict,
}) => {
  const logoColor = isSideNavOpen ? 'white' : COLORS[theme].logoColor

  return (
    <Box isSideNavOpen={isSideNavOpen}>
      {!hideSideNav && (
        <BurgerButton
          {...{
            withAlternateViewport,
            isSideNavOpen,
            onToggleSideNav,
            theme,
            labelOpen: dict.buttons.side_menu_open,
            labelClose: dict.buttons.side_menu_close,
          }}
        />
      )}
      <LinkStyled
        {...{ isSideNavOpen, to, asPath: as, label: dict.sections.home }}
      >
        <LogoRus id="logo-rus" color={logoColor} />
      </LinkStyled>
    </Box>
  )
}

Logo.propTypes = {
  to: PropTypes.string.isRequired,
  as: PropTypes.string,
  onToggleSideNav: PropTypes.func.isRequired,
  isSideNavOpen: PropTypes.bool,
  withAlternateViewport: PropTypes.bool,
  hideSideNav: PropTypes.bool,
  theme: PropTypes.string,
  dict: PropTypes.object,
}

Logo.defaultProps = {
  isSideNavOpen: false,
  withAlternateViewport: false,
  hideSideNav: false,
  theme: '',
}

export default Logo
