import * as React from 'react'
import PropTypes from 'prop-types'

import { IconStyled } from './styles'

export const Icon = React.forwardRef((props, ref) => {
  const { color = 'black', size = 40, icon, style, children, className, ...restProps } = props

  const IconComponent = icon ? (
    <svg>
      <use xlinkHref={`#${icon}`} />
    </svg>
  ) : null

  return (
    <IconStyled
      data-qa='Icon'
      ref={ref}
      color={color}
      size={size}
      style={style}
      className={className}
      {...restProps}
    >
      {children || IconComponent}
    </IconStyled>
  )
})

Icon.propTypes = {
  /** Color of icon */
  color: PropTypes.oneOf(['black', 'grey', 'white']),

  /** Size of icon */
  size: PropTypes.oneOf([70, 50, 40, 30, 18]),

  /** Classname of icon */
  className: PropTypes.string,

  /** Icon */
  icon: PropTypes.any,

  /** Content */
  children: PropTypes.any,

  /** Styles */
  style: PropTypes.any,
}
