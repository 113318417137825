import Router, { withRouter } from 'next/router'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { setCookie } from 'utils/client/cookie'
import { Helmet } from 'react-helmet'

import Footer from 'components/_special/Footer'
import MenuMain from 'components/_special/MenuMain'
import SalesBanner from 'components/_special/SalesBanner'
import ScrollToTop from 'components/_special/ScrollToTop'
import PageTitle from 'components/PageTitle'
import PrivacyBanner from 'components/PrivacyBanner'
import FeaturesContainer from 'containers/FeaturesContainer'
import ResizeContainer from 'containers/ResizeContainer'
import ThemeContainer from 'containers/ThemeContainer'
import { LayoutProvider } from 'contexts/Layout'
import { LocalizationConsumer } from 'contexts/Localization'
import { MenuMainProvider } from 'contexts/MenuMain'
import { biome, cart, dna, mainMenu, routerConfig, wgs, cartSuccess } from 'routes'
import { getMatchedRouteAndParams } from 'utils/router'
import { getActiveDiscount } from 'utils/sales/discounts'
import { DEVELOPMENT } from 'constants/environments'
import { RU } from 'constants/countries'
import { cityAdsDefaultScript } from 'constants/cityAdsScripts'
import 'styles/index.scss'

import { MainBox } from './styles'
import sendUserInfoToAnalytics from './utils/sendUserInfoToAnalytics'
const { discounts_banner } = process.env.localization

if (process.env.SERVER !== 'production') {
  require('styles/development/index.scss')
}

class CoreLayout extends Component {
  constructor(props) {
    super(props)

    this.menuMainRef = React.createRef()
    this.mainWrapperRef = React.createRef()

    this.state = {
      isSideNavOpen: false,
      footerColor: null,
      footerBg: null,
      footerBorder: null,
      isSalesBanner: false,
      activeBanner: undefined,
      salesBannerHeight: { xs: 166, md: 66, lg: 66 },
    }
  }

  componentDidMount() {
    const { getUserProfile } = this.props
    const urlParams = new URLSearchParams(window.location.search)
    const cl_uid = urlParams.get('cl_uid')
    cl_uid && setCookie('cl_uid', cl_uid, 90)
    const utm_source = urlParams.get('utm_source')
    utm_source && setCookie('utm_source', utm_source, 90)
    const click_id = urlParams.get('click_id')
    click_id && setCookie('click_id', click_id, 90)
    getUserProfile()
    sendUserInfoToAnalytics()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sales.goods !== this.props.sales.goods) {
      this.checkSalesBanner()
    }
  }

  closeSideNav = () => {
    const { isSideNavOpen } = this.state

    if (isSideNavOpen) {
      this.setState({ isSideNavOpen: false })
    }
  }

  handleToggleSideNav = () => {
    this.setState({ isSideNavOpen: !this.state.isSideNavOpen })
  }

  switchLocale = (value, prevValue) => {
    const { config, location } = this.props.location.current

    if (value !== prevValue) {
      let routeConfig = config

      // Another one calculation for first page rendered on the server side
      // Because of link function was removed when we pass state through json stringify
      if (!(config && config.link && config.as)) {
        const { matchedRoute } = getMatchedRouteAndParams(
          routerConfig,
          location.pathname,
        )

        routeConfig = matchedRoute
      }

      if (routeConfig && routeConfig.link && routeConfig.as) {
        if (
          routeConfig.isBlockedForDirectLink &&
          routeConfig.isRedirectToCart
        ) {
          Router.push(cart.link(value), cart.as(value))
        } else {
          if (routeConfig.isOuterLink) {
            window.location.assign(routeConfig.as(value))
          } else Router.push(routeConfig.link(value), routeConfig.as(value))
        }
      }
    }
  }

  setFooterBg = color => {
    this.setState({ footerBg: color })
  }

  setFooterColor = color => {
    this.setState({ footerColor: color })
  }

  setFooterBorder = color => {
    this.setState({ footerBorder: color })
  }

  setBodyColor = color => {
    if (process.browser) {
      document.body.style.setProperty('background-color', color)
    }
  }

  checkSalesBanner = () => {
    const { locale, sales } = this.props
    const activeBanner = getActiveDiscount(discounts_banner, locale)
    const isZeroPrice =
      sales?.goods?.[activeBanner?.productType]?.price === 0 ||
      sales?.goods?.[activeBanner?.productType]?.currentPrice === 0 ||
      sales?.goods?.[activeBanner?.productType]?.priceWebsite === 0

    if (activeBanner?.productType && isZeroPrice) {
        return
    }

    const newState = { isSalesBanner: false, activeBanner: undefined }
    if (activeBanner) {
      newState.isSalesBanner = true
      newState.activeBanner = activeBanner
      newState.salesBannerHeight = {
        xs: activeBanner?.height?.xs || 160,
        md: activeBanner?.height?.md || 66,
        lg: activeBanner?.height?.lg || 66,
      }
    }

    this.setState(newState)
  }

  onBannerEnd = () => {
    this.checkSalesBanner()
  }

  onCloseBanner = () => {
    this.setState({
      isSalesBanner: false,
      activeBanner: undefined,
    })
  }

  render() {
    const { children, location, userProfile, locale } = this.props
    const {
      isSideNavOpen,
      footerColor,
      footerBg,
      footerBorder,
      isSalesBanner,
      activeBanner,
      salesBannerHeight,
    } = this.state

    const currentPath = location.current?.config?.basePath
    const shouldInsertCityAddScript =
      locale === RU &&
      process.env.NODE_ENV !== DEVELOPMENT &&
      (currentPath !== biome.basePath &&
      currentPath !== dna.basePath &&
      currentPath !== wgs.basePath &&
      currentPath !== cart.basePath &&
      currentPath !== cartSuccess.basePath)

    return (
      <ThemeContainer>
        {shouldInsertCityAddScript && (
          <Helmet>
            <script id='xcntmyAsync' type='text/javascript'>
              {cityAdsDefaultScript}
            </script>
          </Helmet>
        )}
        <FeaturesContainer>
          <ResizeContainer {...{ resizeCb: this.closeSideNav }}>
            <ScrollToTop currentLocation={location.current} />
            <LayoutProvider
              value={{
                setFooterBg: this.setFooterBg,
                setFooterColor: this.setFooterColor,
                setFooterBorder: this.setFooterBorder,
                setBodyColor: this.setBodyColor,
                closeSideNav: this.closeSideNav,
              }}
            >
              <LocalizationConsumer>
                {({ dict, language }) => (
                  <PageTitle
                    language={language}
                    currentLocation={location.current}
                    dict={dict}
                  />
                )}
              </LocalizationConsumer>

              <LocalizationConsumer>
                {({ dict, countries, locale }) => (
                  <MenuMain
                    route={location.current}
                    isSideNavOpen={isSideNavOpen}
                    onToggleSideNav={this.handleToggleSideNav}
                    mainMenu={mainMenu}
                    profileButtonTitle={dict.buttons.profile}
                    sections={dict.sections}
                    countries={countries}
                    locales={dict.locales}
                    activeLocale={locale}
                    switchLocale={({ country_code }) =>
                      this.switchLocale(country_code, locale)
                    }
                    menuMainRef={this.menuMainRef}
                    user={userProfile}
                  />
                )}
              </LocalizationConsumer>
              {/*
              This empty onClick need to close sub navigation on iOs touch devices
              More there -> https://habr.com/post/212959/
            */}
              <LocalizationConsumer>
                {({ dict, locale, paths }) => (
                  <SalesBanner
                    {...{
                      dict,
                      locale,
                      paths,
                      route: location.current,
                      activeBanner,
                      height: salesBannerHeight,
                      onClose: this.onCloseBanner,
                      onBannerEnd: this.onBannerEnd,
                    }}
                  />
                )}
              </LocalizationConsumer>
              <MainBox onClick={() => {}} ref={this.mainWrapperRef}>
                <MenuMainProvider
                  value={{
                    menuNode: this.menuMainRef.current,
                    switchLocale: this.switchLocale,
                    isSalesBanner,
                    salesBannerHeight,
                    activeBanner,
                  }}
                >
                  {children}
                </MenuMainProvider>
              </MainBox>

              <Footer
                footerColor={footerColor}
                footerBg={footerBg}
                footerBorder={footerBorder}
              />

              <PrivacyBanner />
            </LayoutProvider>
          </ResizeContainer>
        </FeaturesContainer>
      </ThemeContainer>
    )
  }
}

CoreLayout.propTypes = {
  children: PropTypes.node.isRequired,
  getUserProfile: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  userProfile: PropTypes.object.isRequired,
  router: PropTypes.object,
  sales: PropTypes.object,
}

export default withRouter(CoreLayout)
