import { ConnectedRouter } from 'connected-next-router'
import withReduxSaga from 'next-redux-saga'
import withRedux from 'next-redux-wrapper'
import App from 'next/app'
import React from 'react'
import { Provider } from 'react-redux'

import CheckRouteRendering from 'containers/CheckRouteRendering'
import CoreLayout from 'containers/CoreLayout'
import Localization from 'containers/Localization'
import Sentry from 'utils/sentry'

import createStore from '../configureStore'

class MyApp extends App {
  static async getInitialProps({ Component, ctx, router }) {
    if (!process.browser && ctx.res) {
      await Localization.getInitialProps({ ctx, router })
    }

    /*
     * Init some data for page
     */
    let pageProps = {}

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps({ ctx })
    }

    let queryParams = process.browser
      ? null
      : Object.assign(ctx.req.query, ctx.req.params)

    return { pageProps: { ...pageProps, queryParams: queryParams } }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureErrorWithScope(error, errorInfo)

    super.componentDidCatch(error, errorInfo)
  }

  render() {
    const { Component, pageProps, store } = this.props

    return (
      <Provider store={store}>
        <ConnectedRouter>
          <Localization>
            <CoreLayout>
              <CheckRouteRendering>
                <Component {...pageProps} />
              </CheckRouteRendering>
            </CoreLayout>
          </Localization>
        </ConnectedRouter>
      </Provider>
    )
  }
}

export default withRedux(createStore)(withReduxSaga(MyApp))
