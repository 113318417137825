const genProductScript = (id) => {
  return `var xcnt_product_id = '${id}';
            (function(d) {
            var xscr = d.createElement('script');
            xscr.async = 1;
            xscr.src = '//x.cnt.my/async/track/?r=' + Math.random();
            var x = d.getElementById('xcntmyAsync');
            x.parentNode.insertBefore(xscr, x);
          })(document);`
};

export const cityAdsDNAPage = genProductScript(1);

export const cityAdsMicrobiomePage = genProductScript(2);

export const cityAdsWGSPage = genProductScript(9);

export const genCityAdsCartPage = (products, counts) => `
  var xcnt_basket_products = '${products}';
  var xcnt_basket_quantity = '${counts}';
  (function(d) {
    var xscr = d.createElement('script');
    xscr.async = 1;
    xscr.src = '//x.cnt.my/async/track/?r=' + Math.random();
    var x = d.getElementById('xcntmyAsync');
    x.parentNode.insertBefore(xscr, x);
  })(document);`

export const cityAdsSuccessPageScript = `
  var xcnt_order_products = '${typeof window !== 'undefined' ? window.CITY_ADS_PRODUCTS_IN_CART : 0}';
  var xcnt_order_quantity = '${typeof window !== 'undefined' ? window.CITY_ADS_PRODUCT_COUNT_IN_CART : 0}';
  var xcnt_order_id = '${typeof window !== 'undefined' ? window.ORDER_ID : 0}';
  var xcnt_order_total = '${typeof window !== 'undefined' ? window.ORDER_PRICE : 0}';
  var xcnt_order_currency = 'RUR';
  var xcnt_user_email = '${typeof window !== 'undefined' ? window.ORDER_USER_EMAIL : 0}';
  (function(d) {
    var xscr = d.createElement('script');
    xscr.async = 1;
    xscr.src = '//x.cnt.my/async/track/?r=' + Math.random();
    var x = d.getElementById('xcntmyAsync');
    x.parentNode.insertBefore(xscr, x);
  })(document);
`

export const cityAdsDefaultScript = `
  (function(d) {
    var xscr = d.createElement('script');
    xscr.async = 1;
    xscr.src = '//x.cnt.my/async/track/?r=' + Math.random();
    var x = d.getElementById('xcntmyAsync');
    x.parentNode.insertBefore(xscr, x);
  })(document);
`
