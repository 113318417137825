export default function(mainMenu) {
  return mainMenu.reduce((memo, item) => {
    memo.push(item)
    item.items &&
      item.items.forEach(subItem =>
        memo.push({
          ...subItem,
          parentRoute: item,
        }),
      )

    return memo
  }, [])
}
