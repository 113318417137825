import PropTypes from 'prop-types'
import React from 'react'

import { LocalizationContext } from 'contexts/Localization'
import { home } from 'routes'

const CheckRouteRendering = ({ children, location, router }) => {
  const { locale } = React.useContext(LocalizationContext)

  if (
    locale &&
    location.current?.config?.hideForLocales &&
    location.current?.config?.hideForLocales.includes(locale)
  ) {
    router.replace(home.link(locale), home.as(locale))

    return null
  }

  return children
}

CheckRouteRendering.propTypes = {
  children: PropTypes.element.isRequired,
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
}

export default CheckRouteRendering
