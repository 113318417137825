import { em } from 'polished'
import styled, { css } from 'styled-components'

import { Row } from 'styles/components/layout'

export const TextBox = styled.div`
  margin-top: ${em(18)};
  text-align: center;

  ${p =>
    p.isLast &&
    css`
      margin-bottom: -${em(5)};
    `}

  ${p => p.theme.mq.upMd} {
    text-align: left;
    margin-top: 0;
    margin-left: -${em(20)};
    margin-bottom: 0;
  }
`

export const RowStyled = styled(Row)``

export const TextStyled = styled.span`
  display: inline-block;
  font-size: ${p => p.theme.typography.fontSize};
  font-family: ${p => p.theme.typography.mediumFont};
  line-height: 1.44;
  font-weight: 400;

  & > mark {
    background: transparent;
    color: ${p =>
      p.markColor ? p.theme.colors[p.markColor] : p.theme.colors.yellow};
  }

  ${p => p.theme.mq.upMd} {
    white-space: nowrap;
    font-size: ${p => p.theme.typography.textDisclaimerMd};
  }
`

export const ButtonBox = styled.div`
  margin-top: ${em(13)};
  flex-shrink: 0;

  ${p => p.theme.mq.upMd} {
    margin-top: 0;
    margin-left: -${em(12)};
  }

  ${p => p.theme.mq.upLg} {
    margin-left: -${em(20)};
  }
`

export const ButtonContent = styled.div`
  padding: ${em(3)} ${em(22)};
`

export const bgStyles = color => css`
  background-color: ${p => p.theme.colors[color]};
`

export const textStyles = color => css`
  color: ${p => p.theme.colors[color]};
`
