import { round } from 'lodash'
import { call, put } from 'redux-saga/effects'

import {
  SALES_INFO_REQUEST,
  SALES_INFO_SUCCESS,
  SALES_INFO_FAILURE,
  CART_PROMO_SUCCESS,
  CART_CALCULATE,
  CART_PROMO_FAILURE,
} from 'constants/actionTypes'
import { RU } from 'constants/countries'
import { COUNTRIES_WITHOUT_DNA, GOODS_TYPES, SALES } from 'constants/sales'
import { getSalesData } from 'utils/api'
import { gtmEvent } from 'utils/client/analitics'
import { getFormErrors } from 'utils/errors.js'

const { sales } = process.env.localization

const getSaleRegion = locale => sales[locale]

export function* getSalesInfoForLocale({ payload }) {
  const { locale, promocode, isPromoRequest } = payload

  yield put({ type: SALES_INFO_REQUEST, payload: { locale } })

  const salesData = yield call(getSalesData, {
    locale,
    promocode,
  })
  const { response, error } = salesData
  let isFailed = false

  if (response) {
    yield put({
      type: SALES_INFO_SUCCESS,
      payload: calculateSalesInfoForLocale(response, locale),
    })
  } else {
    yield put({ type: SALES_INFO_FAILURE, payload: { ...salesData } })
    isFailed = true
  }

  if (promocode || isPromoRequest)
    yield call(updatePromoState, { promocode, isPromoRequest, response, error })

  return isFailed
}

// Calculate sales info
const calculateSalesInfoForLocale = (response, locale) => {
  const { STRIPE_PAYMENT_KEY, YANDEX_MERCHANT_ID } = process.env.server
  const salesRegion = getSaleRegion(locale)
  const activity = {
    goods: {
      [GOODS_TYPES.dna]: { isActive: !COUNTRIES_WITHOUT_DNA.includes(locale) },
      [GOODS_TYPES.biome]: { isActive: true },
      [GOODS_TYPES.wgs]: { isActive: locale === RU },
    },
  }
  const {
    data,
    data: { delivery, goods },
    deliveryTimings,
  } = response
  const { currentPrice: dnaCurrentPrice, price: dnaPrice } = goods[
    GOODS_TYPES.dna
  ]
  const { currentPrice: biomeCurrentPrice, price: biomePrice } = goods[
    GOODS_TYPES.biome
  ]
  const { currentPrice: wgsCurrentPrice, price: wgsPrice } = goods[
    GOODS_TYPES.wgs
  ]
  const bundleCurrentPrice = round(dnaCurrentPrice + biomeCurrentPrice, 2)
  const bundlePrice = round(dnaPrice + biomePrice, 2)

  const res = {
    ...data,
    ...SALES[salesRegion],
    isGroupon: response.promocode?.isGroupon,
    delivery: {
      ...delivery,
      ...SALES[salesRegion]?.delivery,
      ...deliveryTimings,
    },
    goods: {
      ...goods,
      [GOODS_TYPES.dna]: {
        ...goods[GOODS_TYPES.dna],
        ...activity.goods[GOODS_TYPES.dna],
        isActive:
          activity.goods[GOODS_TYPES.dna].isActive &&
          !response.promocode?.isGroupon,
      },
      [GOODS_TYPES.wgs]: {
        ...goods[GOODS_TYPES.wgs],
        ...activity.goods[GOODS_TYPES.wgs],
      },
      [GOODS_TYPES.biome]: {
        ...goods[GOODS_TYPES.biome],
        ...activity.goods[GOODS_TYPES.biome],
      },
      [GOODS_TYPES.bundle]: {
        code: GOODS_TYPES.bundle,
        isActive: true,
        currentPrice: bundleCurrentPrice,
        price: bundlePrice,
      },
    },
    _paymentKey: STRIPE_PAYMENT_KEY,
    _yandexMerchantId: YANDEX_MERCHANT_ID,
  }

  res.goods[GOODS_TYPES.dna].discount = round(
    100 - (dnaCurrentPrice * 100) / dnaPrice,
  )
  res.goods[GOODS_TYPES.wgs].discount = round(
    100 - (wgsCurrentPrice * 100) / wgsPrice,
  )
  res.goods[GOODS_TYPES.biome].discount = round(
    100 - (biomeCurrentPrice * 100) / biomePrice,
  )

  return res
}

// Set state for requested promo
function* updatePromoState({ promocode, isPromoRequest, response, error }) {
  // Promo code is found and can be used
  if (response && !response.promocode.errorCode) {
    yield put({
      type: CART_PROMO_SUCCESS,
      payload: {
        name: promocode,
        id: response.promocode.promocodeId,
      },
    })

    if (isPromoRequest && promocode)
      gtmEvent({ event: `Used Promocode — Success`, description: promocode })

    yield put({ type: CART_CALCULATE })
    // Promo code was not found or can't be used or server is not responding
  } else {
    const errorCode = response ? response.promocode.errorCode : 'not_responding'

    if (isPromoRequest)
      gtmEvent({ event: `Used Promocode — Error`, description: errorCode })

    yield put({
      type: CART_PROMO_FAILURE,
      payload: {
        errors: getFormErrors({
          fetchError: error,
          errorCode,
          customErrors: [
            {
              field: isPromoRequest ? 'promocode' : null, // show error in promo code field only if it was requested by this field
            },
          ],
        }),
      },
    })
  }
}
