import styled from 'styled-components'

import UnstyledUl from 'styles/components/UnstyledUl'

export const AsideBox = styled.aside`
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
  z-index: 15;
  pointer-events: none;
  color: ${({ colorTheme, theme: { navigation } }) =>
    navigation[colorTheme].textColor};
`

export const NavBox = styled.nav`
  -webkit-overflow-scrolling: touch;
  background: ${({ colorTheme, theme: { navigation } }) =>
    navigation[colorTheme].bgColor};
  display: block;
  height: 100%;
  max-width: ${p => p.theme.blocks.sideNavWidth};
  overflow: auto;
  pointer-events: ${p => (p.isVisible ? 'all' : 'none')};
  position: relative;
  text-transform: uppercase;
  transform: ${p => (p.isVisible ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform ${p => p.theme.animation.timeBtns} ease-in-out;
  width: 90%;
  will-change: transform;
  z-index: 20;

  ${p => p.theme.mq.upMd} {
    max-width: ${p => p.theme.blocks.sideNavWidthMd};
  }
`

export const ListBox = styled(UnstyledUl)``

export const Line = styled.div`
  position: absolute;
  bottom: 0;
  left: -${p => p.theme.indents.baseIndentXs};
  right: -${p => p.theme.indents.baseIndentXs};
  height: 1px;
  background-color: ${({ colorTheme, theme: { navigation } }) =>
    navigation[colorTheme].lineColor};
  visibility: ${p => (p.isActive ? 'visible' : 'hidden')};
  transition: background-color ${p => p.theme.animation.timeStandard}
    ease-in-out;

  ${p => p.theme.mq.upMd} {
    left: -${p => p.theme.indents.baseIndentMd};
    right: -${p => p.theme.indents.baseIndentMd};
  }
`
