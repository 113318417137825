import { call, put, takeEvery, select, all, fork } from 'redux-saga/effects'

import {
  PAGE_REQUEST,
  PAGE_FAILURE,
  PAGE_SUCCESS,
  CHANGE_LOCALE,
} from 'constants/actionTypes'
import { COUNTRIES, RU } from 'constants/countries'
import { staticPath, cdnPath, cdnPathNotLocalized } from 'constants/paths'
import {
  COUNTRIES_WITH_DISTRIBUTION,
  COUNTRIES_WITHOUT_SALES,
} from 'constants/sales'
import pages from 'constants/textBunchesNames'
import {
  getCurrentLanguage,
  getCurrentLocale,
  getCurrentPage,
  getSales,
} from 'selectors'
import { getPage } from 'utils/api'
import Sentry from 'utils/sentry'

/*
 * Worker saga
 */
export function* pageDataRequestWorker({ payload }) {
  const { locale, page } = payload
  const {
    defaultLocale,
    locales,
    languages,
    coordinates,
  } = process.env.localization

  const currentLocale = yield select(getCurrentLocale)
  const currentLanguage = yield select(getCurrentLanguage)
  const currentSales = yield select(getSales)
  const currentPage = yield select(getCurrentPage, page)
  const localeSafety = locale || currentLocale || defaultLocale
  const language =
    languages[locale] || currentLanguage || languages[defaultLocale]

  const countries = locales.map(localeKey => COUNTRIES[localeKey])

  const currentCountry = COUNTRIES[localeSafety]
  const paths = {
    boxes: staticPath(language, 'boxes'),
    cdn: cdnPath(language),
    cdn_boxes: cdnPath(language, 'boxes'),
    cdn_consultation: cdnPath(language, 'consultation'),
    cdn_global: cdnPathNotLocalized(
      [RU].includes(language) ? language : 'global',
    ),
    shared: cdnPathNotLocalized('shared'),
  }
  const cdnPaths = {}
  let responseWithCache
  let responseError

  pages.forEach(page => {
    paths[page] = staticPath(language, page)
    cdnPaths[page] = cdnPath(language, page)
  })

  const isLocaleChanged = localeSafety !== currentLocale

  if (isLocaleChanged) {
    yield put({
      type: CHANGE_LOCALE,
      payload: {
        locale: localeSafety,
        language,
        countries,
        currentCountry,
        coordinates,
        paths,
        cdnPaths,
        sales: {
          ...currentSales,
          specialDistribution: COUNTRIES_WITH_DISTRIBUTION.includes(
            localeSafety,
          ),
          withoutSales: COUNTRIES_WITHOUT_SALES.includes(localeSafety),
        },
      },
    })
  }

  const isLangChanged = language !== currentLanguage

  if (!currentPage || isLangChanged) {
    const { response, error } = yield call(getPage, {
      language,
      page,
    })

    responseWithCache = response
    responseError = error
  } else {
    responseWithCache = {
      pages: {
        [page]: currentPage,
      },
    }
  }

  if (responseWithCache) {
    // Block that has to help us with catching sentry frequent error
    const pageData = responseWithCache.pages[page]

    if (typeof pageData !== 'object') {
      const sentryLog =
        typeof pageData === 'string' ? pageData.substr(1, 1000) : pageData

      Sentry.captureMessageWithScope('STRANGE SERVER ANSWER', {
        response: sentryLog,
      })
    }
    let cleanedPageData = responseWithCache
    if (page === 'common') {
      cleanedPageData = {
        pages: {
          ...responseWithCache.pages,
          common: {
            ...pageData,
            links: pageData.allLinks[localeSafety],
          },
        },
      }
    }

    yield put({
      type: PAGE_SUCCESS,
      payload: {
        ...cleanedPageData,
        name: page,
      },
    })
  } else {
    const errorAction = {
      type: PAGE_FAILURE,
      payload: { ...responseError, name: page },
    }

    if (!process.browser) {
      yield call(console.log, '=========== ACTION FAILED ===========') // eslint-disable-line
      // eslint-disable-next-line
      yield call(console.log, {
        type: errorAction.type,
        text: errorAction.payload.error,
        forPage: page,
      })
    }

    yield put(errorAction)
  }
}

/*
 * Watcher saga
 */
function* pageDataRequestWatcher() {
  yield takeEvery(PAGE_REQUEST, pageDataRequestWorker)
}

export default function* pageSaga() {
  yield all([fork(pageDataRequestWatcher)])
}
