import { template } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

import Icon from 'components/Icon'
import { IE } from 'constants/countries'
import { LocalizationContext } from 'contexts/Localization'
import { ResizeContext } from 'contexts/Resize'
import { footerMenuCol1, footerMenuCol2 } from 'routes'
import { Row, Col } from 'styles/components/layout'
import {
  bodyColor,
  white,
  whiteOp5,
  purpleHeart,
  whiteOp1,
  blackOp07,
} from 'styles/theme/colors'

import FooterLink from './components/FooterLink'
import {
  FooterBox,
  Navigation,
  Address,
  UnstyledList,
  Link,
  Sections,
  ColNoPadding,
  ColStyled,
  Compliance,
  ComplianceRow,
  CeBox,
  ManufacturerBox,
  EcRepBox,
  Divider,
  CompanyText,
  ContactsText,
  MedicalLicense,
  Disclaimer,
  DisclaimerAndMedicalLicense,
} from './styles'
import { Text } from 'shared/Typography'
import { SocialMediaIcons } from 'components/_special/Footer/components/SocialMediaIcons'

const borderColors = {
  white: whiteOp1,
  purple: whiteOp1,
  black: blackOp07,
}

const Footer = ({ footerColor, footerBg, footerBorder }) => {
  const isWhite = footerColor === white
  const isPurple = footerBg === purpleHeart
  const {
    locale,
    dict: {
      info,
      links,
      phone,
      email,
      sections,
      customer_service: customerService,
    },
  } = React.useContext(LocalizationContext)
  const { mobile, tablet } = React.useContext(ResizeContext)
  const col1 = React.useMemo(() => {
    return footerMenuCol1.filter(
      route => !route.hideForLocales.includes(locale),
    )
  }, [locale])
  const col2 = React.useMemo(() => {
    return footerMenuCol2.filter(
      route => !route.hideForLocales.includes(locale),
    )
  }, [locale])
  const col1External = React.useMemo(
    () =>
      ['partners', 'educationalHub', 'promo', 'blog', 'webinars', 'clinic', 'dataInterpretation', 'rnd'].filter(
        route => links[route],
      ),
    [links],
  )
  const col2External = React.useMemo(
    () =>
      [
        'privacy',
        'terms',
        'solo',
        'researches',
        'healthyEating',
        'biomeCourse',
      ].filter(route => links[route]),
    [links],
  )
  const customerServiceCompiled = React.useMemo(() => {
    const compiled = template(customerService)
    return compiled({
      phone: `<a href='tel:${phone.link}' class='link' rel='noopener noreferrer' target='_blank'>${phone.value}</a>`,
      email: `<a href='mailto:${email.value}' class='link' rel='noopener noreferrer' target='_blank'>${email.text}</a>`,
    })
  }, [customerService, phone.link, phone.value, email.value, email.text])

  const iconColor = React.useMemo(() => {
    if (isWhite) {
      return 'white'
    } else if (isPurple) {
      return 'purple'
    }
    return 'black'
  }, [isWhite, isPurple])

  const compliance = (
    <Compliance>
      {locale === IE && (
        <>
          <ComplianceRow withMarginTop={true}>
            <CeBox>
              <Icon id='ce_gray' />
            </CeBox>
            <ManufacturerBox>
              <Icon id='manufacturer' />
            </ManufacturerBox>
            <span
              dangerouslySetInnerHTML={{
                __html: info.compliance.address,
              }}
            />
          </ComplianceRow>
          <ComplianceRow>
            <EcRepBox>
              <Icon id='ec_rep' />
            </EcRepBox>
            <span
              dangerouslySetInnerHTML={{
                __html: info.compliance.address_ireland,
              }}
            />
          </ComplianceRow>
          <Divider borderColor={borderColors[iconColor]} />
        </>
      )}
      <Text
        className={classNames({
          'h-color-D100': !isPurple && !isWhite,
          'h-color-W50': isWhite,
          'h-color-W30': isPurple,
        })}
        size={['18/28', '18/28', '16/25']}
        dangerouslySetInnerHTML={{ __html: info.copyright }}
      />
    </Compliance>
  )

  return (
    <FooterBox {...{ footerColor, footerBg }} as='footer'>
      <Row>
        <Col width={{ md: 1 / 2 }}>
          <Address borderColor={borderColors[footerBorder || iconColor]}>
            <Row style={{ height: '100%' }}>
              <Col
                width={{ md: 8 / 12 }}
                style={{ display: 'flex', flexDirection: 'column', justifyItems: 'flex-start' }}
              >
                {info.company && (
                  <CompanyText
                    forwardedAs={'address'}
                    className={classNames({
                      'h-color-D100': !isPurple && !isWhite,
                      'h-color-W100': isWhite,
                      'h-color-W80': isPurple,
                    })}
                    size={['18/24', '18/24', '16/25']}
                    dangerouslySetInnerHTML={{ __html: info.company }}
                    isWhite={isWhite}
                    isPurple={isPurple}
                  />
                )}

                {links.map && (
                  <Link
                    href={links.map}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <Text
                      as={'address'}
                      className={classNames({
                        'h-color-D100': !isPurple && !isWhite,
                        'h-color-W100': isWhite,
                        'h-color-W80': isPurple,
                      })}
                      size={['18/24', '18/24', '16/25']}
                      dangerouslySetInnerHTML={{ __html: info.address_legal }}
                    />
                  </Link>
                )}

                {(mobile || tablet) && <SocialMediaIcons mobile={mobile} links={links} locale={locale} />}

                <ContactsText
                  className={classNames({
                    'h-color-D100': !isPurple && !isWhite,
                    'h-color-W100': isWhite,
                    'h-color-W80': isPurple,
                  })}
                  size={['18/24', '18/24', '16/25']}
                  dangerouslySetInnerHTML={{ __html: customerServiceCompiled }}
                  isWhite={isWhite}
                  isPurple={isPurple}
                />

                {!mobile && compliance}
              </Col>

              {!mobile && !tablet && (
                <ColStyled width={{ md: 4 / 12 }}>
                  <SocialMediaIcons mobile={mobile} links={links} locale={locale} />
                </ColStyled>
              )}
            </Row>
          </Address>
        </Col>

        <Col width={{ xs: 1, md: 1 / 2 }}>
          <Navigation borderColor={borderColors[footerBorder || iconColor]}>
            <Sections>
              <ColNoPadding width={{ xs: 1, md: 6.5 / 12 }}>
                <UnstyledList>
                  {col1.map((route, index) => (
                    <FooterLink
                      key={`first.${index}`}
                      color={iconColor}
                      title={sections[route.dict]}
                      to={route.link(locale)}
                      as={route.as(locale)}
                      isMobile={mobile}
                    />
                  ))}
                  {col1External.map(route => (
                    <FooterLink
                      key={route}
                      color={iconColor}
                      title={sections[route]}
                      to={links[route]}
                      isMobile={mobile}
                      isExternal={true}
                    />
                  ))}
                </UnstyledList>
              </ColNoPadding>

              <ColNoPadding width={{ xs: 1, md: 5.5 / 12 }}>
                <UnstyledList>
                  {col2.map((route, index) => (
                    <FooterLink
                      key={`second.${index}`}
                      color={iconColor}
                      title={sections[route.dict]}
                      to={route.link(locale)}
                      as={route.as(locale)}
                      isMobile={mobile}
                    />
                  ))}
                  {col2External.map(route => (
                    <FooterLink
                      key={route}
                      color={iconColor}
                      title={sections[route]}
                      to={links[route]}
                      isMobile={mobile}
                      isExternal={true}
                    />
                  ))}
                </UnstyledList>
              </ColNoPadding>

              <DisclaimerAndMedicalLicense>
                {info.disclaimer && (
                  <Disclaimer
                    className={classNames({
                      'h-color-D30': !isPurple && !isWhite,
                      'h-color-W50': isWhite,
                      'h-color-W30': isPurple,
                    })}
                    dangerouslySetInnerHTML={{ __html: info.disclaimer }}
                  />
                )}

                {info.medical_license && (
                  <MedicalLicense
                    className={classNames({
                      'h-color-D30': !isPurple && !isWhite,
                      'h-color-W50': isWhite,
                      'h-color-W30': isPurple,
                    })}
                    dangerouslySetInnerHTML={{ __html: template(info.medical_license)( { licenseLink: links.license }) }}
                  />
                )}

                {mobile && compliance}

              </DisclaimerAndMedicalLicense>
            </Sections>
          </Navigation>
        </Col>
      </Row>
    </FooterBox>
  )
}

Footer.propTypes = {
  footerColor: PropTypes.oneOf([white, bodyColor, whiteOp5]),
  footerBg: PropTypes.string,
  footerBorder: PropTypes.string,
}

export default React.memo(Footer)
