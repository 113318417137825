import PropTypes from 'prop-types'
import React from 'react'

import ButtonCircle from 'components/ButtonCircle'
import { account } from 'constants/externalLinks'
import htmlDecode from 'utils/htmlDecode'

import noAvatar from './assets/no-avatar@2x.png'
import {
  Wrapper,
  Button,
  Avatar,
  TextStyled,
  NoAvatar,
  Background,
  IconBlock,
  Link,
} from './styles'

const COLORS = {
  black: {
    bgClassName: 'bg--transp bg--border-white-op-2',
    iconColor: 'white',
  },
  white: {
    bgClassName: 'bg--transp bg--border-gray',
    iconColor: 'black',
  },
}

/**
 * ButtonProfile — component to render profile button
 *
 * @returns {node} ButtonProfile
 */

const ButtonProfile = ({
  isSideNavOpen,
  user,
  profileButtonTitle,
  theme,
  label,
}) => {
  const { avatar, gender, isFetching } = user
  let buttonInner
  const notLogged = !isFetching && !avatar && !gender
  const loginInProgress = isFetching && !avatar && !gender
  const loggedAndWithoutAvatar = !isFetching && !avatar && gender
  const loggedWithAvatar = !isFetching && avatar && gender
  const bgClassName = COLORS[theme].bgClassName
  const iconColor = COLORS[theme].iconColor

  const [buttonHighlight, setButtonHighlight] = React.useState(false)
  const labelDecoded = React.useMemo(() => htmlDecode(label), [label])

  if (notLogged) {
    buttonInner = (
      <ButtonCircle
        {...{ bgClassName, iconColor, label: labelDecoded }}
        blackoutClassName="bg--picton-blue"
        as="button"
        size="small"
        icon="btn_profile"
        isHighlight={buttonHighlight}
      />
    )
  } else if (loginInProgress) {
    buttonInner = (
      <Button className="bg--black--op-07" aria-label={labelDecoded} />
    )
  } else if (loggedAndWithoutAvatar) {
    buttonInner = (
      <Button aria-label={labelDecoded}>
        <Background {...{ gender }} />
        <IconBlock>
          <NoAvatar src={noAvatar} alt="" />
        </IconBlock>
      </Button>
    )
  } else if (loggedWithAvatar) {
    buttonInner = (
      <Button aria-label={labelDecoded}>
        <Avatar image={avatar} />
      </Button>
    )
  }

  return (
    <Wrapper isTransparent={isSideNavOpen}>
      <Link
        onMouseEnter={() => setButtonHighlight(true)}
        onMouseLeave={() => setButtonHighlight(false)}
        href={account}
      >
        {buttonInner}
        {/*<TextStyled*/}
        {/*  dangerouslySetInnerHTML={{ __html: profileButtonTitle }}*/}
        {/*  colorTheme={theme}*/}
        {/*/>*/}
      </Link>
    </Wrapper>
  )
}

ButtonProfile.propTypes = {
  user: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  isSideNavOpen: PropTypes.bool,
  isMobOrTab: PropTypes.bool,
  profileButtonTitle: PropTypes.string,
  theme: PropTypes.string,
}

ButtonProfile.defaultProps = {
  isSideNavOpen: false,
  profileButtonTitle: '',
  theme: '',
}

export default ButtonProfile
