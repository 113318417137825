import CSSPlugin from 'gsapAlias/CSSPlugin'
import TimelineLite from 'gsapAlias/TimelineLite'

// eslint-disable-next-line no-unused-vars
const plugins = [CSSPlugin]

const TEXT_DURATION = 0.3
const DURATION = 0.5

export const decreaseButton = ({ iconNode, textNode, wrapperNode }) => ({
  bg,
  text,
}) => {
  const timeline = new TimelineLite()
  const { height, width, left } = text.getBoundingClientRect()
  const { left: iconLeft } = iconNode.getBoundingClientRect()

  timeline
    .set(textNode, {
      opacity: 1,
    })
    .to(textNode, TEXT_DURATION, {
      opacity: 0,
      ease: 'easeIn',
    })
    .set(bg, {
      width: width,
    })
    .set(iconNode, {
      x: 0,
    })
    .set(wrapperNode, {
      x: 0,
    })
    .to(
      bg,
      DURATION,
      {
        width: height,
        ease: 'easeIn',
      },
      TEXT_DURATION,
    )
    .to(
      iconNode,
      DURATION,
      {
        x: left - iconLeft,
        ease: 'easeIn',
      },
      TEXT_DURATION,
    )
    .to(
      wrapperNode,
      DURATION,
      {
        x: width - height,
        ease: 'easeIn',
      },
      TEXT_DURATION,
    )

  timeline
    .set(wrapperNode, {
      clearProps: 'x',
    })
    .set(text, {
      padding: 0,
    })
    .set(bg, {
      clearProps: 'width',
    })
    .set(textNode, {
      display: 'none',
    })
    .set(iconNode, {
      clearProps: 'x',
    })

  return timeline
}

export const setConditionWithCounter = ({ textNode }) => {
  textNode.style.display = 'none'
  textNode.style.opacity = 0
}

export const increaseButton = ({ iconNode, textNode, wrapperNode }) => ({
  bg,
  text,
}) => {
  const timeline = new TimelineLite()

  timeline
    .set(textNode, {
      display: 'inline',
    })
    .set(text, {
      clearProps: 'padding',
    })

  const { height, width, left } = text.getBoundingClientRect()
  const { left: iconLeft } = iconNode.getBoundingClientRect()

  timeline
    .set(bg, {
      width: height,
    })
    .set(wrapperNode, {
      x: width - height,
    })
    .set(iconNode, {
      x: left - iconLeft,
    })
    .to(
      wrapperNode,
      DURATION,
      {
        x: 0,
        ease: 'easeIn',
      },
      0,
    )
    .to(
      bg,
      DURATION,
      {
        width: width,
        ease: 'easeIn',
      },
      0,
    )
    .to(
      iconNode,
      DURATION,
      {
        x: 0,
        ease: 'easeIn',
      },
      0,
    )
    .to(
      textNode,
      TEXT_DURATION,
      {
        opacity: 1,
        ease: 'easeIn',
      },
      DURATION,
    )

  timeline
    .set(wrapperNode, {
      clearProps: 'x',
    })
    .set(text, {
      clearProps: 'padding',
    })
    .set(bg, {
      clearProps: 'width',
    })
    .set(iconNode, {
      clearProps: 'x',
    })

  return timeline
}
