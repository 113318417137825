import PropTypes from 'prop-types'
import React, { Component } from 'react'

import CountrySwitcherIcon from 'components/_special/CountrySwitcher/components/CountrySwitcherIcon'
import Icon from 'components/Icon'
import { LocalizationConsumer } from 'contexts/Localization'

import SideNavCollapser from '../SideNavCollapser'

import {
  Box,
  Title,
  TitleUnderline,
  IconBox,
  List,
  ActiveCountryBox,
} from './styles'

class CountrySwitcherMobile extends Component {
  static propTypes = {
    countries: PropTypes.array.isRequired, // eslint-disable-line react/no-unused-prop-types
    currentCountry: PropTypes.object.isRequired,
    dict: PropTypes.object.isRequired,
    isActive: PropTypes.bool.isRequired,
    onCollapserToggle: PropTypes.func.isRequired,
    onCountryChange: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    theme: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = {
      items: [],
      prevCountries: null,
      prevCountry: null,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { countries, currentCountry, dict, onCountryChange } = nextProps
    const { prevCountries, prevCountry } = prevState

    if (countries !== prevCountries || currentCountry !== prevCountry) {
      return {
        prevCountries: countries,
        prevCountry: currentCountry,
        items: countries.map(country => {
          const isActive = currentCountry.code === country.value

          return {
            route: { title: dict.locales[country.value] },
            code: country.value,
            onClick: () => onCountryChange(country.value),
            Component: isActive ? ActiveCountryBox : 'div',
            beforeTitle: <CountrySwitcherIcon icon={country.flag} />,
            afterTitle: isActive ? (
              <IconBox>
                <Icon id="btn_check" color="black" strokeWidth="2" />
              </IconBox>
            ) : null,
          }
        }),
      }
    }

    return null
  }

  render() {
    const {
      dict,
      currentCountry,
      onCollapserToggle,
      isActive,
      theme,
    } = this.props
    const { items } = this.state

    return (
      <Box>
        <div className="pl-2">
          <Title dangerouslySetInnerHTML={{ __html: dict.locales.title }} />
          <TitleUnderline />
        </div>
        <List>
          <SideNavCollapser
            route={{
              title: dict.locales[currentCountry.code],
            }}
            items={items}
            onClick={onCollapserToggle}
            isActive={isActive}
            theme={theme}
          />
        </List>
      </Box>
    )
  }
}

const CountrySwitcherMobileLocalized = props => (
  <LocalizationConsumer>
    {({ dict, currentCountry }) => (
      <CountrySwitcherMobile
        dict={dict}
        currentCountry={currentCountry}
        {...props}
      />
    )}
  </LocalizationConsumer>
)

export default CountrySwitcherMobileLocalized
