import { throttle, debounce } from 'lodash'
import PropTypes from 'prop-types'
import React, {
  useContext,
  useMemo,
  useEffect,
  useState,
  useCallback,
} from 'react'

import { ResizeContext } from 'contexts/Resize'
// import useScrollDirectionEffect, {
//   SCROLL_DOWN,
// } from 'hooks/useScrollDirectionEffect'

import ActivateKitButton from '../ActivateKitButton'
import NavigationItem from '../NavigationItem'
import NavigationSecondLvl from '../NavigationSecondLvl'

import { NavigationBox, Line } from './styles'
import findDefault2ndLvlParent from './utils/findDefault2ndLvlParent'
import getFlatMainMenu from './utils/getFlatMainMenu'

const Navigation = ({ mainMenu, route, isSwitcherLoaded, theme }) => {
  const { config: { basePath } = {} } = route
  const { desktop, scaling } = useContext(ResizeContext)
  const flatMainMenu = useMemo(() => getFlatMainMenu(mainMenu), [mainMenu])
  const default2ndLvlParent = useMemo(
    () => findDefault2ndLvlParent(flatMainMenu, basePath),
    [basePath, flatMainMenu],
  )
  const [secondLvlParent, setSecondLvlParent] = useState(null)
  const [leftPadding, setLeftPadding] = useState(0)
  const [refs, setRefs] = useState(null)
  // const [is2ndLvlSwitched, set2ndLvlSwitch] = useState(false)
  const debouncedFunc = useCallback(debounce(setSecondLvlParent, 100), [])
  const setActiveParent = item => debouncedFunc(item)

  /*
   * Create refs
   */
  useEffect(() => {
    const newRefs = {}
    mainMenu.forEach(item => {
      newRefs[item.route.basePath] = React.createRef()
    })
    setRefs(newRefs)
  }, [mainMenu])

  /*
   * Calculate padding left
   */
  const throttledResizeHandler = useCallback(
    throttle(() => {
      const activeParent =
        secondLvlParent?.route?.basePath ||
        default2ndLvlParent?.route?.basePath ||
        null

      if (!refs || !activeParent) {
        return
      }

      const activeParentRef = refs[activeParent]

      if (activeParentRef && activeParentRef.current) {
        setLeftPadding(activeParentRef.current.getBoundingClientRect().left)
      }
    }, 60),
    [refs, default2ndLvlParent, secondLvlParent, isSwitcherLoaded],
  )
  useEffect(() => {
    throttledResizeHandler()
    window.addEventListener('resize', throttledResizeHandler)

    return () => {
      window.removeEventListener('resize', throttledResizeHandler)
    }
  }, [throttledResizeHandler])

  /*
   * Scrolling logic
   */
  // const direction = useScrollDirectionEffect({ thresholdPixels: 15 })
  // useEffect(() => {
  //   set2ndLvlSwitch(!!window.pageYOffset)
  // }, [])
  // useEffect(() => {
  //   if (direction) {
  //     set2ndLvlSwitch(direction === SCROLL_DOWN)
  //   }
  // }, [direction])

  const parentRoute = secondLvlParent // || default2ndLvlParent
  const isActive = !!(leftPadding && parentRoute && parentRoute.items)
  const isTransformed = false //!!(default2ndLvlParent && isActive && is2ndLvlSwitched)

  /*
   * Rendering
   */
  return (
    <>
      <NavigationBox colorTheme={theme} {...{ isTransformed }}>
        {refs &&
          mainMenu.map(
            (item, key) =>
              !item.isHiddenOnDesktop && (
                <NavigationItem
                  ref={refs[item.route.basePath]}
                  key={key}
                  item={item}
                  onMouseEnter={setActiveParent}
                  onMouseLeave={setActiveParent}
                />
              ),
          )}
        {(desktop || scaling) && (
          <ActivateKitButton
            isWhite={false}
            isSideNavOpen={false}
            theme={theme}
          />
        )}
      </NavigationBox>
      <Line
        {...{ isTransformed }}
        colorTheme={theme}
        is2ndLvlVisible={isActive}
      />
      <NavigationSecondLvl
        {...{ theme, parentRoute, leftPadding, isTransformed, isActive }}
        onMouseEnter={setActiveParent}
        onMouseLeave={setActiveParent}
      />
    </>
  )
}

Navigation.propTypes = {
  mainMenu: PropTypes.array.isRequired,
  route: PropTypes.object.isRequired,
  isSwitcherLoaded: PropTypes.bool.isRequired,
  theme: PropTypes.string,
}

Navigation.defaultProps = {
  theme: '',
}

export default Navigation
