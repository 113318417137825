import { em } from 'polished'
import PropTypes from 'prop-types'
import React from 'react'

import { Col, Row } from 'styles/components/layout'

import { TextBox, TextStyled } from './styles'

const TextBanner = ({ activeBanner, text }) => {
  return (
    <Row
      alignItems="center"
      height={{ xs: '100%', md: em(66) }}
      justifyContent={{ xs: 'center' }}
    >
      <Col>
        <TextBox>
          <TextStyled
            markColor={activeBanner.markColor}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        </TextBox>
      </Col>
    </Row>
  )
}

TextBanner.propTypes = {
  activeBanner: PropTypes.shape({
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    markColor: PropTypes.string.isRequired,
  }).isRequired,
  text: PropTypes.string.isRequired,
}

export default React.memo(TextBanner)
