import PropTypes from 'prop-types'
import React, { Component } from 'react'
import matchPath from 'react-router/matchPath'

import { getPage } from 'actions'
import { LocalizationProvider } from 'contexts/Localization'
import { getSafetyLocale } from 'utils/router'

export default class Localization extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    getPage: PropTypes.func.isRequired,
    initCart: PropTypes.func.isRequired,
    localization: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
    location: PropTypes.object.isRequired, // eslint-disable-line react/no-unused-prop-types
  }

  state = {
    locale: null,
  }

  static async getInitialProps({ ctx: { store, req }, router }) {
    /*
     * Get common content — initialization of locale in the application's state
     */
    const routeObject = req || router
    const locale =
      (routeObject.params && routeObject.params.locale) ||
      (routeObject.query && routeObject.query.locale)
    const safetyLocale = getSafetyLocale(locale)

    store.dispatch(getPage({ locale: safetyLocale, page: 'common' }))

    return {}
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps && nextProps.location) {
      const { params } =
        matchPath(
          nextProps.location.current.location.pathname,
          nextProps.location.current.config,
        ) || {}
      const safetyLocale = getSafetyLocale(
        params?.locale || nextProps.localization.locale,
      )

      if (safetyLocale !== prevState.locale) {
        return { locale: safetyLocale }
      }
    }

    return null
  }

  componentDidMount() {
    const { initCart } = this.props
    const { locale } = this.state

    initCart({ locale })
  }

  componentDidUpdate(prevProps, prevState) {
    const { getPage, localization } = this.props
    const { locale } = this.state

    if (locale !== prevState.locale && !localization.pagesFetching.common) {
      getPage({ locale, page: 'common' })
    }
  }

  render() {
    const { children, localization } = this.props
    const { locale } = this.state
    const { common } = localization.pages

    if (!locale || !(common && 'sections' in common)) {
      return null
    }

    return (
      <LocalizationProvider
        value={{
          countries: localization.countries,
          currentCountry: localization.currentCountry,
          dict: common,
          language: localization.language,
          locale,
          paths: localization.paths,
          cdnPaths: localization.cdnPaths,
          sales: localization.sales,
          hideDNA: !!(
            localization.sales.goods && !localization.sales.goods.dna.isActive
          ),
          specialDistribution: localization.sales.specialDistribution,
          withoutSales: localization.sales.withoutSales,
        }}
      >
        {children}
      </LocalizationProvider>
    )
  }
}
