import { em } from 'polished'
import styled, { css } from 'styled-components'

import UnstyledUl from 'styles/components/UnstyledUl'

export const Arrow = styled.div`
  position: absolute;
  right: ${em(5)};
  top: 50%;
  margin-top: -${p => parseInt(p.theme.arrows.arrowDownSize) / 2}em;
  width: ${p => p.theme.arrows.arrowDownSize};
  height: ${p => p.theme.arrows.arrowDownSize};
  padding: ${p => p.theme.arrows.arrowDownPadding};
  transition: transform ${p => p.theme.animation.timeStandard};
  transform: ${p => (p.isActive ? 'rotate(-180deg)' : 'rotate(0)')};
`

export const Accordion = styled(UnstyledUl)`
  background: ${({ colorTheme, theme: { navigation } }) =>
    navigation[colorTheme].sideNavAccordionBgColor};
  text-transform: none;
  max-height: 0;
  overflow: hidden;
  transition-property: opacity, max-height;
  transition-duration: ${p => p.theme.animation.timeBtns};
  transition-timing-function: ease-in;

  ${p =>
    (p.state === 'entering' || p.state === 'entered') &&
    css`
      max-height: ${em(p.accordionMaxHeight)};
    `}

  ${p =>
    (p.state === 'exiting' || p.state === 'exited') &&
    css`
      max-height: 0;
      transition-timing-function: ease-out;
    `}
`
