import { connect } from 'react-redux'

import { getSales } from 'selectors'
import { getUserProfile } from 'actions'

import Component from './CoreLayout'

function mapStateToProps(state) {
  return {
    location: state.location,
    locale: state.localization.locale,
    userProfile: state.userProfile,
    sales: getSales(state),
  }
}

const mapDispatchToProps = {
  getUserProfile,
}

export default connect(mapStateToProps, mapDispatchToProps)(Component)
