import {
  white,
  black,
  whiteOp12,
  whiteOp1,
  blackOp07NonTransp,
  blackOp07,
  grayOp06NonTransp,
} from './colors'

export default {
  black: {
    textColor: white,
    bgColor: black,
    lineColor: whiteOp12,
    sideNavLineColor: whiteOp1,
    sideNavAccordionBgColor: whiteOp1,
  },
  white: {
    textColor: black,
    bgColor: white,
    lineColor: blackOp07NonTransp,
    sideNavLineColor: blackOp07,
    sideNavAccordionBgColor: grayOp06NonTransp,
  },
}
