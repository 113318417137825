import { em } from 'polished'
import styled from 'styled-components'

export const Box = styled.div`
  display: inline-block;
  opacity: ${p => (p.isTransparent ? 0 : 1)};
  transition: opacity ${p => p.theme.animation.timeFastest};
  transition-delay: ${p =>
    p.isTransparent ? '0s' : p.theme.animation.timeStandard};
  padding-left: ${em(15)};
`
