import animateScrollTo from 'animated-scroll-to'
import Router from 'next/router'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { addAmount } from 'actions'
import { GOODS_TYPES } from 'constants/sales'
import { ResizeContext } from 'contexts/Resize'
import { cart, home } from 'routes'

const useScrollOnMainAddOnProduct = ({ route, bannerRef, locale }) => {
  const dispatch = useDispatch()
  const { goods } = useSelector(state => state.cart)
  const { mobile } = React.useContext(ResizeContext)

  const isMainPage = React.useMemo(
    () => route.config?.basePath === home.basePath,
    [route],
  )
  const productType = React.useMemo(
    () => route.config?.isProductPage && route.config?.productType,
    [route],
  )

  const onClick = React.useCallback(() => {
    if (isMainPage) {
      const target = document.getElementById('aboutTests')
      const bannerBBox = bannerRef.current.getBoundingClientRect()

      animateScrollTo(target, {
        speed: 600,
        verticalOffset: -bannerBBox.top - (mobile ? 0 : bannerBBox.height),
        elementToScroll: window,
      })
    } else {
      if (productType && goods && !goods[productType].amount) {
        dispatch(
          addAmount({
            productType: GOODS_TYPES[productType],
            amount: 1,
            isSubscription: false,
          }),
        )
      }

      Router.push(cart.link(locale), cart.as(locale))
    }
  }, [isMainPage, bannerRef, mobile, productType, goods, locale, dispatch])

  return { onClick }
}

export default useScrollOnMainAddOnProduct
