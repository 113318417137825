import PropTypes from 'prop-types'
import React from 'react'

import ButtonCircle from 'components/ButtonCircle'

import BtnGoodsCounter from '../BtnGoodsCounter'

const COLORS = {
  black: {
    bgClassName: 'bg--picton-blue',
    iconColor: 'white',
  },
  white: {
    bgClassName: 'bg--picton-blue',
    iconColor: 'white',
  },
}

/**
 * BtnCartMobile — component to render mobile cart button
 *
 * @param {object} props - properties of component
 * @returns {node} BtnCartMobile
 */

const BtnCartMobile = ({ wrapperProps, amount, theme }) => {
  const { bgClassName, iconColor } = COLORS[theme]

  return (
    <>
      <ButtonCircle
        as="button"
        size="small"
        icon="btn_cart"
        {...{ bgClassName, iconColor, wrapperProps }}
      />
      <BtnGoodsCounter amount={amount} isShow={amount > 0} />
    </>
  )
}

BtnCartMobile.propTypes = {
  /** Object with callback for click on the button action */
  wrapperProps: PropTypes.object.isRequired,

  /** Amount of goods in cart */
  amount: PropTypes.number,

  /** Color theme code */
  theme: PropTypes.string,
}

BtnCartMobile.defaultProps = {
  theme: '',
}

export default BtnCartMobile
