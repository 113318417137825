import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import Link from 'components/Link'
import LinkBlockArrowShort from 'components/LinkBlockArrowShort'
import { ResizeContext } from 'contexts/Resize'
import { Text } from 'shared/Typography'

import { UnderlineStyled } from './styles'
const colors = {
  white: 'white',
  purple: 'purple',
}

/**
 * FooterLink
 *
 * @param {object} props - properties of component
 * @returns {node} FooterLink
 */
const FooterLink = props => {
  const { color, title, as, to, isExternal } = props
  const { mobile } = useContext(ResizeContext)
  const isWhite = color === colors.white || color === colors.purple
  const opacity = color === colors.purple ? 0.5 : 1
  let linkBody = null

  const content = (
    <Text
      style={{ display: 'block' }}
      as={'span'}
      size={['18/24', '18/24', '16/20']}
      dangerouslySetInnerHTML={{
        __html: title,
      }}
    />
  )
  const extraProps = isExternal
    ? {
        as: 'a',
        target: '_blank',
        rel: 'noopener noreferrer',
      }
    : {}

  if (mobile) {
    linkBody = (
      <>
        <LinkBlockArrowShort
          isWhite={isWhite}
          opacity={opacity}
          className="py-15 pl-2"
          to={to}
          asPath={as}
          {...extraProps}
        >
          {content}
        </LinkBlockArrowShort>
        <UnderlineStyled isWhite={isWhite} />
      </>
    )
  } else {
    if (isExternal) {
      linkBody = (
        <a href={to} className="link" {...extraProps}>
          {content}
        </a>
      )
    } else {
      linkBody = (
        <Link to={to} asPath={as} className="link" {...extraProps}>
          {content}
        </Link>
      )
    }
  }

  return <li className="footer-link">{linkBody}</li>
}

FooterLink.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  as: PropTypes.string,
  isExternal: PropTypes.bool,
  color: PropTypes.oneOf(['white', 'black', 'purple']),
}

FooterLink.defaultProps = {
  isExternal: false,
  color: 'black',
}

export default FooterLink
