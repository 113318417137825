import { em } from 'polished'

// The names of fonts
const dinPro = 'DIN Pro'
const drukCond = 'Druk Cond'
// Fonts Variables
export const fontFamily = `${dinPro}, -apple-system, system-ui, Arial, Helvetica, sans-serif`
export const fontDruk = `${drukCond}, -apple-system, system-ui, Arial, Helvetica, sans-serif`

// The names of fonts
/** @deprecated */
const dinProLight = 'DinPro_light'
/** @deprecated */
const dinProReg = 'DinPro_regular'
/** @deprecated */
const dinProMedium = 'DinPro_medium'
/** @deprecated */
const dinProBold = 'DinPro_bold'
/** @deprecated */
const dinProBlack = 'DinPro_black'

// Fonts Variables
/** @deprecated */
export const lightFont = `${dinProLight}, -apple-system, system-ui, Arial, Helvetica, sans-serif`
/** @deprecated */
export const baseFont = `${dinProReg}, -apple-system, system-ui, Arial, Helvetica, sans-serif`
/** @deprecated */
export const mediumFont = `${dinProMedium}, -apple-system, system-ui, Arial, Helvetica, sans-serif`
/** @deprecated */
export const boldFont = `${dinProBold}, -apple-system, system-ui, Arial, Helvetica, sans-serif`
/** @deprecated */
export const blackFont = `${dinProBlack}, -apple-system, system-ui, Arial, Helvetica, sans-serif`

//
// SIZES
//
// Common
export const fontSizeXs = em(12)
export const fontSize = em(18)
export const fontSizeMd = em(18)
export const fontSizeXsPx = '12px'
export const fontSizeBasePx = '18px'
export const fontSizeBaseMobilePx = '18px'
export const baseLh = 1.77777778
export const baseLhXs = 1.41
// Weight
export const fontWeightLight = 300
export const fontWeightNormal = 400
export const fontWeightMedium = 500
export const fontWeightBold = 700
export const fontWeightBlack = 900

// Cart
export const cartFontSizePx = '16px'
export const cartFontSize = em(cartFontSizePx)
export const cartFontLh = 1.31
export const cartItemFontSizePx = '17px'
export const cartItemFontSize = em(cartItemFontSizePx)
export const cartItemFontLh = 1.28
export const cartItemFontSizeMdPx = '20px'
export const cartItemFontSizeMd = em(cartItemFontSizeMdPx)
export const cartItemFontLhMd = 1.5
export const textCartSubtitle = em(26)
export const textCartSubtitleLh = 1.46
export const textCartLegendPx = 28
export const textCartLegend = em(textCartLegendPx)
export const textCartLegendLh = 1.35

// Footer
export const footerFontSize = em(16)
export const footerFontSizeMd = em(18)

// Headings
export const h1 = em(35)
export const h1MarginBottom = em(20, 35)
export const h1Lh = 1.14
export const h1MdSmallPx = 50
export const h1MdSmall = em(h1MdSmallPx)
export const h1CartMdPx = 40
export const h1CartMd = em(h1CartMdPx)
export const h1CartLhMd = 1.3
export const h1MarginBottomMdSmall = em(20, h1MdSmallPx)
export const h1MdPx = 80
export const h1Md = em(h1MdPx)
export const h1MarginBottomMd = em(20, h1MdPx)
export const h1LhMd = 1.125

export const h2Px = 30
export const h2 = em(h2Px)
export const h2MarginBottom = em(17, h2Px)
export const h2Lh = 1.3333
export const h2MdPx = 35
export const h2Md = em(h2MdPx)
export const h2MarginBottomMd = em(17, h2MdPx)
export const h2LhMd = 1.43

export const h3px = 22
export const h3 = em(h3px)
export const h3Lh = 1.27
export const h3MarginBottom = em(17, h3px)
export const h3MdPx = 35
export const h3Md = em(h3MdPx)
export const h3MarginBottomMd = em(17, h3MdPx)

export const h4Px = 25
export const h4 = em(h4Px)
export const h4Lh = 1.28
export const h4MarginBottom = em(10, h4Px)
export const h4MarginBottomMd = em(25, h4Px)

// Small price
export const priceSmall = em(16)
export const priceSmallLh = 1.28

// Base bold price
export const priceBaseBold = em(22)
export const priceBaseBoldLh = 1.27

// Medium price
export const priceMedium = em(24)
export const priceMediumLh = 1.28

// Big price
export const priceBig = em(35)
export const priceBigLh = 1.485
export const priceBigMd = em(60)
export const priceBigLhMd = 1.2

// Links
export const linkHoveredOp = 0.4

// Faq title
export const faqH2Px = '24px'
export const faqH2 = em(faqH2Px)
export const faqH2Lh = 1.2
export const faqH2MdPx = '40px'
export const faqH2Md = em(faqH2MdPx)
export const faqH2MdLh = 1.5

// Disclaimer
export const textDisclaimerPx = '14px'
export const textDisclaimer = em(textDisclaimerPx)
export const textDisclaimerLh = 1.28
export const textDisclaimerMdPx = '16px'
export const textDisclaimerMd = em(textDisclaimerMdPx)
export const textDisclaimerMdLh = 1.625

// Other text sizes
export const textSmallDescrPx = '14px'
export const textSmallDescr = em(textSmallDescrPx)
export const textSmallDescrMd = em(18)
export const textSmallDescrLh = 1.5
export const textMedium = em(20)
export const textMediumMdPx = '30px'
export const textMediumMd = em(textMediumMdPx)
export const textMediumLh = 1.3
export const textHighlightLh = 1.5
export const textUppercase = em(15)
export const textUppercaseLh = 1.26
export const cookieSmall = em(12)
export const cookieSmallLh = 1.5

//Forms
export const inputLh = baseLh * 1.26
export const inputErrorLh = 1.3
export const textSmallFormsPx = '12px'
export const textSmallForms = em(textSmallFormsPx)
