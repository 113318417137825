import styled from 'styled-components'

import Underline from 'styles/components/Underline'

export const Inner = styled.div`
  position: relative;
  line-height: 3;
`

export const ItemBox = styled.li`
  position: relative;
  display: block;
  padding-left: ${p => p.theme.indents.baseIndentXs};
`

export const ItemUnderline = styled(Underline)`
  top: 0;
  background-color: ${({ colorTheme, theme: { navigation } }) =>
    navigation[colorTheme].sideNavLineColor};
`
