import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import Overlay from 'components/Overlay'
import { LayoutContext } from 'contexts/Layout'

import ActivateKitBtnMobile from './components/ActivateKitBtnMobile'
import CountrySwitcherMobile from './components/CountrySwitcherMobile'
import SideNavCollapser from './components/SideNavCollapser'
import SideNavItem from './components/SideNavItem'
import { AsideBox, ListBox, NavBox, Line } from './styles'

const COUNTRY_SWITCHER_ID = 'country_switcher'

class SideNav extends Component {
  constructor(props) {
    super(props)

    this.menuRef = React.createRef()
    this.state = {
      activeItem: null,
    }
  }

  componentDidUpdate(prevProps) {
    const { isSideNavOpen } = this.props

    if (isSideNavOpen !== prevProps.isSideNavOpen) {
      if (isSideNavOpen) {
        this.handleMenuOpen()
      } else {
        this.handleMenuClose()
      }
    }
  }

  componentWillUnmount() {
    this.handleMenuClose()
  }

  handleAccordionToggle = (event, code) => {
    const { activeItem } = this.state

    event.preventDefault()
    this.setState({
      activeItem: code === activeItem ? null : code,
    })
  }

  handleCountryChange = code => {
    const { activeItem } = this.state

    this.props.switchLocale({ country_code: code })
    this.setState({
      activeItem: code === activeItem ? null : code,
    })
    this.context.closeSideNav()
  }

  handleMenuOpen = () => {
    const { current } = this.menuRef

    disableBodyScroll(current)
  }

  handleMenuClose = () => {
    clearAllBodyScrollLocks()
  }

  render() {
    const { mainMenu, isMobile, isSideNavOpen, countries, theme } = this.props
    const { activeItem } = this.state

    return (
      <>
        <AsideBox colorTheme={theme}>
          <Overlay
            isVisible={isSideNavOpen}
            onClick={this.context.closeSideNav}
          />
          <NavBox
            isVisible={isSideNavOpen}
            ref={this.menuRef}
            colorTheme={theme}
          >
            <ListBox>
              {mainMenu.map((item, key, arr) =>
                item.items && item.items.length ? (
                  <SideNavCollapser
                    {...item}
                    {...{
                      key,
                      theme,
                      isActive: activeItem === item.route.dict,
                      onClick: event =>
                        this.handleAccordionToggle(event, item.route.dict),
                    }}
                  />
                ) : (
                  <SideNavItem
                    {...item}
                    {...{
                      key,
                      isActive: item.route.dict === activeItem,
                      isLast: key === arr.length - 1,
                      theme,
                    }}
                  />
                ),
              )}
            </ListBox>
            {isMobile && <ActivateKitBtnMobile />}
            {isMobile && countries.length > 1 && (
              <CountrySwitcherMobile
                onCountryChange={this.handleCountryChange}
                onCollapserToggle={event =>
                  this.handleAccordionToggle(event, COUNTRY_SWITCHER_ID)
                }
                countries={countries}
                isActive={activeItem === COUNTRY_SWITCHER_ID}
                {...{ theme }}
              />
            )}
          </NavBox>
        </AsideBox>
        {theme === 'black' && (
          <Line colorTheme={theme} {...{ isActive: !isSideNavOpen }} />
        )}
      </>
    )
  }
}

SideNav.propTypes = {
  switchLocale: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  isSideNavOpen: PropTypes.bool,
  mainMenu: PropTypes.array,
  countries: PropTypes.array,
  theme: PropTypes.string,
}

SideNav.defaultProps = {
  theme: '',
}

SideNav.contextType = LayoutContext

export default SideNav
