import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Link from 'components/Link'
import { LocalizationContext } from 'contexts/Localization'

/*
 * TODO: we do not consider in activity status of link next condition
 * link is /ru/some-page and we got location /some-page
 */
function LinkNav({
  location,
  to,
  asPath,
  children,
  className,
  onClick,
  exact,
  component,
}) {
  const { basePath = '' } = location.current.config || {}
  const { locale } = React.useContext(LocalizationContext)
  const isActive = React.useMemo(
    () =>
      basePath && exact
        ? basePath === asPath || `/${locale}${basePath}` === asPath
        : `/${locale}${basePath}`.includes(asPath),
    [locale, basePath, asPath, exact],
  )

  return (
    <Link
      className={cx(className, { active: isActive })}
      {...{ to, asPath, onClick, component }}
    >
      {children}
    </Link>
  )
}

LinkNav.propTypes = {
  location: PropTypes.object.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  asPath: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  exact: PropTypes.bool,
  component: PropTypes.string,
}

LinkNav.defaultProps = {
  className: '',
}

export default LinkNav
