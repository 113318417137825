import styled from 'styled-components'
import { em } from 'polished'

const backgrounds = {
  'grey': 'var(--color-G50)',
  'black': 'var(--color-D100)',
  'white': 'var(--color-W100)',
}

const colors = {
  'grey': 'var(--color-D100)',
  'black': 'var(--color-W100)',
  'white': 'var(--color-D100)',
}

const hoverColors = {
  'grey': 'var(--color-D50)',
  'black': 'var(--color-W50)',
  'white': 'var(--color-D50)',
}

export const IconStyled = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${p => em(p.size, 16)};
  height: ${p => em(p.size, 16)};
  background-color: ${p => backgrounds[p.color]};
  color: ${p => colors[p.color]};
  border-radius: 50%;

  & > svg {
    max-width: 70%;
    max-height: 70%;
  }

  ${p => p.theme.mq.upMd} {
    &:hover {
      color: ${p => hoverColors[p.color]};
    }
  }
`
