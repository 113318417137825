import PropTypes from 'prop-types'
import React from 'react'
import { Transition } from 'react-transition-group'

import Icon from 'components/Icon'

import SideNavItem from '../SideNavItem'

import { Arrow, Accordion } from './styles'

const COLORS = {
  black: {
    iconColor: 'white',
  },
  white: {
    iconColor: 'black',
  },
}

const accordionMaxHeight = 900

const SideNavCollapser = props => {
  const { items, isActive, theme, ...rest } = props
  const { iconColor } = COLORS[theme]
  const itemsWrap = React.useRef(null)

  const accordionHeight = itemsWrap.current
    ? itemsWrap.current.getBoundingClientRect().height
    : accordionMaxHeight

  return (
    <>
      <SideNavItem
        afterTitle={
          <Arrow isActive={isActive}>
            <Icon id="arrow_down" color={iconColor} />
          </Arrow>
        }
        items={items}
        {...{ isActive, theme }}
        {...rest}
      />
      <Transition in={isActive} timeout={400}>
        {state => (
          <Accordion
            {...{ state }}
            accordionMaxHeight={accordionHeight}
            colorTheme={theme}
          >
            <div ref={itemsWrap}>
              {items.map((item, index, arr) => (
                <SideNavItem
                  key={index}
                  {...item}
                  isLast={index === arr.length - 1}
                  theme={theme}
                />
              ))}
            </div>
          </Accordion>
        )}
      </Transition>
    </>
  )
}

SideNavCollapser.propTypes = {
  isActive: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  theme: PropTypes.string,
}

SideNavCollapser.defaultProps = {
  theme: 'black',
}

export default SideNavCollapser
