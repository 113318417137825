import { math } from 'polished'
import styled, { css } from 'styled-components'

import UnstyledUl from 'styles/components/UnstyledUl'

export const NavigationBox = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: ${p => p.theme.blocks.menuHeight};
  height: ${p => math(`${p.theme.blocks.menuHeight} * 2`)};
  transform: ${p =>
    p.isActive && !p.isTransformed ? 'translateY(0)' : `translateY(-50%)`};
  background-color: ${p => p.theme.colors[p.colorTheme]};
  transition-property: transform, opacity, background-color, color;
  transition-duration: ${p => p.theme.animation.timeStandard};
  transition-delay: ${p => (p.isTransformed ? '.2s' : '0s')};
  transition-timing-function: ease-in-out;
  z-index: ${p => (p.isTransformed ? 1 : -3)};
  visibility: hidden;
  color: ${({ colorTheme, theme: { navigation } }) =>
    navigation[colorTheme].textColor};

  ${p => p.theme.mq.upMd} {
    visibility: visible;
    padding-top: ${p => p.theme.blocks.menuHeightMd};
    height: ${p => math(`${p.theme.blocks.menuHeightMd} * 2`)};
  }
`

export const List = styled(UnstyledUl)`
  opacity: ${p => (p.status === 'entered' ? 1 : 0)};
  transition: opacity ${p => p.theme.animation.timeFastest} ease-in-out;
  height: 100%;

  ${p =>
    p.status === 'exiting' &&
    css`
      position: absolute;
      top: ${p => math(`${p.theme.blocks.menuHeight} / 2`)};
      left: 0;
      right: 0;
      bottom: 0;

      ${p => p.theme.mq.upMd} {
        top: ${p => math(`${p.theme.blocks.menuHeightMd} / 2`)};
      }
    `}
`

/*
 * There is a little bit mess with logic because of em's
 * In the scaling interval line is shaking for .5px and consequently diverge with
 * bottom line of 1st level of menu
 * Therefore we have some logic to avoid it:
 * - we are hide line, when 2nd lvl of menu is not active
 */
export const Line = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: ${({ colorTheme, theme: { navigation } }) =>
    navigation[colorTheme].lineColor};
  visibility: ${p => (p.isActive ? 'visible' : 'hidden')};
  transition: background-color ${p => p.theme.animation.timeStandard}
    ease-in-out;
`
