import { fork, all } from 'redux-saga/effects'

import cartSaga from './cartSaga'
import pageSaga from './localizationSaga'
import urlFailureSaga from './urlFailureSaga'
import userProfileSaga from './userProfileSaga'

export default function* rootSaga() {
  const sagas = [
    fork(pageSaga),
    fork(cartSaga),
    fork(urlFailureSaga),
    fork(userProfileSaga),
  ]

  if (!process.browser) {
    sagas.push(fork(require('./serverLoggerSaga').default))
  }

  yield all(sagas)
}
