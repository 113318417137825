import { em, math } from 'polished'

// Navigation
export const sideNavWidth = em(260) //sidenav width
export const sideNavWidthMd = em(295) //tablet sidenav width
export const menuHeight = em(51) //mobile menu height
export const menuHeightMd = em(66) //desktop menu height
export const menuHeightX2Md = math(`${menuHeightMd} * 2`) //desktop menu height

// First block
export const heightBlock1Lg = em(720) //min height of first block in pages — desktop
export const heightBlock1Md = em(650) //min height of first block in pages — tablet
export const heightBlock1 = em(600) //min height of first block in pages - mobile

/**
 * Default heights of sections
 */
export const heightBlock500 = em(500)
export const heightBlock560 = em(560)

// only ancestry
// TODO: fix it!!!
export const heightBlock500Md = em(500)

// Block with 630 height on desktop + tablet
// Protection blocks
// Cutting edge block
export const heightBlock630Md = em(630)

// Block with squares
// Block with publications
// Take care block
// Block with three icons
export const heightBlockSquares = em(576)

// Block with dark blue background height on Desktop + Tablet
// only dna about definition
// TODO: fix it!!!
export const heightBlockDarkBlue = em(490)

// Results block
export const heightMinResults = em(490)
export const heightMinResultsMd = em(620)
export const heightMinResultsLg = em(620)

// Block with square size
export const squareSize = em(100)
export const squareSizeMd = em(144)

// Small square size (for example: icon in square or header on faq page)
export const squareSizeSmall = em(120)

// Muted block opacity
export const blockMutedOpacity = 0.4

// radius
export const blockBorderRadius = em(5)
export const blockBorderRadiusForm = em(15)
export const blockBorderRadiusMedium = em(20)
export const blockBorderRadiusLarge = em(30)

// Icon sizes
export const iconInfoW = em(23)
export const iconInfoH = em(23)
export const iconDisclaimerW = em(25)
export const iconDisclaimerH = em(25)

// Small circle size
export const circleSizeSmall = em(120)
