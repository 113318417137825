import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'
import React from 'react'

const Timer = dynamic(() => import('./components/Timer'))
import useScrollOnMainAddOnProduct from 'components/_special/SalesBanner/hooks/useScrollOnMainAddOnProduct'
import ButtonRoundedSt from 'components/ButtonRoundedSt'
import { cart } from 'routes'
import { Col } from 'styles/components/layout'
import { gtmEvent } from 'utils/client/analitics'

import {
  RowStyled,
  TextBox,
  TextStyled,
  ButtonBox,
  ButtonContent,
  bgStyles,
  textStyles,
} from './styles'

const btnTheme = {
  black: {
    bg: 'black',
    text: 'white',
  },
  white: {
    bg: 'white',
    text: 'black',
  },
}

const TimerBanner = ({
  activeBanner,
  bannerRef,
  text,
  button,
  locale,
  route,
  onEnd,
}) => {
  const isCart = React.useMemo(() => route.config?.basePath === cart.basePath, [
    route,
  ])

  const sendEvent = React.useCallback(() => {
    gtmEvent({
      event: 'Banner button - clicked',
    })
  }, [])

  const { onClick } = useScrollOnMainAddOnProduct({ route, bannerRef, locale })

  const theme = btnTheme[activeBanner.btnColor || 'black']

  return (
    <RowStyled
      alignItems="center"
      height={{ md: '100%' }}
      justifyContent={{ xs: 'center' }}
      flexDirection={{ xs: 'column', md: 'row' }}
    >
      <Col>
        <Timer end={activeBanner.end} isSmall={isCart} onEnd={onEnd} />
      </Col>
      <Col>
        <TextBox isLast={!button || isCart}>
          <TextStyled
            markColor={activeBanner.markColor}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        </TextBox>
      </Col>
      {!!button && !isCart && (
        <Col>
          <ButtonBox onClick={sendEvent}>
            <ButtonRoundedSt
              as="button"
              bgStyles={bgStyles(theme.bg)}
              textStyles={textStyles(theme.text)}
              size="fit"
              onClick={onClick}
            >
              <ButtonContent>
                <span dangerouslySetInnerHTML={{ __html: button }} />
              </ButtonContent>
            </ButtonRoundedSt>
          </ButtonBox>
        </Col>
      )}
    </RowStyled>
  )
}

TimerBanner.propTypes = {
  bannerRef: PropTypes.object.isRequired,
  activeBanner: PropTypes.shape({
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
    btnColor: PropTypes.string.isRequired,
    markColor: PropTypes.string,
  }).isRequired,
  text: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  route: PropTypes.object,
  onEnd: PropTypes.func.isRequired,
}

export default React.memo(TimerBanner)
