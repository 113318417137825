import {
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAILURE,
} from 'constants/actionTypes'

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [USER_PROFILE_REQUEST]: (state, action) => ({
    ...state,
    error: null,
    isFetching: true,
    ...action.payload,
  }),
  [USER_PROFILE_SUCCESS]: (state, action) => ({
    ...state,
    isFetching: false,
    ...action.payload,
  }),
  [USER_PROFILE_FAILURE]: (state, { payload }) => ({
    ...initialState,
    error: { ...payload },
  }),
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isFetching: false,
  avatar: null,
  gender: null,
  error: null,
}

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
