import { withRouter } from 'next/router'
import { connect } from 'react-redux'
import { compose } from 'redux'

import Component from './CheckRouteRendering'

function mapStateToProps(state) {
  return {
    location: state.location,
  }
}

const mapDispatchToProps = {}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Component)
