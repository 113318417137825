import { em } from 'polished'
import styled, { css } from 'styled-components'

import DynamicImage from 'components/DynamicImage'
import { Col, RowIndented } from 'styles/components/layout'
import { Text } from 'styles/components/typography'

export const ColStyled = styled(Col)`
  height: 100%;
  ${p => p.theme.mq.upMd} {
    display: flex;
    align-items: center;
  }
`

export const RowIndentedStyled = styled(RowIndented)`
  flex-wrap: nowrap;
  height: 100%;
`

export const ImageBox = styled.div`
  position: absolute;
  right: ${p => em(p.isCart ? 12 : 25)};
  bottom: ${em(20)};

  ${p => p.theme.mq.upMd} {
    position: relative;
    right: 0;
    bottom: 0;
    margin-right: ${em(15)};
  }
  ${p => p.theme.mq.upLg} {
    align-self: center;
  }
`

export const DynamicImageStyled = styled(DynamicImage)`
  position: relative;
  display: block;
  // max-width: ${em(26)};
  width: ${p => em(p.imageSizes.xs)};
  height: auto;

  ${p => p.theme.mq.upMd} {
    //max-width: ${em(20)};
    width: ${p => em(p.imageSizes.md)};
  }

  ${p => p.theme.mq.upLg} {
    width: ${p => em(p.imageSizes.lg)};
  }
`

export const TextBox = styled.div`
  max-width: ${em(280)};
  margin-top: ${em(3)};
  margin-bottom: ${em(6)};

  ${p => p.theme.mq.upMd} {
    margin-top: 0;
    margin-bottom: 0;
    max-width: none;
    text-align: center;
  }

  ${p => p.theme.mq.upLg} {
    text-align: left;
  }
`

export const TextStyled = styled(Text)`
  font-size: ${p => p.theme.typography.cartFontSize} !important;
  line-height: 20px !important;
  font-weight: 400 !important;

  ${p => p.theme.mq.downMd} {
    margin-top: 0 !important;
    margin-bottom: ${em(20, 16)} !important;
  }

  & > a {
    color: #EAFC7A;
  }

  & > b {
    font-family: ${p => p.theme.typography.mediumFont};
    font-weight: 400;
  }

  & > mark {
    color: ${p => p.theme.colors.black};
    background: ${p => p.theme.colors.transparent};
  }

  & > span.discount__badge {
    padding: 2.5px 5.5px 4.5px 5px;
    background: #FFCA00;
    color: black;
    border-radius: 6px;
    font-size: 16px;
    line-height: 20px;
  }

  & > span.additional {
    color: ${p => p.theme.colors.pink};
  }

  ${p => p.theme.mq.upMd} {
    line-height: 22px !important;
  }

  ${p => p.theme.mq.upLg} {
    white-space: nowrap;
    line-height: 22px !important;
  }
`

export const ButtonBox = styled.div`
  margin-top: ${em(13)};
  flex-shrink: 0;

  ${p => p.theme.mq.upMd} {
    margin-top: 0;
    margin-left: ${em(20)};
  }
`

export const ButtonContent = styled.div`
  padding: ${em(2)} ${em(22)} ${em(4)};
`

export const bgStyles = color => css`
  background-color: ${p => p.theme.colors[color]};
`

export const textStyles = color => css`
  color: ${p => p.theme.colors[color]};
`
