import {
  PAGE_REQUEST,
  PAGE_FAILURE,
  PAGE_SUCCESS,
  CHANGE_LOCALE,
  SALES_INFO_REQUEST,
  SALES_INFO_SUCCESS,
  SALES_INFO_FAILURE,
  DELIVERY_ZONE_REQUEST,
  DELIVERY_ZONE_SUCCESS,
  DELIVERY_ZONE_FAILURE,
} from 'constants/actionTypes'

const getLocalizationAttrs = ({
  locale = '',
  language = '',
  countries = [],
  currentCountry = {},
  coordinates = {},
  paths = {},
  cdnPaths = {},
  sales = {
    delivery: {
      cost: 0,
      from: '',
      to: '',
    },
    isDiscountActive: false,
    specialDistribution: false,
    withoutSales: false,
    isFetching: false,
    currency: '',
  },
}) => ({
  locale,
  language,
  countries,
  currentCountry,
  coordinates,
  paths,
  cdnPaths,
  sales,
})

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [CHANGE_LOCALE]: (state, { payload }) => {
    const mergeResult = { ...state, ...getLocalizationAttrs(payload) }

    if (payload.language !== state.language) {
      mergeResult.pages = {}
    }

    return mergeResult
  },
  [PAGE_REQUEST]: (state, { payload }) => {
    const { page } = payload

    return {
      ...state,
      pagesFetching: {
        ...state.pagesFetching,
        [page]: true,
      },
    }
  },
  [PAGE_SUCCESS]: (state, { payload }) => {
    const { name, pages } = payload

    return {
      ...state,
      pages: {
        ...state.pages,
        ...pages,
      },
      pagesFetching: {
        ...state.pagesFetching,
        [name]: false,
      },
    }
  },
  [PAGE_FAILURE]: (state, { payload }) => {
    const { name } = payload

    return {
      ...state,
      pagesFetching: {
        ...state.pagesFetching,
        [name]: false,
      },
    }
  },
  [SALES_INFO_REQUEST]: state => {
    return {
      ...state,
      sales: {
        ...state.sales,
        isFetching: true,
      },
    }
  },
  [SALES_INFO_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      sales: {
        ...state.sales,
        ...payload,
        isFetching: false,
      },
    }
  },
  [SALES_INFO_FAILURE]: (state, { payload }) => {
    return {
      ...state,
      sales: {
        ...state.sales,
        error: payload,
        isFetching: false,
      },
    }
  },
  [DELIVERY_ZONE_REQUEST]: state => {
    return {
      ...state,
      sales: {
        ...state.sales,
        isFetching: true,
      },
    }
  },
  [DELIVERY_ZONE_SUCCESS]: (state, { payload }) => {
    const { deliveryZoneId } = payload
    const newDelivery = { ...state.sales.delivery.zones[deliveryZoneId] }

    return {
      ...state,
      sales: {
        ...state.sales,
        isFetching: false,
        delivery: { ...state.sales.delivery, ...newDelivery },
      },
    }
  },
  [DELIVERY_ZONE_FAILURE]: (state, { payload }) => {
    return {
      ...state,
      sales: {
        error: payload,
        isFetching: false,
      },
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  ...getLocalizationAttrs({}),
  pages: {},
  pagesFetching: {},
}

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
