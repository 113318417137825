import React from 'react'

import { Svg } from './styles'

function CookieIcon() {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      fill="none"
      viewBox="0 0 60 60"
    >
      <path
        fill="url(#paint0_radial)"
        d="M60 30c0 13.9-12.43 30-29 30C11.77 60 0 47.57 0 31 0 9.1 15.43 0 32 0s28 10.76 28 30z"
      />
      <path
        fill="#965F27"
        d="M9.479 25.65c-1.17-.6-2.54 1.3-.69 1.52a.84.84 0 00.69-1.52zm16.17-11.84c-1.47-.75-3.17 1.62-.86 1.9a1.042 1.042 0 00.86-1.9zm13.88 23.25c-1.17-.6-2.54 1.3-.69 1.52a.84.84 0 00.69-1.52zm6.39-14.35c-1.47-.75-3.17 1.62-.86 1.9a1.042 1.042 0 00.86-1.9zm-21.4 18.19c-1.47-.75-3.17 1.62-.86 1.9a1.042 1.042 0 00.86-1.9z"
        opacity="0.2"
      />
      <path
        fill="#965F27"
        d="M29.51 20.89c-8.87.77-8.8 16.5 2.26 11.14 8.45-4.08 1.91-11.5-2.26-11.14zM47 27c-6.89 3.06-2.49 15.63 4.89 8.29 5.66-5.6-1.62-9.74-4.89-8.29zm-31.37 8.28c-7-3.91-15 7.93-3.94 9.51 8.4 1.21 7.25-7.67 3.94-9.51zm-.45-24.77C8.67 8.65 4.44 19.84 13.76 19c7.1-.56 4.48-7.61 1.42-8.49zm19.21-7.6c-6.87 1.089-5.89 13.37 2.41 8.56 6.34-3.681.81-9.081-2.42-8.55l.01-.01zM32 43.62c-7.69 1.25-6.6 15 2.71 9.58 7.07-4.12.88-10.2-2.71-9.58zm15.7-31.47c-4.8.78-4.11 9.33 1.69 6 4.42-2.6.61-6.37-1.69-6z"
        opacity="0.35"
      />
      <path
        fill="url(#paint1_radial)"
        d="M29.508 21.5c-8 .69-7.92 14.85 2 10 7.64-3.65 1.75-10.32-2-10z"
      />
      <path
        fill="url(#paint2_radial)"
        d="M47.22 27.53c-6.16 2.74-2.22 14 4.37 7.42 5.04-5.01-1.47-8.7-4.37-7.42z"
      />
      <path
        fill="url(#paint3_radial)"
        d="M15.311 35.69c-6.41-3.56-13.59 7.21-3.58 8.65 7.64 1.09 6.6-6.98 3.58-8.65z"
      />
      <path
        fill="url(#paint4_radial)"
        d="M14.999 11c-5.81-1.67-9.59 8.33-1.27 7.62 6.32-.62 3.98-6.88 1.27-7.62z"
      />
      <path
        fill="url(#paint5_radial)"
        d="M34.43 3.42c-6.15 1-5.27 12 2.17 7.66 5.67-3.29.72-8.08-2.17-7.66z"
      />
      <path
        fill="url(#paint6_radial)"
        d="M32 44.18c-6.9 1.13-5.92 13.44 2.43 8.6 6.39-3.7.84-9.13-2.43-8.6z"
      />
      <path
        fill="url(#paint7_radial)"
        d="M47.74 12.62c-4.13.67-3.55 8 1.46 5.15 3.8-2.22.48-5.47-1.46-5.15z"
      />
      <defs id="defsId">
        <radialGradient
          id="paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(30 0 0 30 28.88 27.87)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAC37D" />
          <stop offset="0.44" stopColor="#F8C17B" />
          <stop offset="0.64" stopColor="#F2BA75" />
          <stop offset="0.79" stopColor="#E7AD6B" />
          <stop offset="0.91" stopColor="#D79B5C" />
          <stop offset="1" stopColor="#C7894D" />
        </radialGradient>
        <radialGradient
          id="paint1_radial"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(6.498 -2.1888 1.82287 5.41166 28.049 24.713)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.42" stopColor="#965A42" />
          <stop offset="0.53" stopColor="#8B543E" />
          <stop offset="0.72" stopColor="#6F4433" />
          <stop offset="0.97" stopColor="#402A21" />
          <stop offset="1" stopColor="#3B271F" />
        </radialGradient>
        <radialGradient
          id="paint2_radial"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(53.2966 0 0 55.6312 505.769 -289.257)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.42" stopColor="#965A42" />
          <stop offset="0.53" stopColor="#8B543E" />
          <stop offset="0.72" stopColor="#6F4433" />
          <stop offset="0.97" stopColor="#402A21" />
          <stop offset="1" stopColor="#3B271F" />
        </radialGradient>
        <radialGradient
          id="paint3_radial"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(66.8127 0 0 58.5145 149.148 -223.6)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.42" stopColor="#965A42" />
          <stop offset="0.53" stopColor="#8B543E" />
          <stop offset="0.72" stopColor="#6F4433" />
          <stop offset="0.97" stopColor="#402A21" />
          <stop offset="1" stopColor="#3B271F" />
        </radialGradient>
        <radialGradient
          id="paint4_radial"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(42.2589 0 0 30.3091 139.932 34.894)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.42" stopColor="#965A42" />
          <stop offset="0.53" stopColor="#8B543E" />
          <stop offset="0.72" stopColor="#6F4433" />
          <stop offset="0.97" stopColor="#402A21" />
          <stop offset="1" stopColor="#3B271F" />
        </radialGradient>
        <radialGradient
          id="paint5_radial"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(43.7826 0 0 43.041 349.305 -439.018)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.42" stopColor="#965A42" />
          <stop offset="0.53" stopColor="#8B543E" />
          <stop offset="0.72" stopColor="#6F4433" />
          <stop offset="0.97" stopColor="#402A21" />
          <stop offset="1" stopColor="#3B271F" />
        </radialGradient>
        <radialGradient
          id="paint6_radial"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(61.5399 0 0 60.4053 361.272 -144.623)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.42" stopColor="#965A42" />
          <stop offset="0.53" stopColor="#8B543E" />
          <stop offset="0.72" stopColor="#6F4433" />
          <stop offset="0.97" stopColor="#402A21" />
          <stop offset="1" stopColor="#3B271F" />
        </radialGradient>
        <radialGradient
          id="paint7_radial"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(20.0827 0 0 19.6958 338.041 -237.12)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.42" stopColor="#965A42" />
          <stop offset="0.53" stopColor="#8B543E" />
          <stop offset="0.72" stopColor="#6F4433" />
          <stop offset="0.97" stopColor="#402A21" />
          <stop offset="1" stopColor="#3B271F" />
        </radialGradient>
      </defs>
    </Svg>
  )
}

export default CookieIcon
