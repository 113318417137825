import {
  escape,
  has,
  isNumber,
  isBoolean,
  isString,
  round,
  memoize,
} from 'lodash'

import { GOODS_TYPES, DELIVERY_TYPES } from 'constants/sales'
import LocalStorage, {
  LS_CART_CODE,
  LS_ORDER_CODE,
} from 'utils/client/localStorage'
import { getGETQueries } from 'utils/client/url'

const lStorage = new LocalStorage()
const { STRIPE_PAYMENT_KEY } = process.env.server
const { DYNAMIC_DISCOUNT_PROMO } = process.env.localization

export const productCost = ({
  productConfig,
  amount,
  isSubscription = false,
}) => {
  const curProduct = isSubscription ? productConfig.subscription : productConfig
  const cartCost = amount * curProduct.currentPrice
  const realCost = amount * curProduct.price

  return {
    cost: round(cartCost, 2),
    realCost: round(realCost, 2),
  }
}

export const getSummary = ({
  amount,
  cost,
  realCost,
  deliveryConfig,
  deliveryType,
}) => {
  let deliveryCost
  let isDeliveryFree

  if (STRIPE_PAYMENT_KEY) {
    /*
     * For the europe region: delivery cost is depends on condition
     * delivery = 0 if there are 2 or more kits in the cart
     */
    deliveryCost = amount > 1 ? 0 : deliveryConfig.currentCost
    isDeliveryFree = amount > 1
  } else {
    /*
     * For the russian region: delivery cost is depends on conditions
     * delivery = 0 if there are 2 or more kits in the cart
     * delivery = 0 if delivery type === pickup
     */
    deliveryCost =
      amount > 1 || deliveryType === DELIVERY_TYPES.pickup
        ? 0
        : deliveryConfig.currentCost
    isDeliveryFree = amount > 1 && deliveryType !== DELIVERY_TYPES.pickup
  }

  const costWithDelivery = cost + deliveryCost
  const realCostWithDelivery = realCost + deliveryCost

  return {
    amount,
    isDeliveryFree,
    delivery: deliveryCost,
    cost: round(costWithDelivery, 2),
    realCost: round(realCostWithDelivery, 2),
    diff: round(realCostWithDelivery - costWithDelivery, 2),
  }
}

const memoFilterDeliveryTypes = memoize(
  baseDeliveryTypes =>
    baseDeliveryTypes.filter(({ value }) => value !== DELIVERY_TYPES.pickup),
  (baseDeliveryTypes, biomeAdded, isSubscription) =>
    `${JSON.stringify(baseDeliveryTypes)}_${biomeAdded}_${isSubscription}`,
)

export const filterDeliveryTypes = (baseDeliveryTypes, goods) => {
  if (!baseDeliveryTypes || baseDeliveryTypes.length < 1) {
    return null
  }

  const {
    [GOODS_TYPES.biome]: { isSubscription, amount },
  } = goods
  const biomeAdded = amount > 0
  if (biomeAdded && isSubscription) {
    return memoFilterDeliveryTypes(
      baseDeliveryTypes,
      biomeAdded,
      isSubscription,
    )
  }

  return baseDeliveryTypes
}

//
// Save cart to Local Storage
export const saveCartToLS = cart => {
  const { dna, biome, wgs } = GOODS_TYPES
  const cartForSave = {
    goods: {
      [dna]: {
        amount: cart.goods[dna].amount,
      },
      [biome]: {
        amount: cart.goods[biome].amount,
        isSubscription: cart.goods[biome].isSubscription,
      },
      [wgs]: {
        amount: cart.goods[wgs].amount,
      },
    },
    promo: {
      name: cart.promo.name,
    },
  }

  lStorage.setItem(LS_CART_CODE, cartForSave)
}

//
// Save order to Local Storage
export const saveOrderToLS = ({ salesConfig, cart }) => {
  lStorage.setItem(LS_ORDER_CODE, { salesConfig, cart })
}

//
// Clear Local Storage
export const clearCartInLS = () => {
  lStorage.setItem(LS_ORDER_CODE, {})
  lStorage.setItem(LS_CART_CODE, {})
}

//
// Get cart info from Local Storage
export const getCartFromLS = () => {
  const cart = {
    promo: {
      name: '',
    },
    goods: [],
  }

  const { dna, biome, wgs } = GOODS_TYPES
  const savedCart = lStorage.getItem(LS_CART_CODE, true)
  const isCorrectCart =
    has(savedCart, `goods.${dna}.amount`) &&
    isNumber(savedCart.goods[dna].amount) &&
    has(savedCart, `goods.${wgs}.amount`) &&
    isNumber(savedCart.goods[wgs].amount) &&
    has(savedCart, `goods.${biome}.amount`) &&
    isNumber(savedCart.goods[biome].amount) &&
    has(savedCart, `goods.${biome}.isSubscription`) &&
    isBoolean(savedCart.goods[biome].isSubscription) &&
    has(savedCart, 'promo.name') &&
    isString(savedCart.promo.name)

  if (isCorrectCart) {
    const amount =
      savedCart.goods[dna].amount +
      savedCart.goods[wgs].amount +
      savedCart.goods[biome].amount
    if (
      savedCart.promo.name &&
      !(
        savedCart.promo.name.toLowerCase() ===
          DYNAMIC_DISCOUNT_PROMO.toLowerCase() && amount < 2
      )
    ) {
      cart.promo.name = savedCart.promo.name
    }
    if (savedCart.goods[dna].amount) {
      cart.goods.push({
        productType: dna,
        isSubscription: false,
        amount: Math.max(0, savedCart.goods[dna].amount),
      })
    }
    if (savedCart.goods[wgs].amount) {
      cart.goods.push({
        productType: wgs,
        isSubscription: false,
        amount: Math.max(0, savedCart.goods[wgs].amount),
      })
    }
    if (savedCart.goods[biome].amount) {
      cart.goods.push({
        productType: biome,
        isSubscription: savedCart.goods[biome].isSubscription,
        amount: Math.max(0, savedCart.goods[biome].amount),
      })
    }
  }

  return cart
}

//
// Get cart info from url
//
//   ?product=dna - один тест dna
//   ?product=biome - один тест biome
//   ?product= - один тест dna и один тест biome
//   ?promocode=welcome - применяет промокод
//   ?subscription=true - добавляет одну подписку (при этом product=dna и product=biome игнорируются)
export const getCartFromURL = () => {
  const queries = getGETQueries()
  const { dna, biome, wgs } = GOODS_TYPES
  let promocode = queries.promocode ? escape(queries.promocode) : null
  if (
    promocode &&
    promocode.toLowerCase() === DYNAMIC_DISCOUNT_PROMO.toLowerCase()
  )
    promocode = null

  const cart = {
    promo: {
      name: promocode,
    },
    goods: [],
  }

  if (queries.subscription === 'true') {
    cart.goods.push({
      productType: biome,
      isSubscription: true,
      amount: 1,
    })
  } else {
    if (queries.product === '') {
      cart.goods.push({
        productType: dna,
        isSubscription: false,
        amount: 1,
      })
      cart.goods.push({
        productType: biome,
        isSubscription: false,
        amount: 1,
      })
    } else if (queries.product === 'dna') {
      cart.goods.push({
        productType: dna,
        isSubscription: false,
        amount: 1,
      })
    } else if (queries.product === 'biome') {
      cart.goods.push({
        productType: biome,
        isSubscription: false,
        amount: 1,
      })
    } else if (queries.product === 'wgs') {
      cart.goods.push({
        productType: wgs,
        isSubscription: false,
        amount: 1,
      })
    } else if (queries.product === 'biome_bundle') {
      cart.goods.push({
        productType: biome,
        isSubscription: false,
        amount: 2,
      })
    }
  }

  return cart
}
