import { xs, md, lg, xl, lgMenu } from 'styles/theme/breakpoints'

export const mediaCodes = {
  upXs: 'upXs',
  upMd: 'upMd',
  upLg: 'upLg',
  upXl: 'upXl',
  upXs2x: 'upXs2x',
  upMd2x: 'upMd2x',
  upLg2x: 'upLg2x',
  upXl2x: 'upXl2x',
}
const retina = [
  '(-webkit-min-device-pixel-ratio: 1.3)',
  '(-o-min-device-pixel-ratio: 13 / 10)',
  '(min-resolution: 120dpi)',
]
const extendRetina = query => {
  return retina.map(item => `${query} and ${item}`).join(', ')
}
export default {
  isRetina: `@media ${extendRetina('only screen')}`,
  [mediaCodes.upXs]: `@media (min-width: ${xs}px)`,
  [mediaCodes.upMd]: `@media (min-width: ${md}px)`,
  [mediaCodes.upLg]: `@media (min-width: ${lg}px)`,
  [mediaCodes.upXl]: `@media (min-width: ${xl}px)`,
  [mediaCodes.upXs2x]: `@media ${extendRetina(`(min-width: ${xs}px)`)}`,
  [mediaCodes.upMd2x]: `@media ${extendRetina(`(min-width: ${md}px)`)}`,
  [mediaCodes.upLg2x]: `@media ${extendRetina(`(min-width: ${lg}px)`)}`,
  [mediaCodes.upXl2x]: `@media ${extendRetina(`(min-width: ${xl}px)`)}`,
  downMd: `@media (max-width: ${md - 1}px)`,
  downLg: `@media (max-width: ${lg - 1}px)`,
  hover: `@media (hover: hover) and (pointer: fine)`,
  upThreshold: minWidth => `@media (min-width: ${minWidth}px)`,
  upLgMenu: `@media (min-width: ${lgMenu}px)`,
}
