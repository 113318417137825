import { em } from 'polished'
import styled from 'styled-components'

import Underline from 'styles/components/Underline'
import UnstyledUl from 'styles/components/UnstyledUl'

const checkSize = em(18)

export const Box = styled.div`
  padding-top: ${em(30)};
`

export const Title = styled.span`
  position: relative;
  color: ${p => p.theme.colors.muted};
  font-size: ${p => p.theme.typography.fontSize};
  text-transform: none;
`

export const TitleUnderline = styled(Underline)``

export const List = styled(UnstyledUl)``

export const IconBox = styled.div`
  position: absolute;
  top: 50%;
  right: ${em(15)};
  width: ${checkSize};
  height: ${checkSize};
  transform: translateY(-50%);
  margin-top: -1px;
`

export const ActiveCountryBox = styled.div`
  cursor: default !important;
  font-family: ${p => p.theme.typography.mediumFont};
  opacity: 1 !important;
`
