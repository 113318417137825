import PropTypes from 'prop-types'
import React from 'react'

import useScrollOnMainAddOnProduct from 'components/_special/SalesBanner/hooks/useScrollOnMainAddOnProduct'
import ButtonRoundedSt from 'components/ButtonRoundedSt'
import { cart } from 'routes'
import { gtmEvent } from 'utils/client/analitics'

import {
  ButtonBox,
  ColStyled,
  DynamicImageStyled,
  ImageBox,
  RowIndentedStyled,
  TextBox,
  TextStyled,
  bgStyles,
  ButtonContent,
  textStyles,
} from './styles'

const btnTheme = {
  black: {
    bg: 'black',
    text: 'white',
  },
  white: {
    bg: 'white',
    text: 'black',
  },
  materialRed: {
    bg: 'materialRed',
    text: 'white',
  },
}

const AdvertisementBanner = ({
  activeBanner,
  dict,
  paths,
  text,
  button,
  route,
  locale,
  bannerRef,
  ...otherTexts
}) => {
  const {
    buttons: { buy },
  } = dict

  const isCart = React.useMemo(() => route.config?.basePath === cart.basePath, [
    route,
  ])
  const sendEvent = React.useCallback(() => {
    gtmEvent({
      event: 'Banner button - clicked',
    })
  }, [])

  const productType = React.useMemo(
    () => route.config?.isProductPage && route.config?.productType,
    [route],
  )

  const { onClick } = useScrollOnMainAddOnProduct({ route, locale, bannerRef })

  const buttonProps = React.useMemo(() => {
    if (activeBanner.link) {
      return {
        as: 'a',
        to: activeBanner.link,
      }
    } else {
      return {
        as: 'button',
        onClick,
      }
    }
  }, [activeBanner, onClick])

  const theme = btnTheme[activeBanner.btnColor || 'black']

  const isProductButton = !!productType && !!otherTexts[productType]

  return (
    <RowIndentedStyled
      indent={{ xs: 0, md: 0, lg: 0 }}
      alignItems={{ xs: 'center' }}
      justifyContent={{ md: 'center' }}
    >
      <ColStyled>
        <ImageBox isCart={isCart}>
          <DynamicImageStyled
            image={`${paths.shared}/banner/${activeBanner.img}`}
            imageSizes={activeBanner.imgSizes}
            namesByBreakpoints={true}
            imageType={activeBanner.imgType || 'png'}
            fitOption="none"
            alt={activeBanner.img}
            quality={{
              mobile: 100,
              mobile2x: 100,
              mobile3x: 100,
              tablet: 100,
              tablet2x: 100,
              tablet3x: 100,
              desktop: 100,
              retina: 100,
              uhd: 100,
            }}
          />
        </ImageBox>
        <TextBox>
          <TextStyled
            style={{ color: activeBanner?.textColor || 'white' }}
            dangerouslySetInnerHTML={{
              __html:
                (productType && otherTexts[productType]) ||
                (isCart && otherTexts.cart) ||
                text,
            }}
          />
        </TextBox>
        {!!button && !isCart && (
          <ButtonBox onClick={sendEvent}>
            <ButtonRoundedSt
              bgStyles={bgStyles(theme.bg)}
              textStyles={textStyles(theme.text)}
              size="fit"
              {...buttonProps}
            >
              <ButtonContent>
                <span
                  dangerouslySetInnerHTML={{
                    __html: isProductButton ? buy : button,
                  }}
                />
              </ButtonContent>
            </ButtonRoundedSt>
          </ButtonBox>
        )}
      </ColStyled>
    </RowIndentedStyled>
  )
}

AdvertisementBanner.propTypes = {
  bannerRef: PropTypes.object.isRequired,
  activeBanner: PropTypes.shape({
    name: PropTypes.string.isRequired,
    link: PropTypes.string,
    img: PropTypes.string.isRequired,
    imgType: PropTypes.oneOf(['png', 'svg', 'jpg']),
    imgSizes: PropTypes.shape({
      xs: PropTypes.number,
      md: PropTypes.number,
      lg: PropTypes.number,
    }),
    color: PropTypes.string.isRequired,
    btnColor: PropTypes.string,
  }).isRequired,
  dict: PropTypes.object,
  paths: PropTypes.object,
  route: PropTypes.object,

  /** text on main page and on other pages if they are not specified */
  text: PropTypes.string.isRequired,

  /** banner text on dna page */
  dna: PropTypes.string,

  /** banner text on biome page */
  biome: PropTypes.string,

  /** banner text on wgs page */
  wgs: PropTypes.string,

  /** banner text on cart page */
  cart: PropTypes.string,
  locale: PropTypes.string.isRequired,

  /** text in button */
  button: PropTypes.string,
}

export default React.memo(AdvertisementBanner)
