import PropTypes from 'prop-types'
import React from 'react'

const push = 'PUSH'

const ScrollToTop = ({ currentLocation }) => {
  React.useEffect(() => {
    if (currentLocation.action === push) {
      window.scrollTo(0, 0)
    }
  }, [currentLocation])

  return null
}

ScrollToTop.propTypes = {
  currentLocation: PropTypes.object.isRequired,
}

export default ScrollToTop
