import { throttle } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { ResizeProvider } from 'contexts/Resize'
import { checkBreakpoints } from 'utils/client/clientInfo'

const ResizeContainer = ({ children, resizeCb }) => {
  const [viewport, setViewport] = React.useState(() => checkBreakpoints(true))
  const prevViewport = React.useRef(viewport)

  React.useEffect(() => {
    const handleResize = throttle(() => {
      const viewport = checkBreakpoints()
      if (prevViewport.current.breakpoint !== viewport.breakpoint) {
        prevViewport.current = viewport
        setViewport(viewport)
      }

      resizeCb && resizeCb()
    }, 200)

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [resizeCb])

  return <ResizeProvider value={viewport}>{children}</ResizeProvider>
}

ResizeContainer.propTypes = {
  children: PropTypes.node,
  resizeCb: PropTypes.func,
}

export default ResizeContainer
