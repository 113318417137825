import PropTypes from 'prop-types'
import React from 'react'

import htmlDecode from 'utils/htmlDecode'

import { ButtonBox, Top, Middle, Bottom } from './styles'

const BurgerButton = ({
  onToggleSideNav,
  isSideNavOpen,
  withAlternateViewport,
  theme,
  labelOpen,
  labelClose,
}) => {
  const labelOpenDecoded = React.useMemo(() => htmlDecode(labelOpen), [
    labelOpen,
  ])
  const labelCloseDecoded = React.useMemo(() => htmlDecode(labelClose), [
    labelClose,
  ])

  return (
    <ButtonBox
      {...{ withAlternateViewport }}
      aria-label={isSideNavOpen ? labelCloseDecoded : labelOpenDecoded}
      colorTheme={theme}
      isActive={isSideNavOpen}
      className={'mr-1'}
      onClick={onToggleSideNav}
    >
      <Top />
      <Middle />
      <Bottom />
    </ButtonBox>
  )
}

BurgerButton.propTypes = {
  onToggleSideNav: PropTypes.func.isRequired,
  isSideNavOpen: PropTypes.bool,
  withAlternateViewport: PropTypes.bool,
  theme: PropTypes.string,
  labelOpen: PropTypes.string,
  labelClose: PropTypes.string,
}

BurgerButton.defaultProps = {
  isSideNavOpen: false,
  withAlternateViewport: false,
  theme: '',
}

export default BurgerButton
