import { takeEvery, call } from 'redux-saga/effects'

import {
  CART_PROMO_FAILURE,
  PAGE_FAILURE,
  USER_PROFILE_FAILURE,
  CART_COMMON_UPDATE_FAILURE,
} from 'constants/actionTypes'
import { DEVELOPMENT } from 'constants/environments'
import Sentry from 'utils/sentry'

const isDev = process.env.SERVER === DEVELOPMENT

/*
 * Array of actions to filter
 */
const filterFailureActions = [
  // filtered because that means that promocode was wrong
  CART_PROMO_FAILURE,
  // filtered because of huge amount of events - I moved dicts for locales. So old build got 404
  // It happens for one user per week but with huge amount of repeats
  PAGE_FAILURE,
  // filtered because we provide more detailed error to Sentry on the saga level
  CART_COMMON_UPDATE_FAILURE,
]
/*
 * Get all actions for dev
 */
const failureActionsPattern = isDev
  ? action => action.type.includes('FAILURE')
  : action =>
      action.type.includes('FAILURE') &&
      !filterFailureActions.includes(action.type) &&
      // !(action.type === PAGE_FAILURE && !('status' in action.payload)) && // page failure filtered caused on client side (no status of request)
      !(action.type === USER_PROFILE_FAILURE && action.payload.status === 403) // profile request failure 403 - token expired

/*
 * Catch url failure
 */
function* urlFailure(action) {
  if (isDev) {
    // Console logging for development
    yield call(console.log, '=========== ACTION FAILED ===========') // eslint-disable-line
    yield call(console.log, action) // eslint-disable-line
  } else {
    // Sentry logging for development
    Sentry.captureMessageWithScope(`ACTION FAILED: ${action.type}`, {
      'redux-action': action,
    })
  }
}

export default function* urlFailureSaga() {
  yield takeEvery(failureActionsPattern, urlFailure)
}
