import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Transition, TransitionGroup } from 'react-transition-group'

import NavigationItem from '../NavigationItem'

import { NavigationBox, List, Line } from './styles'

class NavigationSecondLvl extends Component {
  handleEnter = () => {
    const { onMouseEnter, parentRoute } = this.props

    onMouseEnter(parentRoute)
  }

  handleLeave = () => this.props.onMouseLeave(null)

  render() {
    const {
      leftPadding,
      isTransformed,
      parentRoute,
      isActive,
      theme,
    } = this.props

    return (
      <NavigationBox
        {...{ isTransformed, isActive }}
        colorTheme={theme}
        onMouseEnter={this.handleEnter}
        onMouseLeave={this.handleLeave}
      >
        <TransitionGroup component={null}>
          <Transition
            timeout={100}
            key={parentRoute?.route?.basePath || 'empty'}
          >
            {status => (
              <List status={status} style={{ paddingLeft: leftPadding }}>
                {isActive &&
                  parentRoute.items.map((route, key) => (
                    <NavigationItem key={key} item={route} />
                  ))}
              </List>
            )}
          </Transition>
        </TransitionGroup>
        <Line colorTheme={theme} {...{ isActive }} />
      </NavigationBox>
    )
  }
}

NavigationSecondLvl.propTypes = {
  leftPadding: PropTypes.number,
  parentRoute: PropTypes.object,
  isTransformed: PropTypes.bool,
  isActive: PropTypes.bool,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
  theme: PropTypes.string,
}

NavigationSecondLvl.defaultProps = {
  leftPadding: 0,
  theme: '',
}

export default NavigationSecondLvl
