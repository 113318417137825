import CSSPlugin from 'gsapAlias/CSSPlugin'
import TimelineLite from 'gsapAlias/TimelineLite'

// eslint-disable-next-line no-unused-vars
const plugins = [CSSPlugin]

const DURATION = 0.4

export const growingCounter = ({ counterNode }, cb) => {
  const timeline = new TimelineLite({ onComplete: cb })

  timeline
    .set(counterNode, {
      opacity: 1,
      scaleX: 0,
      scaleY: 0,
      z: 0,
    })
    .to(counterNode, DURATION, {
      scaleX: 1,
      scaleY: 1,
      ease: 'easeOut',
    })

  return timeline
}

export const hideCounter = ({ counterNode }, cb) => {
  const timeline = new TimelineLite({ onComplete: cb })

  timeline
    .set(counterNode, {
      opacity: 1,
      scaleX: 1,
      scaleY: 1,
      z: 0,
    })
    .to(counterNode, DURATION, {
      scaleX: 0,
      scaleY: 0,
      ease: 'easeOut',
    })

  return timeline
}
