import PropTypes from 'prop-types'
import React from 'react'

import { LocalizationConsumer } from 'contexts/Localization'
import { TextLH } from 'styles/components/typography'
import { Text } from 'shared/Typography'

import { NavItem, LinkNavStyled, LinkStyled } from './styles'

const NavigationItem = React.forwardRef(
  ({ className, item, onMouseLeave, onMouseEnter }, ref) => {
    const hasChildren = !!(item.items && item.items.length)
    const handleEnter = () => onMouseEnter && onMouseEnter(item)
    // const handleEnter = () => {
    //   if(onMouseEnter){
    //     onMouseEnter(hasChildren ? item : undefined)
    //   }
    // }
    const handleLeave = () => onMouseLeave && onMouseLeave(null)

    return (
      <NavItem
        ref={ref}
        className={className}
        onMouseEnter={handleEnter}
        onClick={handleEnter}
        onMouseLeave={handleLeave}
      >
        {item.route.as ? (
          <LocalizationConsumer>
            {({ locale }) => (
              <LinkNavStyled
                to={item.route.link(locale)}
                asPath={item.route.as(locale)}
                exact={!hasChildren}
                className="link"
                component={'a'}
              >
                <Text
                  as='span'
                  size='16/20'
                  dangerouslySetInnerHTML={{ __html: item.route.title }}
                />
              </LinkNavStyled>
            )}
          </LocalizationConsumer>
        ) : (
          <LinkStyled
            to={item.route.link()}
            className="link"
            isTargetBlank={true}
            component={'a'}
          >
            <TextLH dangerouslySetInnerHTML={{ __html: item.route.title }} />
          </LinkStyled>
        )}
      </NavItem>
    )
  },
)

NavigationItem.propTypes = {
  item: PropTypes.object.isRequired,
  className: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
}

NavigationItem.defaultProps = {
  className: '',
}

export default NavigationItem
