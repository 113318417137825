import { escape } from 'lodash'
import { getGETQueries } from 'utils/client/url'
const { DYNAMIC_DISCOUNT_PROMO } = process.env.localization

export const getActionHandlerFromURL = () => {
  const queries = getGETQueries();
  const action = queries.action;
  if (action === 'fill-cart') {
    return {
      type: 'fill-cart',
      func: fillCart,
    };
  }
  return null;
}

const fillCart = () => {
  const queries = getGETQueries()
  const action = queries.action
  let promocode = queries.promocode ? escape(queries.promocode) : null
  if (
    promocode &&
    promocode.toLowerCase() === DYNAMIC_DISCOUNT_PROMO.toLowerCase()
  )
    promocode = null
  const cart = {
    promo: {
      name: promocode,
    },
    goods: [],
  };
  const GOODS_TYPES_MAPPED = {
    microbiome: 'biome',
    dna: 'dna',
    wgs: 'wgs',
  }
  if (action === 'fill-cart'){
    if (queries.microbiome === 'subscription') {
      cart.goods.push({
        productType: GOODS_TYPES_MAPPED.microbiome,
        isSubscription: true,
        amount: 1,
      })
    }
    Object.keys(GOODS_TYPES_MAPPED).forEach((type) => {
      if (+queries[type] > 0) {
        cart.goods.push({
          productType: GOODS_TYPES_MAPPED[type],
          isSubscription: false,
          amount: +queries[type],
        })
      }
    });
  }
  return cart;
}
