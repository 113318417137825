import PropTypes from 'prop-types'
import React from 'react'

import Icon from 'components/Icon'
import Link from 'components/Link'
// import { TextLH } from 'styles/components/typography'

import './LinkBlockArrowShort.scss'
import { Wrapper } from './styles'

/**
 * Component of block link with text and short arrow
 * @return Component — LinkBlockArrowShort
 */

const TYPOGRAPHY_SIZES = {
  base: 'base',
  h2: 'lg',
  h1: 'xl',
}

const LinkBlockArrowShort = props => {
  const { size, className, isWhite, opacity, children, to, asPath, as } = props
  const linkClassNames = `link-block-short link ${className} `
  const arrowSizeClassNames = `link-block-short__arrow--${size}`
  const iconColor = isWhite ? 'white' : 'black'
  const strokeWidth = size === TYPOGRAPHY_SIZES.base ? '1.2' : '2'
  let textSizesClassNames = `text--${TYPOGRAPHY_SIZES[size]} `

  if (isWhite) {
    textSizesClassNames += 'link-block-short--white'
  }

  const inner = (
    <Wrapper {...{ opacity }}>
      {children}

      <div className={`link-block-short__arrow ${arrowSizeClassNames}`}>
        <Icon id='arrow_short' color={iconColor} strokeWidth={strokeWidth} />
      </div>
    </Wrapper>
  )

  if (as === 'a') {
    return (
      <a
        href={to}
        rel='noopener noreferrer'
        target='_blank'
        className={linkClassNames}
      >
        {inner}
      </a>
    )
  }

  return (
    <Link to={to} asPath={asPath} className={linkClassNames}>
      {inner}
    </Link>
  )
}

LinkBlockArrowShort.propTypes = {
  /** Link inline/inline-block content */
  children: PropTypes.node.isRequired,

  /** Location (server-side routing) */
  to: PropTypes.string.isRequired,

  /** Location for client-side nextjs routing */
  asPath: PropTypes.string,

  /** Kind of wrapper component */
  as: PropTypes.oneOf(['a', 'Link']),

  /** ClassName for root tag */
  className: PropTypes.string,

  /** Change default color of arrow to white */
  isWhite: PropTypes.bool,

  /** Size of arrow equal typography sizes */
  size: PropTypes.oneOf(Object.keys(TYPOGRAPHY_SIZES)),

  /** Opacity for text and arrow */
  opacity: PropTypes.number,
}

LinkBlockArrowShort.defaultProps = {
  as: 'Link',
  className: '',
  size: 'base',
  isWhite: false,
  opacity: 1,
}

export default LinkBlockArrowShort
