import PropTypes from 'prop-types'
import React from 'react'

import BtnCartAnimated from './components/BtnCartAnimated'
import BtnCartMobile from './components/BtnCartMobile'

import './ButtonCart.scss'

/**
 * ButtonCart — component to render cart button
 * (with animation or without for mobile)
 *
 * @param {object} props - properties of component
 * @returns {node} ButtonCart
 */
const ButtonCart = ({
  breakpoint,
  isMobile,
  isVisible,
  children,
  ...restProps
}) => {
  const wrapperClass = isVisible ? '' : 'button-cart--is-transparent '

  if (breakpoint === '') {
    return null
  }

  return (
    <div className={`button-cart ${wrapperClass}`}>
      {isMobile ? (
        <BtnCartMobile {...restProps} />
      ) : (
        <BtnCartAnimated {...restProps}>{children}</BtnCartAnimated>
      )}
    </div>
  )
}

ButtonCart.propTypes = {
  /** Props for button's wrapper */
  wrapperProps: PropTypes.object,

  /** Title for buy button */
  children: PropTypes.string,

  /** Name of breakpoint */
  breakpoint: PropTypes.string,

  /** Amount of goods in cart */
  amount: PropTypes.number,

  /** Flag to switch button to mobile format (ButtonCircle without animation) */
  isMobile: PropTypes.bool,

  /** Flag to hide button (exmp: when menu is opened or for some pages) */
  isVisible: PropTypes.bool,
}

ButtonCart.defaultProps = {
  isVisible: true,
}

export default ButtonCart
