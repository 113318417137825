import styled, { css } from 'styled-components'

export const NavigationBox = styled.nav`
  display: none;
  flex-grow: 1;
  height: 100%;
  align-items: center;
  overflow: hidden;
  will-change: transform;
  transition-property: transform, color, background-color;
  transition-duration: ${p => p.theme.animation.timeStandard};
  transition-timing-function: ease-in-out;
  transition-delay: ${p => (p.isTransformed ? '.2s' : '0s')};
  transform: translateY(${p => (p.isTransformed ? '-100%' : '0')});
  z-index: 5;
  color: ${({ colorTheme, theme: { navigation } }) =>
    navigation[colorTheme].textColor};
  background-color: ${({ colorTheme, theme: { navigation } }) =>
    navigation[colorTheme].bgColor};

  ${p => p.theme.mq.upMd} {
    display: flex;
  }
`

/*
 * There is a little bit mess with logic because of em's
 * In the scaling interval line is shaking for .5px and consequently diverge with
 * bottom line of 2nd level of menu
 * Therefore we have some logic to avoid it:
 * - we move line 1px up to avoid 1px gap
 * - we expand line for width of window when we dont see 2nd lvl of menu
 * - we use transition for left and right to synchronize expanding with 2nd lvl of menu animation
 */
export const Line = styled.div`
  display: block;
  position: fixed;
  height: 1px;
  top: calc(${p => p.theme.blocks.menuHeight} - 1px);
  left: ${p => p.theme.indents.baseIndentXs};
  right: ${p => p.theme.indents.baseIndentXs};
  background-color: ${({ colorTheme, theme: { navigation } }) =>
    navigation[colorTheme].lineColor};
  z-index: 5;
  opacity: ${p => (p.isTransformed ? 0 : 1)};
  transition: opacity 0.1s linear ${p => (p.isTransformed ? '0s' : '.3s')},
    left 0s linear 0s, right 0s linear 0s,
    background-color ${p => p.theme.animation.timeStandard} ease-in-out;

  ${p => p.theme.mq.upMd} {
    top: calc(${p => p.theme.blocks.menuHeightMd} - 1px);
    left: ${p => p.theme.indents.baseIndentMd};
    right: ${p => p.theme.indents.baseIndentMd};
  }

  ${p => p.theme.mq.upLg} {
    left: ${p => p.theme.indents.baseBoxIndentLg};
    right: ${p => p.theme.indents.baseBoxIndentLg};
  }

  ${p =>
    !p.is2ndLvlVisible &&
    css`
      left: 0 !important;
      right: 0 !important;
      transition: opacity 0.1s linear ${p => (p.isTransformed ? '0s' : '.3s')},
        left 0s linear ${p => p.theme.animation.timeStandard},
        right 0s linear ${p => p.theme.animation.timeStandard};
    `}
`
