import { em } from 'polished'
import styled, { css } from 'styled-components'

import { BoxBase } from 'styles/components/layout'

export const HeaderBox = styled(BoxBase)`
  position: fixed;
  left: 0;
  right: 0;
  height: ${p => p.theme.blocks.menuHeight};
  background-color: ${({ isTransparent, colorTheme, theme: { navigation } }) =>
    isTransparent ? 'transparent' : navigation[colorTheme].bgColor};
  visibility: ${p => (p.isHidden ? 'hidden !important' : 'visible')};
  z-index: 500;

  ${p => p.theme.mq.upMd} {
    height: ${p => p.theme.blocks.menuHeightMd};
  }

  ${({ withAlternateViewport }) =>
    withAlternateViewport &&
    css`
      ${p => p.theme.mq.upLgMenu} {
        transition: none;
        /* Background white for main menu comes from menu's 2nd lvl */
        background-color: transparent;
      }
    `}

  ${({ withAlternateViewport }) =>
    !withAlternateViewport &&
    css`
      ${p => p.theme.mq.upLg} {
        transition: none;
        /* Background white for main menu comes from menu's 2nd lvl */
        background-color: transparent;
      }
    `}

  ${({ isAlwaysColored }) =>
    isAlwaysColored &&
    css`
      ${p => p.theme.mq.upLgMenu} {
        background-color: ${({ colorTheme, theme: { navigation } }) =>
          navigation[colorTheme].bgColor};
      }

      ${p => p.theme.mq.upLg} {
        background-color: ${({ colorTheme, theme: { navigation } }) =>
          navigation[colorTheme].bgColor};
      }
    `}
`

export const HeaderInner = styled.div`
  position: relative;
  height: 100%;
`

export const RightBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  z-index: 10;

  ${p => p.theme.mq.upMd} {
    padding-bottom: ${em(1)};
  }
`
