import { em } from 'polished'

import { gridGutterWidth } from 'styles/theme/bootstrap'
import { inputErrorLh } from 'styles/theme/typography'

// Grid indents
export const baseBoxIndentLg = em('80px')
export const baseIndentMd = '40px'
export const baseIndentXs = '20px'

// Typography indents
export const paragraphIndent = em(15)
export const listLeftIndent = em(gridGutterWidth / 3)
export const listLeftIndentMd = em(gridGutterWidth / 2.2)
export const menuMainPaddingBottomMd = em(6)

// Forms indents
export const inputBottomIndent = `${inputErrorLh * 2}em` // for max 2 lines of input error text
