import { all, call, fork, put, takeLatest } from 'redux-saga/effects'

import {
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_FAILURE,
} from 'constants/actionTypes'
import { getUserProfile } from 'utils/api'
import LocalStorage, { TOKEN } from 'utils/client/localStorage'

const lStorage = new LocalStorage()
const token = lStorage.getItem(TOKEN)

/*
 * Get profile
 */
function* requestProfile() {
  if (token) {
    const { response, error } = yield call(getUserProfile, { token })

    if (response) {
      yield put({ type: USER_PROFILE_SUCCESS, payload: { ...response } })
    } else {
      yield put({ type: USER_PROFILE_FAILURE, payload: { ...error } })
    }
  } else {
    yield put({ type: USER_PROFILE_SUCCESS, payload: {} })
  }
}

function* getProfile() {
  yield takeLatest(USER_PROFILE_REQUEST, requestProfile)
}

export default function* rootSaga() {
  yield all([fork(getProfile)])
}
