import { LOCATION_CHANGE } from 'connected-next-router'

import { routerConfig } from 'routes'
import { getMatchedRouteAndParams } from 'utils/router'

const HISTORY_LIMIT = 2

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [LOCATION_CHANGE]: (state, { payload }) => {
    const { matchedRoute } = getMatchedRouteAndParams(
      routerConfig,
      payload.location.pathname,
    )

    const routeObject = {
      ...payload,
      config: matchedRoute,
    }
    const limitedHistory = [...state.history]

    limitedHistory.splice(HISTORY_LIMIT - 1, 1)
    limitedHistory.unshift(routeObject)

    return {
      current: routeObject,
      history: limitedHistory,
    }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
export const createLocationInitialState = (location, action) => {
  const { matchedRoute } = getMatchedRouteAndParams(
    routerConfig,
    location.pathname,
  )
  const current = {
    location,
    action,
    config: matchedRoute,
  }

  return {
    current: current,
    history: [current],
  }
}

const initialState = {
  current: null,
  history: [],
}

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : state
}
