import { withRouter } from 'next/router'
import { connect } from 'react-redux'

import { getPage, initCart } from 'actions'

import Component from './Localization'

function mapStateToProps(state) {
  return {
    localization: state.localization,
    location: state.location,
  }
}

const mapDispatchToProps = {
  getPage,
  initCart,
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Component),
)
