import { throttle } from 'lodash'
import React from 'react'

import { LocalizationContext } from 'contexts/Localization'
import { lgMenu, md } from 'styles/theme/breakpoints'

const useCustomMq = ({ onlyForLocale }) => {
  const [mobileMenu, setMobileMenu] = React.useState(false)
  const { locale } = React.useContext(LocalizationContext)

  React.useEffect(() => {
    if (onlyForLocale === locale) {
      const getRange = () => {
        if (window.innerWidth >= md && window.innerWidth < lgMenu) {
          setMobileMenu(true)
        } else {
          setMobileMenu(false)
        }
      }
      const throttledGetRange = throttle(getRange, 200)

      getRange()
      window.addEventListener('resize', throttledGetRange)

      return () => window.removeEventListener('resize', throttledGetRange)
    }
  }, [onlyForLocale, locale])

  return mobileMenu
}

export default useCustomMq
