import { em } from 'polished'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  padding-right: ${em(60)};
  opacity: ${p => p.opacity};

  .link-block-short__arrow {
    top: calc(50% - 4.5px);
  }
`
