import { em } from 'polished'
import styled, { css } from 'styled-components'

import { Text } from 'styles/components/typography'

const bittersweet = '#FF736B'
const cerulean = '#02B8EB'
const positioned = css`
  width: inherit;
  height: inherit;
  position: absolute;
  left: 0;
  top: 0;
`

export const Button = styled.div`
  width: ${p => p.theme.buttons.hActionBtnSlim};
  height: ${p => p.theme.buttons.hActionBtnSlim};
  border-radius: 50%;
  position: relative;

  ${p => p.theme.mq.upMd} {
    width: ${p => p.theme.buttons.hActionBtnSlimMd};
    height: ${p => p.theme.buttons.hActionBtnSlimMd};
  }
`

export const IconBlock = styled.div`
  ${positioned}
`

export const Avatar = styled.div`
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: ${p => p.theme.colors.blackOp07} url(${p => p.image}) center;
  background-size: cover;
  transition: transform ${p => p.theme.animation.timeStandard};
`

export const TextStyled = styled(Text)`
  display: none;
  margin-left: ${em(10)};
  color: ${({ colorTheme, theme: { navigation } }) =>
    navigation[colorTheme].textColor};
  transition-property: opacity, color;
  transition-duration: ${p => p.theme.animation.timeStandard};
  transition-timing-function: ease-in-out;

  ${p => p.theme.mq.upLg} {
    display: block;
  }
`

export const NoAvatar = styled.img`
  width: 70%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
`

export const Background = styled.div`
  border-radius: 50%;
  transition: transform ${p => p.theme.animation.timeStandard};
  ${positioned}

  ${({ gender }) =>
    gender === 'male' &&
    css`
      background-color: ${bittersweet};
    `}

  ${({ gender }) =>
    gender === 'female' &&
    css`
      background-color: ${cerulean};
    `}
`

export const Wrapper = styled.div`
  opacity: 1;
  transition: opacity ${p => p.theme.animation.timeFastest}
    ${p => p.theme.animation.timeStandard};

  ${({ isTransparent }) =>
    isTransparent &&
    css`
      opacity: 0;
      transition-delay: 0s;
    `}
`

export const Link = styled.a`
  display: flex;

  ${p => p.theme.mq.hover} {
    &:hover {
      ${TextStyled} {
        opacity: ${p => p.theme.blocks.blockMutedOpacity};
      }
      ${Background}, ${Avatar} {
        transform: scale(${p => p.theme.buttons.buttonCircleGrowingCoef})
          translateZ(0);
      }
    }
  }
`
