import { em } from 'polished'
import styled from 'styled-components'

import Icon from 'components/Icon'
import Link from 'components/Link'

const threshold = '450px'

export const Box = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  transition: transform ${p => p.theme.animation.timeBtns} ease-in-out;
  z-index: 20;
  transform: ${p =>
    p.isSideNavOpen ? `translateX(${p.theme.blocks.sideNavWidth})` : 'none'};
  pointer-events: ${p => (p.isSideNavOpen ? 'none' : 'all')};

  ${p => p.theme.mq.upMd} {
    padding-right: ${em(15)};
    transform: ${p =>
      p.isSideNavOpen
        ? `translateX(${p.theme.blocks.sideNavWidthMd})`
        : 'none'};
  }
`

export const LinkStyled = styled(Link)`
  display: block;
  height: 100%;
  transition-property: color, opacity;
  transition-timing-function: ${p => p.theme.animation.cubicBezier};
  transition-duration: ${p => p.theme.animation.timeBtns};
  user-select: none;
  opacity: ${p => (p.isSideNavOpen ? 0 : 1)};

  ${p => p.theme.mq.upMd} {
    transition-property: opacity;
  }

  @media (min-width: ${threshold}) {
    opacity: 1;
  }
`

const LogoIcon = styled(Icon)`
  display: inline-block;
`

export const LogoRus = styled(LogoIcon)`
  width: ${em(55)};
  padding-bottom: ${em(2)};

  ${p => p.theme.mq.upMd} {
    width: ${em(65)};
    padding-bottom: ${em(8)};
  }
`
