import Head from 'next/head'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { gtmTitleChanged } from 'utils/client/analitics'
import Sentry from 'utils/sentry'

const server = process.env.server

class PageTitle extends Component {
  constructor(props) {
    super(props)

    this.state = {
      prevPathname: null,
      canonicalLink: null,
      description: null,
      title: null,
      ogImage: null,
      ogImageWidth: 1200,
      ogImageHeight: 630,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { dict, currentLocation, language } = nextProps
    const { location, config } = currentLocation
    const { pathname } = location
    const { prevPathname } = prevState

    if (pathname !== prevPathname) {
      if (config) {
        const code = config.dict
        const meta = dict.meta[code] || {}

        return {
          prevPathname: pathname,
          canonicalLink: `${server.WEBSITE_URL}${location.pathname}`,
          description: meta.description,
          title: meta.title,
          noindex: config.noindex,
          ogImage: `${server.CDN_URL}/${language}/meta5/${code}.png`,
        }
      }

      // If there is no such page in router config —
      // reset: canonicalLink, ogImage
      // use title and descriptions of error page
      return {
        prevPathname: pathname,
        description: dict.meta.error.description,
        title: dict.meta.error.title,
        canonicalLink: '',
        ogImage: '',
      }
    }

    return null
  }

  componentDidMount() {
    this.__setPageCodeToSentry()
  }

  componentDidUpdate(prevProps, prevState) {
    const { title, canonicalLink } = this.state

    if (canonicalLink !== prevState.canonicalLink) {
      // Send notification to GTM
      gtmTitleChanged(canonicalLink, title)
      // Set page code for sentry
      this.__setPageCodeToSentry()
    }
  }

  __setPageCodeToSentry() {
    const {
      currentLocation: { config },
    } = this.props

    if (config && config.dict) {
      Sentry.setPage(config.dict)
    } else {
      Sentry.setPage('unknown')
    }
  }

  render() {
    const {
      canonicalLink,
      ogImage,
      ogImageHeight,
      ogImageWidth,
      title,
      description,
      noindex,
    } = this.state

    return (
      <Head>
        <title>{title}</title>

        <meta name="title" content={title} />
        <meta property="og:title" content={title} />

        <meta name="description" content={description} />
        <meta property="og:description" content={description} />

        {noindex && <meta name="robots" content="noindex" />}

        {canonicalLink && (
          <>
            <link rel="canonical" href={canonicalLink} />
            <link property="og:url" content={canonicalLink} />
          </>
        )}

        {ogImage && (
          <>
            <link rel="image_src" href={ogImage} />
            <meta name="twitter:image" content={ogImage} />
            <meta property="og:image" content={ogImage} />
            <meta property="og:image:width" content={ogImageWidth} />
            <meta property="og:image:height" content={ogImageHeight} />
          </>
        )}
      </Head>
    )
  }
}

PageTitle.propTypes = {
  currentLocation: PropTypes.object.isRequired,
  dict: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired,
}

export default PageTitle
