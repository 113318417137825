import PropTypes from 'prop-types'
import React from 'react'

import { FeaturesProvider } from 'contexts/Features'
import { isBrowser, isWebpSupport, restrictUIX } from 'utils/client/clientInfo'

const FeaturesContainer = ({ children }) => {
  const [clientFeatures] = React.useState(() => ({
    isBrowser: isBrowser(),
    restrictUIX: restrictUIX(),
    webp: isWebpSupport(),
  }))

  return <FeaturesProvider value={clientFeatures}>{children}</FeaturesProvider>
}

FeaturesContainer.propTypes = {
  children: PropTypes.node,
}

export default FeaturesContainer
