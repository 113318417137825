import { em } from 'polished'

// Background
// export const bodyBg = $white

// Text
// export const fontFamilyBase = $baseFont
// export const fontWeightBase = normal
// export const dtFontWeight = $boldFont
// export const lineHeightBase = $base-lh
// export const bodyColor = $black
// export const textMuted = $black-op-4
// export const textMutedInverted = $white-op-6

// Headings
export const headingsMarginBottom = 0

// Paragraph
export const paragraphMarginBottom = 0

// Link
// export const linkColor = inherit
// export const linkDecoration = none
// export const linkHoverColor = inherit
// export const linkHoverDecoration = none

// Blocks
// export const borderRadius = $block-border-radius

// Table
export const tableCellPadding = 0
// export const tableCaptionColor = $text-muted

// Forms
export const labelMarginBottom = 0

//Grid
export const gridGutterWidth = 40
// export const gridBreakpoints = (
//   xs: 0,
//   md: 768px,
//   lg: 1200px,
//   xl: 1600px
// )
//
// export const containerMaxWidths = (
//   md: 728px,
//   lg: 1120px,
//   xl: 1520px
// )

//Spacer
export const spacer = em(10)
// export const spacers = () !default
// export const spacers = map-merge((
//   0: 0, // 0
//   '05': ($spacer / 2), // 5
//   1: $spacer, // 10
//   15: ($spacer * 1.5), // 15
//   2: ($spacer * 2), // 20
//   3: ($spacer * 3), // 30
//   4: ($spacer * 4), // 40
//   5: ($spacer * 5), // 50
//   6: ($spacer * 6) // 60
// ), $spacers)

export const spacerMobile = em(10)
// export const spacersMobile = () !default
// export const spacersMobile = map-merge((
//   0: 0, // 0
//   '05': ($spacer-mobile / 2), // 5
//   1: $spacer-mobile, // 10
//   15: ($spacer-mobile * 1.5), // 15
//   2: ($spacer-mobile * 2), // 20
//   3: ($spacer-mobile * 3), // 30
//   4: ($spacer-mobile * 4), // 40
//   5: ($spacer-mobile * 5), // 50
//   6: ($spacer-mobile * 6) // 60
// ), $spacers-mobile)

// This variable affects the `.h-*` and `.w-*` classes.
// export const sizes = () !default
// export const sizes = map-merge((
//   25: 25%,
//   50: 50%,
//   75: 75%,
//   100: 100%,
//   auto: auto
// ), $sizes)
