// Easings
export const cubicBezier = 'cubic-bezier(0.215, 0.610, 0.355, 1.000)'

// Times
export const timeFastest = '.1s'
export const timeFast = '.2s'
export const timeStandard = '.3s'
export const timeBtns = '.4s'
export const timeLong = '.6s'
export const timeLonger = '.9s'

// Links
export const linkTransition = `opacity ${timeBtns} ${cubicBezier}`
export const logoLinkTransition = `filter ${timeBtns} ${cubicBezier}`
export const buttonTransition = `filter ${timeBtns} ${cubicBezier}`
