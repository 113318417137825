import dynamic from 'next/dynamic'
import Router from 'next/router'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ThemeContext } from 'styled-components'

import { addAmount, sendPromo } from 'actions'
import ButtonCart from 'components/_special/ButtonCart'
import ButtonProfile from 'components/_special/ButtonProfile'
import { RU, UK, IE, NL, BE } from 'constants/countries'
import { LocalizationContext } from 'contexts/Localization'
import { ResizeContext } from 'contexts/Resize'
import { home, cart, cartSuccess, cartCheckout, cartPayment } from 'routes'
import { getIsCartInitializingOrUpdating } from 'selectors'
import { filterMainMenu } from 'utils/router'

import ActivateKitButton from './components/ActivateKitButton'
import Logo from './components/Logo'
import Navigation from './components/Navigation'
import SideNav from './components/SideNav'
import useCustomMq from './hooks/useCustomMq'
import { HeaderBox, HeaderInner, RightBox } from './styles'

const CountrySwitcher = dynamic(
  () => import('components/_special/CountrySwitcher'),
  {
    loading: () => (
      <div>
        <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
      </div>
    ),
  },
)

/*
 * We have some special logic in this component:
 * If we got 0 as width of viewport it means its a first render (after SSR)
 * so we render desktop menu but with 0 opacity
 */
const MenuMain = ({
  onToggleSideNav,
  isSideNavOpen,
  switchLocale,
  activeLocale,
  menuMainRef,
  profileButtonTitle,
  user,
  route,
  locales,
  countries,
  mainMenu,
  sections,
}) => {
  const viewport = React.useContext(ResizeContext)
  const { colors } = React.useContext(ThemeContext)
  const { paths, dict } = React.useContext(LocalizationContext)
  const cartState = useSelector(state => state.cart)
  const isCartInProgress = useSelector(getIsCartInitializingOrUpdating)
  const {
    locale,
    pages: {
      common: { buttons },
    },
    sales: { specialDistribution, withoutSales, isGroupon },
  } = useSelector(state => state.localization)
  const dispatch = useDispatch()
  const isTabletMenu = useCustomMq({ onlyForLocale: RU })
  const isMobOrTab = viewport.mobile || viewport.tablet || isTabletMenu || null
  const switcherVisible =
    viewport.tablet || viewport.desktop || viewport.scaling

  const [isSwitcherLoaded, handleSwitcherLoad] = React.useState(false)

  const isCartPage =
    route.config?.basePath === cart.basePath ||
    route.config?.basePath === cartSuccess.basePath ||
    route.config?.basePath === cartCheckout.basePath ||
    route.config?.basePath === cartPayment.basePath

  const productType = route.config?.isProductPage && route.config?.productType

  const menuMainList = React.useMemo(
    () => filterMainMenu(mainMenu, sections, activeLocale),
    [mainMenu, sections, activeLocale],
  )

  const preparedCountries = React.useMemo(() => {
    let activeCountry = {}
    const countriesList = countries
      .filter(country => ['UK', 'IT', 'DK', 'IE', 'BE', 'NL', 'TR'].includes(country.name))
      .map(country => {
        const countrySelectItem = {
          value: country.code,
          label: locales[country.code],
          label_short: country.name,
          flag: `${paths.shared}/flags/${country.code}.svg`,
        }

        if (activeLocale === country.code) {
          activeCountry = countrySelectItem
        }

        return countrySelectItem
      })
      .sort((x, y) => (x.value > y.value ? 1 : x.value < y.value ? -1 : 0))

    return {
      activeCountry,
      countries: countriesList,
    }
  }, [countries, locales, paths.shared, activeLocale])

  const theme = route.config?.background === colors.black ? 'black' : 'white'

  // reset Groupon promo if more then one test were added to cart
  const goToCartCb = React.useCallback(() => {
    if (isGroupon && cartState.sum.amount > 1) {
      dispatch(
        sendPromo({
          promocode: undefined,
          locale,
        }),
      )
    }

    if (!isGroupon && productType && !cartState.sum.amount) {
      dispatch(
        addAmount({
          productType: productType,
          amount: 1,
          isSubscription: false,
        }),
      )
      return setTimeout(
        () => {
          Router.push(cart.link(locale), cart.as(locale))
        },
        viewport.mobile ? 0 : 1000,
      )
    }

    return Router.push(cart.link(locale), cart.as(locale))
  }, [
    isGroupon,
    cartState.sum.amount,
    locale,
    dispatch,
    productType,
    viewport.mobile,
  ])

  const isCartButtonHidden =
    !cartState.isInitialized ||
    isCartInProgress ||
    specialDistribution ||
    withoutSales

  const hideNavigationInCart = [UK, IE, NL, BE].includes(locale) && isCartPage

  return (
    <HeaderBox
      as="header"
      colorTheme={theme}
      withAlternateViewport={isTabletMenu}
      ref={menuMainRef}
      isHidden={viewport.breakpoint === ''}
      isTransparent={isSideNavOpen && !hideNavigationInCart}
      isAlwaysColored={hideNavigationInCart}
    >
      <HeaderInner className="row no-gutters align-items-center justify-content-between">
        <div className="d-flex justify-content-start align-items-center h-100">
          <Logo
            {...{
              theme,
              isSideNavOpen,
              onToggleSideNav,
              hideSideNav: hideNavigationInCart,
              dict,
            }}
            to={home.link(activeLocale)}
            as={home.as(activeLocale)}
            withAlternateViewport={isTabletMenu}
          />
          {preparedCountries.countries &&
            preparedCountries.countries.length > 1 &&
            switcherVisible &&
            !hideNavigationInCart && (
              <CountrySwitcher
                key={preparedCountries.activeCountry.value}
                isHidden={isSideNavOpen}
                handleLoad={handleSwitcherLoad}
                handleSwitch={switchLocale}
                countries={preparedCountries.countries}
                activeCountry={preparedCountries.activeCountry}
              />
            )}
          {(viewport.tablet || isTabletMenu) && !hideNavigationInCart && (
            <ActivateKitButton {...{ isSideNavOpen, theme }} />
          )}
        </div>
        {!hideNavigationInCart &&
          (isMobOrTab ? (
            <SideNav
              {...{
                isSideNavOpen,
                switchLocale,
                countries: preparedCountries.countries,
                theme,
              }}
              mainMenu={menuMainList}
              isMobile={viewport.mobile}
            />
          ) : (
            <Navigation
              {...{ mainMenu: menuMainList, route, isSwitcherLoaded, theme }}
            />
          ))}

        <RightBox>
          {!isCartButtonHidden && (
            <ButtonCart
              {...{
                isMobile: viewport.mobile,
                isVisible: !isSideNavOpen && !isCartPage,
                breakpoint: viewport.breakpoint,
                as: 'button',
                amount: cartState.sum.amount,
                wrapperProps: {
                  onClick: goToCartCb,
                  'aria-label': 'go to cart',
                },
                theme,
              }}
            >
              {/*{buttons['buy'] || null}*/}
            </ButtonCart>
          )}

          <div className="mr-1 mr-md-15" />
          <ButtonProfile
            {...{ isMobOrTab, theme }}
            user={user}
            isSideNavOpen={isSideNavOpen}
            profileButtonTitle={profileButtonTitle}
            label={dict.buttons.profile}
          />
        </RightBox>
      </HeaderInner>
    </HeaderBox>
  )
}

MenuMain.propTypes = {
  activeLocale: PropTypes.string.isRequired,
  countries: PropTypes.array.isRequired,
  mainMenu: PropTypes.array.isRequired,
  menuMainRef: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  sections: PropTypes.object.isRequired,
  switchLocale: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  onToggleSideNav: PropTypes.func.isRequired,
  isSideNavOpen: PropTypes.bool,
  locales: PropTypes.object,
  profileButtonTitle: PropTypes.string,
}

MenuMain.defaultProps = {
  isSideNavOpen: false,
  profileButtonTitle: '',
}

export default MenuMain
