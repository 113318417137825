import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Transition, TransitionGroup } from 'react-transition-group'

import { growingCounter, hideCounter } from './animations'

import './BtnGoodsCounter.scss'

export default class BtnGoodsCounter extends Component {
  static propTypes = {
    isShow: PropTypes.bool.isRequired,
    amount: PropTypes.number,
    inCb: PropTypes.func,
    outCb: PropTypes.func,
  }

  static defaultProps = {
    amount: 0,
  }

  render() {
    const { amount, isShow, outCb, inCb } = this.props

    return (
      <Transition
        in={isShow}
        mountOnEnter={true}
        unmountOnExit={true}
        onExited={outCb}
        onEntered={inCb}
        addEndListener={(node, done) => {
          if (isShow) {
            growingCounter({ counterNode: node }, done)
          } else {
            hideCounter({ counterNode: node }, done)
          }
        }}
      >
        {() => (
          <div className="btn-goods-counter" ref={this.counterRef}>
            <div className="btn-goods-counter__inner">
              <div className="btn-goods-counter__bg" />
              <TransitionGroup component={null}>
                <Transition timeout={300} key={amount}>
                  {state => (
                    <span
                      className={`btn-goods-counter__body btn-goods-counter__body--${state}`}
                    >
                      {amount}
                    </span>
                  )}
                </Transition>
              </TransitionGroup>
            </div>
          </div>
        )}
      </Transition>
    )
  }
}
