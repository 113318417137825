import styled from 'styled-components'
import { em } from 'polished'

export const Social = styled.div`
  display: inline-grid;
  gap: ${em(13)};

  ${p => p.theme.mq.downLg} {
    display: flex;
    margin-top: 40px;
    gap: 10px;
  }

  ${p => p.theme.mq.upThreshold(1024)} {
    grid-template-columns: auto auto;
  }
`
