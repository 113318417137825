import { em } from 'polished'
import styled, { css } from 'styled-components'

import LinkNav from 'components/_special/LinkNav'
import Link from 'components/Link'

export const NavItem = styled.li`
  display: inline-block;
  height: 100%;
`

const linkStyle = css`
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 ${em(10)};

  ${p => p.theme.mq.upMd} {
    padding-bottom: ${p => p.theme.indents.menuMainPaddingBottomMd};
  }
`

export const LinkStyled = styled(Link)`
  ${linkStyle};
`

export const LinkNavStyled = styled(LinkNav)`
  ${linkStyle};
`
