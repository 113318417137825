import PropTypes from 'prop-types'
import React, { useMemo, useContext } from 'react'

import LinkNav from 'components/_special/LinkNav'
import Link from 'components/Link'
import { LayoutContext } from 'contexts/Layout'
import { LocalizationContext } from 'contexts/Localization'
import { TextLH } from 'styles/components/typography'

import { Inner, ItemBox, ItemUnderline } from './styles'

/**
 * SideNavCollapserHeading
 *
 * @param {object} props - properties of component
 * @returns {node} SideNavCollapserHeading
 */
const SideNavItem = props => {
  const {
    route: { title, link, as },
    items,
    isLast,
    beforeTitle,
    afterTitle,
    className,
    onClick,
    Component,
    isActive,
    theme,
  } = props
  const { closeSideNav } = useContext(LayoutContext)
  const { locale } = useContext(LocalizationContext)
  const capitalizedTitle = useMemo(
    () => title.charAt(0).toUpperCase() + title.slice(1),
    [title],
  )
  const to = useMemo(() => (link ? link(locale) : null), [locale, link])
  const asPath = useMemo(() => (as ? as(locale) : null), [locale, as])

  const contentInner = (
    <Inner>
      {beforeTitle}
      <TextLH dangerouslySetInnerHTML={{ __html: capitalizedTitle }} />
      {afterTitle}
    </Inner>
  )
  let contentWrapped = contentInner

  if (to && asPath) {
    contentWrapped = (
      <LinkNav
        {...{
          component: 'a',
          to,
          asPath,
          onClick: onClick || closeSideNav,
          exact: !items,
          className: `link d-block ${className}`,
        }}
      >
        {contentInner}
      </LinkNav>
    )
  } else if (to) {
    contentWrapped = (
      <Link
        {...{
          to,
          onClick: onClick || closeSideNav,
          isTargetBlank: true,
          className: `link d-block ${className}`,
        }}
      >
        {contentInner}
      </Link>
    )
  } else if (Component && onClick) {
    contentWrapped = (
      <Component onClick={onClick} className={`link ${className}`}>
        {contentInner}
      </Component>
    )
  }

  return (
    <ItemBox>
      {contentWrapped}
      {!isLast && !isActive && <ItemUnderline colorTheme={theme} />}
    </ItemBox>
  )
}

SideNavItem.propTypes = {
  route: PropTypes.object,
  onClick: PropTypes.func,
  afterTitle: PropTypes.node,
  beforeTitle: PropTypes.node,
  items: PropTypes.array,
  className: PropTypes.string,
  isLast: PropTypes.bool,
  isBold: PropTypes.bool,
  isActive: PropTypes.bool,
  Component: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  theme: PropTypes.string,
}

SideNavItem.defaultProps = {
  isLast: false,
  isBold: false,
  beforeTitle: null,
  afterTitle: null,
  className: '',
  Component: 'div',
  isActive: false,
  theme: '',
}

export default SideNavItem
