import { cloneDeep, forEach, isEqual } from 'lodash'
import {
  takeEvery,
  takeLatest,
  throttle,
  put,
  call,
  all,
  fork,
  select,
} from 'redux-saga/effects'

import {
  CHANGE_LOCALE,
  CART_INIT,
  CART_INITED,
  CART_ADD_AMOUNT,
  CART_UPDATE,
  CART_CALCULATE,
  CART_PROMO_SEND,
  CART_RUSSIA_PAYMENT_REQUEST,
  CART_EUROPE_COLLECT_REQUEST,
  CART_COLLECT_RESET,
  CART_PAGE_LEAVING,
  CART_EUROPE_PAYMENT_REQUEST,
  CART_COMMON_UPDATE_REQUEST,
  CART_COMMON_UPDATE_SUCCESS,
  CART_COMMON_UPDATE_FAILURE,
  CART_PERSONAL_INFO_CHANGE,
  CART_PERSONAL_INFO_SAVE,
  CART_UNFINISHED_ID_SAVE,
  CART_CLEAR_GOODS,
  CART_DELIVERY_TYPE_CHANGE,
  CART_DELIVERY_TYPE_SAVE,
  DELIVERY_ZONE_REQUEST,
  DELIVERY_ZONE_SUCCESS,
  DELIVERY_ZONE_FAILURE,
  CART_PROMO_RESET,
  CART_RESET,
} from 'constants/actionTypes'
import { GOODS_TYPES, COUNTRIES_WITH_DYNAMIC_DISCOUNT } from 'constants/sales'
import { UK } from 'constants/countries'
import {
  getSales,
  getCartState,
  getCurrentLocale,
  getCartStateWithFilteredGoods,
  getIsCartInitialized,
} from 'selectors'
import {
  order,
  collectEuropeData,
  saveUnfinishedOrder,
  getDeliveryPrice,
} from 'utils/api'
import {
  gtmCheckoutUserInfo,
  gtmCheckoutCardInfo,
  gtmAddToCart,
  gtmRemoveFromCart,
  gtmEvent,
} from 'utils/client/analitics'
import { getCookie } from 'utils/client/cookie'
import {
  productCost,
  getSummary,
  filterDeliveryTypes,
  saveCartToLS,
  getCartFromLS,
  getCartFromURL,
  clearCartInLS,
  saveOrderToLS,
} from 'utils/client/sales'
import { getActionHandlerFromURL } from 'utils/client/actionsFromURL'
import getOrderPayloadForRussia from 'utils/sales/getOrderPayloadForRussia'
import sentry from 'utils/sentry'

import { getSalesInfoForLocale } from './salesInfoSaga'
import { provideCartToCityAdsVariable } from '../utils/city-ads'

const { DYNAMIC_DISCOUNT_PROMO } = process.env.localization
const COUNTRIES_WITH_BUNDLE = [UK]
const BUNDLE_PROMOCODE = 'sixty'
/*
 * Worker sagas
 */

//
// Save cart to Local Storage
function* cartSaveToStorage() {
  const cart = yield select(getCartState)

  saveCartToLS(cart)
}

//
// Initialize cart based on URL and Local Storage
function* cartInitializationWorker({ payload }) {
  const { locale } = payload
  const isCartInitialized = yield select(getIsCartInitialized)

  if (isCartInitialized) {
    return false
  }
  const urlHandler = getActionHandlerFromURL()

  const isCartPreset = urlHandler?.type === 'fill-cart'

  const cartFromUrl = isCartPreset ? urlHandler.func() : getCartFromURL()
  const cartFromStorage = getCartFromLS()
  const promocode =
    cartFromUrl.promo.name || cartFromStorage.promo.name || undefined

  const isFailed = yield call(getSalesInfoForLocale, {
    payload: { locale, promocode },
  })

  if (isFailed) {
    return true
  }

  const salesConfig = yield select(getSales)

  cartFromUrl.goods = cartFromUrl.goods.filter(
    ({ productType }) => salesConfig.goods[productType].isActive,
  )
  cartFromStorage.goods = cartFromStorage.goods.filter(
    ({ productType }) =>
      salesConfig.goods[productType].isActive &&
      salesConfig.goods[productType].price !== 0 &&
      salesConfig.goods[productType].currentPrice !== 0 &&
      salesConfig.goods[productType].priceWebsite !== 0
  )

  let mergedGoods = cartFromUrl.goods
  let changes = 0

  if (!isCartPreset && cartFromStorage.goods.length > 0) {
    mergedGoods = cartFromStorage.goods
    if (cartFromUrl.goods.length > 0) {
      cartFromUrl.goods.forEach(item => {
        const isInStorage = cartFromStorage.goods.find(
          product => product.productType === item.productType,
        )
        if (!isInStorage) {
          mergedGoods.push(item)
        }
      })
    }
  }

  if (mergedGoods.length > 0) {
    // Set up flags for GTM logic
    // we send events to datalayer when it was initialized by user
    // so we have to block sending for our inner logic
    mergedGoods.forEach((product, index) => {
      mergedGoods[index].actionByUser = false
      // remove excess biome tests for Groupon promo in Germany
      if (salesConfig.isGroupon && product.productType === GOODS_TYPES.biome) {
        product.amount = 1
      }
    })

    yield call(addAmountOfDifferentGoods, {
      payload: mergedGoods,
    })
    ++changes
  } else if (salesConfig.isGroupon) {
    // remove excess biome tests for Groupon promo in Germany
    yield call(addAmountAndCalculateCartWorker, {
      payload: {
        productType: GOODS_TYPES.biome,
        amount: 1,
        isSubscription: false,
        actionByUser: false,
      },
    })
  }
  if (changes > 0) {
    yield put({ type: CART_CALCULATE, payload: { initializing: true } })
  }

  const userGA = getCookie('_ga')
  sentry.setGA(userGA)
  if (window.smartlook) {
    window.smartlook('identify', userGA)
    window.smartlook(() => {
      const smartlook_url = window.smartlook.playUrl
      sentry.setSmartlook(smartlook_url)
      window.amplitude?.logEvent('Smartlook Start Session', {
        session_url: smartlook_url,
      })
    })
  }
  yield put({ type: CART_INITED, payload: { userGA } })
}

//
// Update cart based on new Locale
function* cartUpdateWorker({ payload }) {
  const { locale } = payload
  const isCartInitialized = yield select(getIsCartInitialized)

  if (isCartInitialized) {
    yield put({ type: CART_PROMO_RESET })
    yield call(getSalesInfoForLocale, {
      payload: {
        locale,
      },
    })
    yield call(cartCalculateWorker, { payload })
    yield put({ type: CART_COLLECT_RESET })
  }
}

// Request promocode by its field
function* promocodeRequestWorker({ payload }) {
  const isFailed = yield call(getSalesInfoForLocale, {
    payload: { ...payload, isPromoRequest: true },
  })
  if (isFailed) return

  const salesConfig = yield select(getSales)
  // remove excess biome tests for Groupon promo in Germany
  if (salesConfig.isGroupon) {
    const cartState = yield select(getCartStateWithFilteredGoods)
    const diff = 1 - cartState.goods[GOODS_TYPES.biome].amount
    if (diff) {
      yield call(addAmountAndCalculateCartWorker, {
        payload: {
          productType: GOODS_TYPES.biome,
          amount: diff,
          isSubscription: false,
          actionByUser: false,
        },
      })
    }
  }
}

//
// Update costs when something changed
function* cartCalculateWorker({ payload }) {
  const { initializing = false } = payload || {}
  const salesConfig = yield select(getSales)
  const cart = yield select(getCartState)
  const newGoods = cloneDeep(cart.goods)
  let newSumAmount = 0
  let newSumCost = 0
  let newSumRealCost = 0

  /*
   * Sum logic
   */
  forEach(newGoods, (product, productType, goods) => {
    if (salesConfig.goods && salesConfig.goods[productType].isActive) {
      // check if subscription is still available after locale changed
      const isSubscription =
        product.isSubscription &&
        !!salesConfig.goods[productType].subscription.price
      const { cost, realCost } = productCost({
        amount: product.amount,
        isSubscription: isSubscription,
        productConfig: salesConfig.goods[productType],
        promo: cart.promo,
      })

      goods[productType].isSubscription = isSubscription
      goods[productType].cost = cost
      goods[productType].realCost = realCost

      newSumAmount += product.amount
      newSumCost += cost
      newSumRealCost += realCost
    }
  })
  const newSum = getSummary({
    amount: newSumAmount,
    cost: newSumCost,
    realCost: newSumRealCost,
    deliveryConfig: salesConfig.delivery,
    deliveryType: cart.deliveryType,
  })

  /*
   * Delivery logic
   */
  const availableTypes = filterDeliveryTypes(
    salesConfig.delivery.baseTypes,
    newGoods,
  )
  const newDelivery =
    cart.deliveryAvailableTypes === availableTypes
      ? {}
      : { deliveryAvailableTypes: availableTypes }

  if (!cart.deliveryType) {
    newDelivery.deliveryType = salesConfig.delivery.default
  }
  provideCartToCityAdsVariable(newGoods)
  yield put({
    type: CART_UPDATE,
    payload: {
      ...newDelivery,
      goods: newGoods,
      sum: newSum,
    },
  })

  if (cart.isInitialized || initializing) {
    yield call(cartSaveToStorage)
  }
}

//
// Work with amount of goods
function* addAmount({ payload }) {
  const { productType, amount, isSubscription, actionByUser = true } = payload
  const cart = yield select(getCartState)
  const salesConfig = yield select(getSales)
  const prevProduct = cart.goods[productType]

  if (
    !salesConfig.goods ||
    !salesConfig.goods[productType].isActive ||
    salesConfig.goods[productType].price === 0 ||
    salesConfig.goods[productType].priceWebsite === 0 ||
    salesConfig.goods[productType].currentPrice === 0
  ) {
    return null
  }

  // Update date for current goods
  const nextAmount =
    isSubscription === prevProduct.isSubscription
      ? cart.goods[productType].amount + amount
      : amount

  // Implementation of dynamic discount
  const locale = yield select(getCurrentLocale)
  if (COUNTRIES_WITH_DYNAMIC_DISCOUNT.includes(locale)) {
    const isDynamicPromoDiscount =
      cart.promo?.name?.toLowerCase() === DYNAMIC_DISCOUNT_PROMO.toLowerCase()

    if (cart.sum.amount + amount > 1) {
      // use promo
      const isWithoutPromo = !cart.promo?.name && !cart.promo?.id
      if (isWithoutPromo || (!salesConfig.isGroupon && !isDynamicPromoDiscount))
        yield call(getSalesInfoForLocale, {
          payload: {
            promocode: DYNAMIC_DISCOUNT_PROMO,
            locale,
            isPromoRequest: true,
          },
        })
    } else if (isDynamicPromoDiscount && actionByUser) {
      // reset promo
      yield call(getSalesInfoForLocale, {
        payload: {
          promocode: undefined,
          locale,
          isPromoRequest: true,
        },
      })
    }
  }

  // Implementation of biome bundle discount
  if (
    productType === GOODS_TYPES.biome &&
    COUNTRIES_WITH_BUNDLE.includes(locale)
  ) {
    if (nextAmount >= 2) {
      // use promo
      if (!cart.promo?.name && !cart.promo?.id)
        yield call(getSalesInfoForLocale, {
          payload: {
            promocode: BUNDLE_PROMOCODE,
            locale,
            isPromoRequest: true,
          },
        })
    } else if (cart.promo?.name === BUNDLE_PROMOCODE) {
      // reset promo
      yield call(getSalesInfoForLocale, {
        payload: {
          promocode: undefined,
          locale,
          isPromoRequest: true,
        },
      })
    }
  }

  yield put({
    type: CART_UPDATE,
    payload: {
      goods: {
        [productType]: {
          amount: nextAmount,
          isSubscription,
        },
      },
    },
  })

  // If action initialized by not a user — we do not send it to analytics
  if (actionByUser === false) {
    return null
  }

  // Some crutch - logic for GTM
  // need to refactor it
  // Idea for refactor:
  // - realize logic of toggle subscription as remove previous product and add new
  // than we can remove code from 'else'
  const newCart = yield select(getCartState)

  if (isSubscription === prevProduct.isSubscription) {
    if (amount > 0) {
      // Send new info to GTM
      gtmAddToCart({
        amount: amount,
        cart: newCart,
        salesConfig,
        productType,
        isSubscription: isSubscription,
      })
    } else if (amount < 0) {
      // Send new info to GTM
      gtmRemoveFromCart({
        amount: amount * -1,
        cart: newCart,
        salesConfig,
        productType,
        isSubscription: isSubscription,
      })
    }
  } else {
    // Send info to GTM
    gtmRemoveFromCart({
      cart,
      salesConfig,
      productType,
      isSubscription: prevProduct.isSubscription,
    })
    // Send new info to GTM
    gtmAddToCart({
      amount: amount,
      cart: newCart,
      salesConfig,
      productType,
      isSubscription: isSubscription,
    })
  }

  return null
}

function* addAmountOfDifferentGoods({ payload }) {
  let i = 0

  while (i < payload.length) {
    yield call(addAmount, { payload: payload[i] })
    i++
  }
}

function* addAmountAndCalculateCartWorker({ payload }) {
  yield call(addAmount, { payload })
  yield put({ type: CART_CALCULATE })
}

//
// Save personal info to store
function* personalInfoSaveWorker({ payload }) {
  const salesConfig = yield select(getSales)
  const prevCart = yield select(getCartState)

  yield put({ type: CART_PERSONAL_INFO_SAVE, payload })

  const newCart = yield select(getCartState)
  const { email, phone, city, country, is_offer_accepted } = newCart.personal

  // User add some personal info except default 2 fields (country and phoneCode)
  if (Object.keys(newCart.personal).length > 2) {
    // Send event to GTM
    gtmCheckoutUserInfo({
      cart: newCart,
      salesConfig,
    })
  }

  if (is_offer_accepted !== prevCart.personal.is_offer_accepted) {
    gtmEvent({ event: `AgreeRules - ${is_offer_accepted ? 'Yes' : 'No'}` })
  }

  if (email || phone) {
    const personalInfoIsChanged = !isEqual(prevCart.personal, newCart.personal)

    if (personalInfoIsChanged) {
      sentry.setEmailOrder(email)

      const { saveResponse, saveError } = yield call(saveUnfinishedOrder, {
        cart: newCart,
        sales: salesConfig,
      })

      if (
        !saveError &&
        saveResponse &&
        typeof saveResponse === 'object' &&
        'id' in saveResponse &&
        saveResponse.id !== newCart.orderIdUnfinished
      ) {
        yield put({
          type: CART_UNFINISHED_ID_SAVE,
          payload: { id: saveResponse.id },
        })
      }
    }
  }

  if (country && city) {
    const {
      personal: { country: prevCountry, city: prevCity },
    } = prevCart
    const {
      personal: { country, city },
    } = newCart
    const personalInfoIsChanged = country !== prevCountry || city !== prevCity

    if (personalInfoIsChanged) {
      yield put({
        type: DELIVERY_ZONE_REQUEST,
      })

      const data = yield call(getDeliveryPrice, {
        country,
        city,
      })

      if (data) {
        yield put({
          type: DELIVERY_ZONE_SUCCESS,
          payload: { deliveryZoneId: data },
        })
        yield put({ type: CART_CALCULATE })
      } else {
        yield put({
          type: DELIVERY_ZONE_FAILURE,
          payload: { error: data },
        })
      }
    }
  }
}

//
// Change delivery type and recalc params
function* deliveryTypeChangeWorker({ payload }) {
  yield put({ type: CART_DELIVERY_TYPE_SAVE, payload })
  yield put({ type: CART_CALCULATE })
}

//
// Collect data logic for Europe - 1st step of order
function* europeCollectDataWorker() {
  yield put({ type: CART_COMMON_UPDATE_REQUEST })

  const cart = yield select(getCartStateWithFilteredGoods)
  const sales = yield select(getSales)
  const { orderResponse, orderError } = yield call(collectEuropeData, {
    cart,
    sales,
  })

  if (orderResponse && orderResponse.success === true && !orderError) {
    const { order_id, client_secret } = orderResponse

    yield put({
      type: CART_COMMON_UPDATE_SUCCESS,
      payload: {
        orderId: order_id,
        clientSecret: client_secret,
        isOrderCollected: true,
        isOrderSucceed: sales.isGroupon,
      },
    })

    gtmCheckoutCardInfo({
      cart,
      salesConfig: sales,
    })

    return
  }

  sentry.captureMessageWithScope('ORDER COLLECT FAILURE', {
    error: orderError,
    response: orderResponse,
  })

  yield put({
    type: CART_COMMON_UPDATE_FAILURE,
    payload: { error: 'Something went wrong: Collect Data' },
  })
}

//
// Payment logic for Europe - 2nd step of order
function* europePaymentWorker({ payload }) {
  yield put({ type: CART_COMMON_UPDATE_REQUEST })

  const { stripe } = payload
  const cart = yield select(getCartState)

  const result = yield call(stripe.handleCardPayment, cart.clientSecret)
  const { error, paymentIntent } = result

  if (error) {
    let errorIsUserFriendly = false

    if ('message' in error && 'type' in error && 'code' in error) {
      // Condition of general error: error details sent from payment system (stripe)
      // we can show it to user
      errorIsUserFriendly = true
    } else {
      sentry.captureMessageWithScope('ORDER PAYMENT FAILURE', {
        axiosError: error,
        response: result,
      })
    }

    yield put({
      type: CART_COMMON_UPDATE_FAILURE,
      payload: {
        error: error.message,
        errorIsUserFriendly,
      },
    })

    return
  }

  if (paymentIntent.status === 'succeeded') {
    yield put({
      type: CART_COMMON_UPDATE_SUCCESS,
      payload: { isOrderSucceed: true },
    })
  }
}

//
// Order logic for Russia
function* orderRussiaWorker() {

  yield put({ type: CART_COMMON_UPDATE_REQUEST })
  const cart = yield select(getCartStateWithFilteredGoods)
  const sales = yield select(getSales)

  const orderPayload = yield getOrderPayloadForRussia({
    cart,
    sales,
  })

  const { orderResponse, orderError } = yield call(order, orderPayload)

  if (orderResponse?.success === true && !orderError) {
    gtmEvent({ event: 'Button Buy - Success' })
    yield put({
      type: CART_COMMON_UPDATE_SUCCESS,
      payload: {
        orderId: orderResponse.orderId,
        redirectUrl: orderResponse.redirectUrl,
        isOrderSucceed: true,
      },
    })

    return
  }

  let errorIsUserFriendly = false
  let errorCode
  if (
    orderResponse?.success === false &&
    !!orderResponse?.code &&
    orderResponse?.type === 'yandex'
  ) {
    errorIsUserFriendly = true
    errorCode = orderResponse.code || 'buy_error'
  }

  sentry.captureMessageWithScope('ORDER PAYMENT FAILURE (YANDEX)', {
    axiosError: orderError,
    response: orderResponse,
    order_payload: JSON.stringify(
      Object.assign({}, orderPayload, {
        charge_info: undefined,
        delivery_info: undefined,
        contact_info: undefined,
        email: orderPayload.contact_info.email,
        token: orderPayload.charge_info.token,
      }),
    ),
  })

  gtmEvent({ event: 'Button Buy - Error', description: errorCode })
  yield put({
    type: CART_COMMON_UPDATE_FAILURE,
    payload: {
      error: 'Something went wrong',
      errorCode,
      errorIsUserFriendly,
    },
  })
}

//
// Save cart info to LS for google purchase event
function* orderSaveToLSWorker() {
  // omit personal info from save to LS
  // eslint-disable-next-line no-unused-vars
  const { personal, ...cart } = yield select(getCartStateWithFilteredGoods)
  cart.personal = personal
  const salesConfig = yield select(getSales)

  saveOrderToLS({ cart, salesConfig })
}

//
// When user leaves cart page
function* cartPageLeavingWorker() {
  const { isOrderSucceed, isOrderCollected } = yield select(getCartState)

  // Do nothing if user leaves cart page after succeed order
  if (isOrderSucceed) {
    return
  }

  // If order collected than reset cart collection status
  if (isOrderCollected) {
    yield put({ type: CART_COLLECT_RESET })
  }
}

//
// When clear goods in cart we also clear LS
function cartClearWorker() {
  clearCartInLS()
}

//
// Request new sales data after CART_RESET
// In this case no need to do cart recalculation, because it's empty
function* cartResetWorker() {
  const locale = yield select(getCurrentLocale)
  const isCartInitialized = yield select(getIsCartInitialized)

  if (isCartInitialized) {
    yield call(getSalesInfoForLocale, {
      payload: {
        locale,
      },
    })
  }
}

/*
 * Watcher saga
 */
function* cartInitializationWatcher() {
  yield takeEvery(CART_INIT, cartInitializationWorker)
}

function* addAmountAndCalcWatcher() {
  yield takeEvery(CART_ADD_AMOUNT, addAmountAndCalculateCartWorker)
}

function* promocodeAndCalcWatcher() {
  yield takeEvery(CART_PROMO_SEND, promocodeRequestWorker)
}

function* changeLocaleWatcher() {
  yield takeLatest(CHANGE_LOCALE, cartUpdateWorker)
}

function* cartCalculateWatcher() {
  yield takeLatest(CART_CALCULATE, cartCalculateWorker)
}

function* personalInfoChangeWatcher() {
  yield throttle(200, CART_PERSONAL_INFO_CHANGE, personalInfoSaveWorker)
}

function* europeCollectDataWatcher() {
  yield takeEvery(CART_EUROPE_COLLECT_REQUEST, europeCollectDataWorker)
}

function* europePaymentWatcher() {
  yield takeEvery(CART_EUROPE_PAYMENT_REQUEST, europePaymentWorker)
}

function* orderRussiaWatcher() {
  yield takeEvery(CART_RUSSIA_PAYMENT_REQUEST, orderRussiaWorker)
}

function* orderSaveToLSWatcher() {
  yield takeEvery(CART_COMMON_UPDATE_SUCCESS, orderSaveToLSWorker)
}

function* cartPageLeavingWatcher() {
  yield takeLatest(CART_PAGE_LEAVING, cartPageLeavingWorker)
}

function* cartResetWatcher() {
  yield takeEvery(CART_RESET, cartResetWorker)
}

function* cartClearWatcher() {
  yield takeEvery(CART_CLEAR_GOODS, cartClearWorker)
}

function* deliveryTypeChangeWatcher() {
  yield takeEvery(CART_DELIVERY_TYPE_CHANGE, deliveryTypeChangeWorker)
}

/*
 * Root saga
 */
export default function* rootSaga() {
  yield all([
    // Init cart
    fork(cartInitializationWatcher),
    // Amount added than cart updated
    fork(addAmountAndCalcWatcher),
    // If promo code entered than check it
    fork(promocodeAndCalcWatcher),
    // If we change locale of website - we need to recalculate cart
    fork(changeLocaleWatcher),
    // Listen all requests to recalculate cart
    fork(cartCalculateWatcher),
    // Special saga for GTM to save info about unfinished order
    fork(personalInfoChangeWatcher),
    // Recalculate delivery cost when delivery type changed
    fork(deliveryTypeChangeWatcher),
    // Collect personal info step saga
    fork(europeCollectDataWatcher),
    // Order saga
    fork(europePaymentWatcher),
    // Order russia saga
    fork(orderRussiaWatcher),
    // Save cart and sales to LS before leave cart page
    fork(orderSaveToLSWatcher),
    // Process action when user leave cart page
    fork(cartPageLeavingWatcher),
    // Reset sales after cart reset
    fork(cartResetWatcher),
    // Clean cart saga
    fork(cartClearWatcher),
  ])
}
