import { em } from 'polished'
import styled from 'styled-components'

import { BoxBase } from 'styles/components/layout'
import { TextLH } from 'styles/components/typography'

export const TextLHStyled = styled(TextLH)`
  transition: color ${p => p.theme.animation.timeStandard};
  white-space: nowrap;
  font-size: ${p => p.theme.typography.fontSizeXs};
`

export const BoxBaseStyled = styled(BoxBase)`
  position: fixed;
  z-index: 2147483100;
  bottom: 0;
  transition: opacity ${p => p.theme.animation.timeBtns} ease-in;
  opacity: ${p => (p.isVisible ? 1 : 0)};
  flex-wrap: nowrap;
  padding-top: ${em(20)};
  padding-bottom: ${em(20)};
`

export const Wrapper = styled.div`
  background-color: ${p => p.theme.colors.blackOp75};
  color: ${p => p.theme.colors.white};
  border-radius: ${p => p.theme.blocks.blockBorderRadiusMedium};
  padding: ${em(14)} ${em(20)} ${em(20)};
  max-width: 100%;

  ${p => p.theme.mq.upMd} {
    max-width: ${em(324)};
    padding: ${em(20)} ${em(25)};
  }

  ${p => p.theme.mq.upLg} {
    max-width: ${em(480)};
  }
`

export const TextWrapper = styled.div`
  padding-bottom: ${em(14)};

  ${p => p.theme.mq.upMd} {
    padding-bottom: ${em(20)};
  }
`

export const ButtonTextWrapper = styled.div`
  display: inline-flex;
  padding: ${em(6)} ${em(25)};
  align-items: center;

  &:hover {
    ${TextLHStyled} {
      color: ${p => p.theme.colors.white};
    }
  }

  ${p => p.theme.mq.upMd} {
    padding-top: ${em(2)};
  }
`

export const IconBox = styled.div`
  width: ${em(20)};
  height: ${em(20)};
  margin-right: ${em(5)};
`
