import React from 'react'

import Icon from 'components/Icon'
import { accountSources } from 'constants/externalLinks'
import { LocalizationConsumer } from 'contexts/Localization'

import { A, IconBox } from './styles'

/**
 * ActivateKitBtnMobile
 *
 * @returns {node} ActivateKitBtnMobile
 */
const ActivateKitBtnMobile = () => {
  return (
    <LocalizationConsumer>
      {({ dict }) => (
        <A
          target="_blank"
          rel="noopener noreferrer"
          className="py-1 pl-2"
          href={accountSources}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: dict.buttons.activate_kit,
            }}
          />
          <IconBox>
            <Icon id="color_activate_kit" />
          </IconBox>
        </A>
      )}
    </LocalizationConsumer>
  )
}

export default ActivateKitBtnMobile
