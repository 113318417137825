import { em } from 'polished'
import PropTypes from 'prop-types'
import React from 'react'
import { ThemeProvider } from 'styled-components'

import * as animation from 'styles/theme/animation'
import * as arrows from 'styles/theme/arrows'
import * as blocks from 'styles/theme/blocks'
import { md, lg, xl } from 'styles/theme/breakpoints'
import * as buttons from 'styles/theme/buttons'
import * as colors from 'styles/theme/colors'
import * as indents from 'styles/theme/indents'
import mq from 'styles/theme/mediaQueries'
import navigation from 'styles/theme/themeNavigation'
import * as typography from 'styles/theme/typography'

function ThemeContainer({ children }) {
  const breakpoints = React.useMemo(() => {
    const bp = [em(md), em(lg), em(xl)]
    bp.md = bp[0]
    bp.lg = bp[1]
    bp.xl = bp[2]

    return bp
  }, [])

  return (
    <ThemeProvider
      theme={{
        breakpoints,
        animation,
        arrows,
        blocks,
        buttons,
        colors,
        indents,
        typography,
        mq,
        navigation,
      }}
    >
      {children}
    </ThemeProvider>
  )
}

ThemeContainer.propTypes = {
  children: PropTypes.node,
}

export default ThemeContainer
