import { em } from 'polished'
import styled from 'styled-components'

export const TextBox = styled.div`
  text-align: center;
  margin-top: -${em(7)};

  ${p => p.theme.mq.upMd} {
    margin-top: 0;
  }
`

export const TextStyled = styled.span`
  display: inline-block;
  font-size: ${p => p.theme.typography.fontSize};
  font-family: ${p => p.theme.typography.mediumFont};
  line-height: 1.44;
  font-weight: 400;

  & > mark {
    background: transparent;
    color: ${p =>
      p.markColor ? p.theme.colors[p.markColor] : p.theme.colors.yellow};
  }

  ${p => p.theme.mq.upMd} {
    white-space: nowrap;
    font-size: ${p => p.theme.typography.textDisclaimerMd};
  }
`
