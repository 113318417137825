import styled from 'styled-components'

export default styled.div`
  position: relative;
  top: -1px;
  width: 100%;
  height: 1px;
  background: ${p => p.theme.colors.blackOp07};
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 1px;
    background: ${p => p.theme.colors.black};
    transition-property: width;
    transition-duration: ${p => p.theme.animation.timeStandard};
    transition-timing-function: ease-in;
  }
`
