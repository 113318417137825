import PropTypes from 'prop-types'
import React, { Component } from 'react'

import ButtonRoundedBase from 'components/ButtonRoundedBase'
import { Text } from 'styles/components/typography'

import BtnGoodsCounter from '../BtnGoodsCounter'

import {
  decreaseButton,
  increaseButton,
  setConditionWithCounter,
} from './animations'

import './BtnCartAnimated.scss'

const COLORS = {
  black: {
    bgClassName: 'bg--picton-blue',
    iconColor: 'white',
    textClassName: 'text--white',
  },
  white: {
    bgClassName: 'bg--picton-blue',
    iconColor: 'white',
    textClassName: 'text--white',
  },
}

/**
 * BtnCartAnimated — component to render animated cart button
 *
 * @returns {Component} BtnCartAnimated
 */

class BtnCartAnimated extends Component {
  constructor(props) {
    super(props)

    this.wrapperRef = React.createRef()
    this.textRef = React.createRef()
    this.iconRef = React.createRef()
    this.counterRef = React.createRef()

    this.state = {
      // Condition of button rendering
      // 0 - Buy button for empty cart
      // 1 - Decreasing animation
      // 2 - Show counter
      // 3 - Counter shown condition
      // 4 - Hide counter
      // 5 - Increasing animation
      condition: props.amount > 0 ? 3 : 0,
      prevAmount: props.amount,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { condition, prevAmount } = prevState
    const { amount } = nextProps
    const result = {}

    if (amount !== prevAmount) {
      result.prevAmount = amount

      if (condition === 0 && amount > 0) {
        result.condition = 1
      }

      if (condition === 3 && amount === 0) {
        result.condition = 4
      }

      return result
    }

    return null
  }

  componentDidMount() {
    const { condition } = this.state

    if (condition === 3) {
      setConditionWithCounter({
        iconNode: this.iconRef.current,
        textNode: this.textRef.current,
        wrapperNode: this.wrapperRef.current,
      })
    }
  }

  decreasingCb = () => {
    this.setState({
      condition: 2,
    })
  }

  showCounterCb = () => {
    this.setState({
      condition: 3,
    })
  }

  hideCounterCb = () => {
    this.setState({
      condition: 5,
    })
  }

  increasingCb = () => {
    this.setState({
      condition: 0,
    })
  }

  render() {
    const { wrapperProps, children, theme } = this.props
    const { condition, prevAmount } = this.state
    const btnProps = {
      wrapperProps,
      notHoverable: true,
    }
    let showCounter = false
    let textPaddingClass = ''

    // 0 - Buy button for empty cart
    if (condition === 0) {
      btnProps.notHoverable = false

      // 1 - Decreasing animation
    } else if (condition === 1) {
      btnProps.animTimeline = decreaseButton({
        iconNode: this.iconRef.current,
        textNode: this.textRef.current,
        wrapperNode: this.wrapperRef.current,
      })
      btnProps.animTimelineCb = this.decreasingCb

      // 2 - Show counter
    } else if (condition === 2) {
      showCounter = true
      btnProps.notHoverable = false

      // 3 - Counter shown condition
    } else if (condition === 3) {
      showCounter = true
      textPaddingClass = 'btn-cart-animated__content--no-padding'
      btnProps.notHoverable = false
      // 4 - Hide counter
    } else if (condition === 4) {
      textPaddingClass = 'btn-cart-animated__content--no-padding'
      // 5 - Increasing animation
    } else if (condition === 5) {
      btnProps.animTimeline = increaseButton({
        iconNode: this.iconRef.current,
        textNode: this.textRef.current,
        wrapperNode: this.wrapperRef.current,
      })
      btnProps.animTimelineCb = this.increasingCb
    }

    const hoverClass = showCounter ? 'btn-cart-animated__big-hover' : ''
    const { iconColor, bgClassName, textClassName } = COLORS[theme]

    return (
      <div className="btn-cart-animated" ref={this.wrapperRef}>
        <ButtonRoundedBase
          as="button"
          size="slim"
          textClassName={`${textClassName} btn-cart-animated__content ${textPaddingClass}`}
          icon="btn_cart"
          iconStrokeWidth="1.2"
          refIcon={this.iconRef}
          wrapperClassName={hoverClass}
          {...{ iconColor, bgClassName }}
          {...btnProps}
        >
          <Text
            ref={this.textRef}
            dangerouslySetInnerHTML={{ __html: children }}
          />
        </ButtonRoundedBase>
        <BtnGoodsCounter
          amount={prevAmount}
          isShow={showCounter}
          outCb={this.hideCounterCb}
          inCb={this.showCounterCb}
        />
      </div>
    )
  }
}

BtnCartAnimated.propTypes = {
  /** Object with callback for click on the button action */
  wrapperProps: PropTypes.object.isRequired,

  /** Title for buy button */
  children: PropTypes.string,

  /** Amount of goods in cart */
  amount: PropTypes.number,

  /** Color theme code */
  theme: PropTypes.string,
}

BtnCartAnimated.defaultProps = {
  amount: 0,
  theme: '',
}

export default BtnCartAnimated
