import { em } from 'polished'
import styled from 'styled-components'

const iconSize = em(40)

export const A = styled.a`
  display: block;
  position: relative;
  background: ${p => p.theme.colors.grass};
  color: ${p => p.theme.colors.white} !important;
`

export const IconBox = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: ${p => em(p.theme.indents.baseIndentXs)};
  width: ${iconSize};
  height: ${iconSize};
`
