import {
  createRouterMiddleware,
  initialRouterState,
} from 'connected-next-router'
import { applyMiddleware, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'

import rootReducer from './reducers'
import { createLocationInitialState } from './reducers/locationReducer'
import rootSaga from './sagas'

const bindMiddleware = middleware => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension')
    const reduxImmutableStateInvariant = require('redux-immutable-state-invariant').default()
    return composeWithDevTools(
      applyMiddleware(...middleware, reduxImmutableStateInvariant),
    )
  }
  return applyMiddleware(...middleware)
}

function configureStore(initialState = {}, options) {
  if (options && options.asPath) {
    initialState.router = initialRouterState(options.asPath)
    initialState.location = createLocationInitialState(
      { ...initialState.router.location },
      initialState.router.actions,
    )
  }

  const routerMiddleware = createRouterMiddleware()
  const sagaMiddleware = createSagaMiddleware({
    onError: error => {
      if (process.env.SERVER !== 'production') {
        console.log('=========SAGA ERROR============') // eslint-disable-line
        console.error(error) // eslint-disable-line
      }
    },
  })

  const store = createStore(
    rootReducer,
    initialState,
    bindMiddleware([routerMiddleware, sagaMiddleware]),
  )

  /**
   * next-redux-saga depends on `runSagaTask` and `sagaTask` being attached to the store.
   *
   *   `runSagaTask` is used to rerun the rootSaga on the client when in sync mode (default)
   *   `sagaTask` is used to await the rootSaga task before sending results to the client
   *
   */
  store.runSagaTask = () => {
    store.sagaTask = sagaMiddleware.run(rootSaga)
  }

  // run the rootSaga initially
  store.runSagaTask()

  return store
}

export default configureStore
