// We are using this constants inside webpack config
// therefore you have to forget about es6

const PRODUCTION = 'production'
const STAGING = 'staging'
const TEST = 'test'
const DEVELOPMENT = 'development'

module.exports = {
  PRODUCTION,
  STAGING,
  TEST,
  DEVELOPMENT,
}
