import { em } from 'polished'
import styled from 'styled-components'

import { BoxBase } from 'styles/components/layout'

export const BoxBaseStyled = styled(BoxBase)`
  position: relative;
  width: 100%;
  z-index: 100;
  background: ${({ theme, color }) => theme.colors[color] || color};
  color: ${p => p.theme.colors.white};
  overflow: hidden;
  padding-top: ${em(20)};
  padding-bottom: ${em(20)};
  margin-top: ${p => p.theme.blocks.menuHeight};
  margin-bottom: -${p => p.theme.blocks.menuHeight};
  -webkit-font-smoothing: antialiased;
}

  ${p => p.theme.mq.upMd} {
    position: fixed;
    z-index: 100;
    top: ${em(66)};
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
`

export const Line = styled.div`
  position: relative;
  left: 0;
  width: 100%;
  height: 1px;
  z-index: 99;
  margin-top: ${p => p.theme.blocks.menuHeight};
  margin-bottom: -${p => p.theme.blocks.menuHeight};
  background: ${p => p.theme.colors.whiteOp12};

  ${p => p.theme.mq.upMd} {
    position: fixed;
    margin: 0;
    top: ${p => em(p.indent.md + 66)};
  }

  ${p => p.theme.mq.upLg} {
    top: ${p => em(p.indent.lg + 66)};
  }
`

export const CloseBox = styled.div`
  position: absolute;
  top: ${em(10)};
  right: ${em(10)};

  ${p => p.theme.mq.upMd} {
    top: ${em(18)};
    right: ${em(25)};
  }

  ${p => p.theme.mq.upLg} {
    top: ${em(18)};
  }
`
