import { em } from 'polished'
import PropTypes from 'prop-types'
import React from 'react'

import ButtonClose from 'components/ButtonClose'
//import { JP } from 'constants/countries'
import { ResizeContext } from 'contexts/Resize'
import { cart } from 'routes'

import AdvertisementBanner from './components/AdvertisementBanner'
//import AdvertisementBannerJP from './components/AdvertisementBannerJP'
import TextBanner from './components/TextBanner'
import TimerBanner from './components/TimerBanner'
import { BoxBaseStyled, CloseBox, Line } from './styles'

const SalesBanner = ({
  activeBanner,
  height,
  dict,
  paths,
  locale,
  route,
  onClose,
  onBannerEnd,
}) => {
  const { mobile } = React.useContext(ResizeContext)
  const bannerRef = React.useRef(null)

  const { sales_banner } = dict

  const text = React.useMemo(
    () =>
      activeBanner
        ? mobile
          ? sales_banner?.[activeBanner.name]?.title_mob
          : sales_banner?.[activeBanner.name]?.title
        : '',
    [mobile, sales_banner, activeBanner],
  )

  const handleClose = React.useCallback(() => {
    onClose()
  }, [onClose])

  const isCart = route.config?.basePath === cart.basePath

  /** for default banners are shown only on product pages and in cart */
  const isBannerHidden = !route.config?.isProductPage && !isCart

  const isTimerInCart = activeBanner?.isTimer && isCart

  /** check if we have same bg color of page and of banner*/
  const isOutline = activeBanner?.color === route.config?.background

  if (!activeBanner || isBannerHidden) return null

  return (
    <>
      <BoxBaseStyled
        id={'salesBanner'}
        ref={bannerRef}
        color={activeBanner.color}
        height={
          isTimerInCart
            ? // we have different heights for this banner type on cart page and other pages
              { xs: 'auto', md: em(50), lg: em(50) }
            : { xs: 'auto', md: em(height.md), lg: em(height.lg) }
        }
      >
        {activeBanner.onlyText ? (
          <TextBanner text={text} activeBanner={activeBanner} />
        ) : activeBanner.isTimer ? (
          <TimerBanner
            {...{
              activeBanner,
              text,
              button: sales_banner[activeBanner.name].button,
              locale,
              bannerRef,
              route,
              onEnd: onBannerEnd,
            }}
          />
        ) : (
          /*locale === JP ? (
          /!** we have different order of elements and other logic in japanese banner *!/
          <AdvertisementBannerJP
            {...{
              activeBanner,
              text,
              dict,
              paths,
              locale,
              isCart,
              ...sales_banner[activeBanner.name],
            }}
          />
        ) : */ <AdvertisementBanner
            {...{
              activeBanner,
              text,
              dict,
              paths,
              locale,
              ...sales_banner[activeBanner.name],
              bannerRef,
              route,
            }}
          />
        )}
        {activeBanner.isClosing && (
          <CloseBox>
            <ButtonClose {...{ handleClick: handleClose, size: 'medium', color: activeBanner?.closeButtonColor }} />
          </CloseBox>
        )}
      </BoxBaseStyled>
      {/** if we have same background color of page and background color of banner,
       there will appear this white semitransparent divider */}
      {isOutline && <Line indent={{ md: height.md, lg: height.lg }} />}
    </>
  )
}

SalesBanner.propTypes = {
  activeBanner: PropTypes.shape({
    /** name of sale */
    name: PropTypes.string,

    /** show banner with countdown to end date */
    isTimer: PropTypes.bool,

    /** show simple banner with text */
    onlyText: PropTypes.bool,

    /** adds cross button and allows to close banner in this session */
    isClosing: PropTypes.bool,

    /** banner's background color */
    color: PropTypes.string,

    /** color of highlighted text inside <mark> tag */
    markColor: PropTypes.string,

    /** color of button */
    btnColor: PropTypes.oneOf(['white', 'black']),
  }),
  height: PropTypes.shape({
    xs: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
  }),
  dict: PropTypes.object,
  locale: PropTypes.string,
  paths: PropTypes.object,
  route: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onBannerEnd: PropTypes.func.isRequired,
}

export default SalesBanner
