import { getGETQueries } from 'utils/client/url'

export const getActiveDiscount = (discounts, locale) => {
  const activeKey = Object.keys(discounts).find(key => {
    const stock = discounts[key]
    const now = new Date()
    const start = new Date(stock.start)
    const end = new Date(stock.end)
    const isEnabledForLocale =
      !stock.exclude || stock.exclude.indexOf(locale) === -1

    return now >= start && now <= end && isEnabledForLocale
  })

  const query = typeof window !== 'undefined' ? getGETQueries() : {}

  if (query.promo_test) {
    return discounts[Object.keys(discounts).find((key) => key.toLowerCase() === query.promo_test.toLowerCase())]
  }

  return discounts[activeKey]
}
