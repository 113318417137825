// TODO передавать сразу ID.
//   А это костыль. Мы не должны зашивать жестко ID продуктов на фронте
import {GOODS_TYPES} from '../constants/sales';

const productTypeToIds = {
  [GOODS_TYPES.dna]: 1,
  [GOODS_TYPES.biome]: 2,
  [GOODS_TYPES.wgs]: 9,
}
if (typeof window !== 'undefined') {
  window.CITY_ADS_PRODUCTS_IN_CART = '';
  window.CITY_ADS_PRODUCT_COUNT_IN_CART = '';
}
export function getCartToCityAdsVariable(goodsInCart) {
  const {products, count} = Object.entries(goodsInCart).reduce((acc, [code, item]) => {
    if (item.amount > 0) {
      acc.products.push(productTypeToIds[code]);
      acc.count.push(item.amount);
    }
    return acc;
  }, {products: [], count: []});
  return {products: products.join(','), count: count.join(',')}
}
export function provideCartToCityAdsVariable(goodsInCart) {
  const variables = getCartToCityAdsVariable(goodsInCart);
  window.CITY_ADS_PRODUCTS_IN_CART = variables.products;
  window.CITY_ADS_PRODUCT_COUNT_IN_CART = variables.count;
}
