import { em } from 'polished'
import styled from 'styled-components'

export const CountrySwitcherIconStyled = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: ${em(24)};
  height: ${em(24)};
  margin-right: ${em(20)};

  img {
    display: block;
    width: 100%;
  }

  ${p => p.theme.mq.upMd} {
    margin-right: ${em(10)};
  }
`
