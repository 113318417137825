import styled, { css } from 'styled-components'

import Underline from 'styles/components/Underline'

export const UnderlineStyled = styled(Underline)`
  width: auto;
  left: 0;
  right: 0;
  ${({ isWhite }) =>
    isWhite &&
    css`
      background-color: ${p => p.theme.colors.whiteOp1};
    `}

  ${p => p.theme.mq.upMd} {
    border: none;
  }
`
