import { routerReducer } from 'connected-next-router'
import { combineReducers } from 'redux'

import cartReducer from './cartReducer'
import localizationReducer from './localizationReducer'
import locationReducer from './locationReducer'
import userProfileReducer from './userProfileReducer'

export default combineReducers({
  router: routerReducer,
  localization: localizationReducer,
  location: locationReducer,
  cart: cartReducer,
  userProfile: userProfileReducer,
})
