import { em } from 'polished'

export const large = 75
export const medium = 60
export const base = 50
export const newSmall = 40
export const small = 38
export const xs = 21

export const hActionBtnLarge = em(large)
export const hActionBtnMedium = em(medium)
export const hActionBtn = em(base)
export const hActionBtnSlim = em(small)

export const buttonInnerIndentLarge = em(12)
export const buttonInnerIndentMedium = em(12)
export const buttonInnerIndentBase = em(10)
export const buttonInnerIndentSmall = em(6)

export const indent = {
  large: em(12),
  base: em(10),
  newSmall: em(6),
  small: em(6),
  xs: em(4),
}

export const buttonRoundedTextIndent = em(20)

export const buttonRoundedGrowingCoef = 1.1
export const buttonCircleGrowingCoef = 1.2
