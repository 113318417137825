import { em } from 'polished'
import styled, { css } from 'styled-components'

import { BoxBase, Col } from 'styles/components/layout'
import { Text } from 'shared/Typography'

export const WithBorderTop = styled.div`
  border-top: 1px solid ${p => p.borderColor};
  height: 100%;

  ${p => p.theme.mq.upMd} {
    padding: ${em(40)} 0 0;
  }
`

export const Address = styled(WithBorderTop)`
  padding: ${em(40)} ${p => p.theme.indents.baseIndentXs};
`

export const Compliance = styled.div`
  margin-top: 40px;

  ${p => p.theme.mq.upMd} {
    margin-top: 30px;
  }
`

export const Navigation = styled(WithBorderTop)``

export const FooterBox = styled(BoxBase)`
  flex-shrink: 0;
  z-index: 2;
  overflow: hidden;
  color: ${p => p.footerColor || p.theme.colors.bodyColor};
  background: ${p => p.footerBg || 'transparent'};
  margin-top: ${em(
    -1,
  )}; // fix for space between upsale card and footer when page render with subpixel units
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 20px;

  ${p => p.theme.mq.upMd} {
    padding-bottom: 26px;
  }
`

export const Link = styled.a`
  cursor: pointer;
`

export const CompanyText = styled(Text)`
  margin-bottom: 0;

  a {
    color: ${p => p.isWhite ? 'var(--color-W100)' : p.isPurple ? 'var(--color-W30)' : 'var(--color-D100)'};
    text-decoration: underline;
  }
`

export const ContactsText = styled(Text)`
  margin-top: 40px;
  margin-bottom: auto;

  span {
    opacity: 0.3;
  }

  a {
    color: ${p => p.isWhite ? 'var(--color-W100)' : p.isPurple ? 'var(--color-W30)' : 'var(--color-D100)'};
    text-decoration: underline;
  }
`

export const MedicalLicense = styled(Text)`
  margin-top: 20px;

  a {
    display: block;
    text-decoration: underline;
    transition: opacity 0.5s;

    &:hover {
      opacity: 0.5;
    }
  }
`

export const Disclaimer = styled(Text)`
`

export const UnstyledList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  ${({ theme }) => theme.mq.upMd} {
    gap: ${em(10)};
  }
`

export const ButtonBox = styled.div`
  display: inline-block;
  margin-left: ${em(10)};
  margin-right: ${em(10)};

  ${({ withMarginBottom }) => withMarginBottom && `margin-bottom: ${em(20)};`}
`

export const Sections = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const ColStyled = styled(Col)`
  padding-left: 0;
  text-align: right;

  ${p => p.theme.mq.upMd} {
    padding-left: 0;
  }
`

export const ColNoPadding = styled(Col)`
  padding-left: 0;
  padding-right: 0;

  ${p => p.theme.mq.upMd} {
    padding-left: 0;
    padding-right: 0;
  }
`

export const DisclaimerAndMedicalLicense = styled(Col)`
  margin-top: 40px;

  ${p => p.theme.mq.downMd} {
    padding-left: 20px;
    padding-right: 20px;
  }

  ${p => p.theme.mq.upMd} {
    margin-top: 60px;
    padding-left: 0;
    padding-right: 0;
  }
`

export const ComplianceRow = styled.div`
  // display: flex;
  // align-items: center;

  ${p =>
    p.withMarginTop &&
    css`
      margin-top: ${em(45)};
      margin-bottom: ${em(7)};
    `}
  & span {
    display: inline;
    opacity: 0.4;
  }
`

export const Divider = styled.hr`
  border-top: ${em(1.2)} solid ${p => p.theme.colors.transparent};
  border-bottom: none;
  margin-top: ${em(20)};

  ${p => p.theme.mq.upMd} {
    border-top: ${em(1.2)} solid ${p => p.borderColor};
    margin-bottom: -${em(20)};
  }
`

export const CeBox = styled.div`
  display: inline-block;
  position: relative;
  top: ${em(3)};
  width: ${em(22)};
  height: ${em(16)};
  flex-shrink: 0;
  margin-right: ${em(11)};
`

export const ManufacturerBox = styled.div`
  display: inline-block;
  position: relative;
  top: ${em(3)};
  width: ${em(18)};
  height: ${em(21)};
  flex-shrink: 0;
  margin-right: ${em(13)};
`

export const EcRepBox = styled.div`
  display: inline-block;
  position: relative;
  top: ${em(3)};
  width: ${em(54)};
  height: ${em(19)};
  flex-shrink: 0;
  margin-right: ${em(10)};
`
